import { useMemo } from 'react';

import { useRouter } from 'next/router';
import Image from 'next/image';

import { FCWithChildren } from '~/shared/types/FCWithChildren';
import { useSettings } from '~/shared/hooks/settings';
import { routes } from '~/shared/constants/routes';
import { env } from '~/shared/constants/env';
import { TypeWriter } from '~/shared/components/TypeWriter';

import {
  Container,
  LeftBox,
  RightBox,
  RightBoxContent,
  TitleContainer,
  ImageContainer,
  LeftBoxPoweredByContainer,
  RightBoxPowredByContainer,
} from './styles';
import { PoweredByLogoImg } from '../../../../public/images/powered-by-logo';

export const AuthLayout: FCWithChildren = ({ children }) => {
  const { settings } = useSettings();

  const router = useRouter();

  const title = useMemo(() => {
    if (router.asPath === routes.auth.signIn) {
      return 'acesse\nsua conta';
    }

    if (router.asPath === routes.auth.forgotPassword) {
      return 'recupere\nsua conta';
    }

    if (router.asPath === routes.auth.resetPassword) {
      return 'redefina\nsua senha';
    }

    if (router.asPath === routes.setup) {
      return 'finalize\nseu cadastro';
    }

    return 'cadastre-se';
  }, [router]);

  const PoweredBy: any = () =>
    settings?.ignoreRsLink ? (
      <PoweredByLogoImg />
    ) : (
      <a href={env.RS_SOLUTIONS_SITE_URL} target="_blank" rel="noreferrer">
        <PoweredByLogoImg />
      </a>
    );

  return (
    <Container>
      <LeftBox>
        <TitleContainer>
          <TypeWriter>{title}</TypeWriter>
        </TitleContainer>

        <ImageContainer>
          {settings?.theme?.backgrounds?.auth && (
            <Image
              src={settings?.theme?.backgrounds?.auth}
              alt="..."
              layout="fill"
            />
          )}
        </ImageContainer>

        <LeftBoxPoweredByContainer>
          <PoweredBy />
        </LeftBoxPoweredByContainer>
      </LeftBox>

      <RightBox id="main-element">
        <RightBoxContent id="auth-layout-content">
          {settings?.theme?.logo && (
            <Image
              src={settings.theme?.logo}
              alt="..."
              width={200}
              height={85}
              objectFit="contain"
              priority
              quality={100}
            />
          )}

          {children}

          <RightBoxPowredByContainer>
            <PoweredBy />
          </RightBoxPowredByContainer>
        </RightBoxContent>
      </RightBox>
    </Container>
  );
};
