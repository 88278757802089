import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  width: 100vw;

  display: flex;
  background: ${({ theme }) => theme.colors.background};
`;

export const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  overflow: hidden;

  main {
    display: flex;
    flex-direction: column;

    flex: 1;
    height: 100%;
    overflow-x: hidden;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;
