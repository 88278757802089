import { FC, useEffect, useState } from 'react';

import { IoClose } from 'react-icons/io5';

import { useRouter } from 'next/router';
import Link from 'next/link';

import { ScaleFade, Tooltip } from '@chakra-ui/react';

import { signOut, useAuth } from '~/modules/auth/hooks/auth';

import { useClickOutside } from '~/shared/hooks/clickOutside';
import { ReferenceIdEnum } from '~/shared/enums/ReferenceIdEnum';
import { routes } from '~/shared/constants/routes';

import {
  Container,
  AvatarButton,
  PopoverArrow,
  PopoverContainer,
  ClosePopoverButton,
  PopoverContent,
} from './styles';
import { Avatar } from '../../Avatar';

export const MyAccount: FC = () => {
  const router = useRouter();

  const { user } = useAuth();

  const [isOpen, setIsOpen] = useState(false);

  function closePopover(): void {
    setIsOpen(false);
  }

  useEffect(() => {
    router.events.on('routeChangeStart', closePopover);

    return () => {
      router.events.off('routeChangeStart', closePopover);
    };
  }, [router]);

  const popoverRef = useClickOutside<HTMLLIElement>(closePopover);

  useEffect(() => {
    document.addEventListener('keydown', closePopover, false);

    return () => {
      document.removeEventListener('keydown', closePopover, false);
    };
  }, []);

  function tooglePopperIsOpen(): void {
    setIsOpen((prevState) => !prevState);
  }

  return (
    <Container ref={popoverRef} id={ReferenceIdEnum['my-account-item']}>
      <AvatarButton type="button" onClick={tooglePopperIsOpen}>
        <Avatar
          isRounded
          size={30}
          alt={user?.name}
          src={user?.avatarUrl}
          aria-label="Minha conta"
          tooltip="Minha conta"
          noImageType="first-letter"
          noImageColor="#eee"
          noImageBackgroundColor="#868686"
        />
      </AvatarButton>

      <ScaleFade in={isOpen} initialScale={0.9} unmountOnExit>
        <PopoverContainer>
          <PopoverArrow />

          <Tooltip label="Fechar" hasArrow>
            <ClosePopoverButton type="button" onClick={closePopover}>
              <IoClose />
            </ClosePopoverButton>
          </Tooltip>

          <PopoverContent>
            <div>
              <div>
                <Avatar
                  alt={user?.name}
                  isRounded
                  size={60}
                  textSize={20}
                  src={user?.avatarUrl}
                  noImageType="first-letter"
                  noImageColor="#eee"
                  noImageBackgroundColor="#868686"
                />
              </div>

              <strong>{user?.name}</strong>

              <span>{user?.email}</span>
            </div>

            <Link href={routes.subscriptions.list}>ver assinaturas</Link>

            <button type="button" onClick={signOut}>
              sair do portal
            </button>
          </PopoverContent>
        </PopoverContainer>
      </ScaleFade>
    </Container>
  );
};
