import { ButtonHTMLAttributes, FC } from 'react';

import { IOfferStepButton } from '~/modules/offers/interfaces/IOfferStepButton';

import { Container } from './styles';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  data: IOfferStepButton;
}

export const Button: FC<ButtonProps> = ({ data, ...props }) => {
  return (
    <Container type="button" colors={data.colors} {...props}>
      <span>{data.title}</span>
    </Container>
  );
};
