import { FC, useCallback, useState } from 'react';

import { FaGraduationCap } from 'react-icons/fa';

import { useClickOutside } from '~/shared/hooks/clickOutside';
import { ReferenceIdEnum } from '~/shared/enums/ReferenceIdEnum';
import { ToolbarIconButton } from '~/shared/components/Toolbar/ToolbarIconButton';

import { CoursesDropdownContainer } from './styles';
import { CoursesPopover } from '../CoursesPopover';
import { CoursesProvider } from '../../hooks/courses';

export const CoursesDropdown: FC = () => {
  const [isOpen, setIsOpen] = useState(false);

  const closePopover = useCallback(() => {
    setIsOpen(false);
  }, []);

  const openPopover = useCallback(() => {
    setIsOpen(true);
  }, []);

  const popoverRef = useClickOutside<HTMLLIElement>(closePopover);

  return (
    <CoursesDropdownContainer ref={popoverRef} id="courses">
      <ToolbarIconButton
        aria-label="courses"
        tooltip="Cursos"
        icon={FaGraduationCap}
        id={ReferenceIdEnum['courses-item']}
        iconSize={24}
        onClick={openPopover}
      />
      <CoursesProvider>
        <CoursesPopover isOpen={isOpen} close={closePopover} />
      </CoursesProvider>
    </CoursesDropdownContainer>
  );
};
