import {
  FC,
  MouseEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import ReactPlayer from 'react-player';
import ReactDOM from 'react-dom';

import { useClickOutside } from '~/shared/hooks/clickOutside';

import { Container } from './styles';

export interface VideoModalProps {
  onClose: (event?: MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  url: string;
}

export const VideoModal: FC<VideoModalProps> = ({ onClose, url }) => {
  const backdropRef = useRef<HTMLDivElement>(null);

  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);

    return () => setIsMounted(false);
  }, []);

  const handleClose = useCallback(() => {
    backdropRef.current.style.opacity = '0';

    setTimeout(() => {
      onClose && onClose();
    }, 300);
  }, [onClose]);

  const contentRef = useClickOutside<HTMLDivElement>(handleClose);

  if (!isMounted) return null;

  return ReactDOM.createPortal(
    <Container
      id="video-modal-backdrop"
      ref={backdropRef}
      onClick={handleClose}
    >
      <div ref={contentRef}>
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore */}
        <ReactPlayer url={url} controls playing />
      </div>
    </Container>,
    document.getElementById('modal-root')
  );
};
