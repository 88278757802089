const prefix = 'posfacil';

export const cookies = {
  AGREED_WITH_POLITICS: `${prefix}.agreed-with-politics`,
  SEGMENT_ID: `${prefix}.segment`,
  AUTH_TOKEN: `${prefix}.token`,
  CURRENT_RESALE_CODE: `${prefix}.current-resale-code`,
  USER_COMPANIES: `${prefix}.companies-ids`,
  APP_EDITING_DATA: `${prefix}.app-editing-data`,
  SETTINGS: `${prefix}.settings`,
  SELECTED_PLAN: `${prefix}.selected-plan`,
  ORIGIN: `${prefix}.current-subscription`,
  ALREADY_CLOSED_PROMOTION_HELP: `${prefix}.already-closed-promotion-help`,
  MERCADO_PAGO_REDIRECT_APP: `${prefix}.mercado-pago-redirect-app`,
  CURRENT_MERCADO_PAGO_COMPANY_ID: `${prefix}.current-mercado-pago-company-id`,
  CURRENT_MERCADO_PAGO_REDIRECT_TO: `${prefix}.current-mercado-pago-redirect-to`,
  INVOICE_ALREADY_OPENED_AT_FIRST_TIME: (invoiceId: string): string =>
    `${prefix}.invoice-${invoiceId}-already-opened-at-first-time`,
};
