import styled from 'styled-components';

interface IProps {
  readonly glow: boolean;
}
export const Wrapper = styled.div<IProps>`
  background-color: gray;
  position: absolute;
  border: 1px solid yellow;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.5s;
  opacity: ${({ glow }) => (glow ? 0.2 : 1)};
`;
