import styled from 'styled-components';

export const Container = styled.li`
  position: relative;

  > div {
    position: absolute;
    bottom: -12px;
    right: -15px;
    width: 230px;
  }
`;

export const AvatarButton = styled.button`
  transition: all 0.3s;

  &:hover {
    opacity: 0.75;
  }
`;

export const PopoverArrow = styled.div`
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid ${({ theme }) => theme.colors.surface};
  top: -7px;
  position: absolute;
  right: ${({ theme }) => `calc(${theme.spacing.sm} + 6px)`};
`;

export const PopoverContainer = styled.div`
  background: ${({ theme }) => theme.colors.surface};
  position: absolute;
  width: 100%;

  box-shadow: 3px 3px 15px #00000020;
  border-radius: ${({ theme }) => theme.borderRadius.sm};
`;

export const PopoverContent = styled.div`
  overflow: hidden;
  border-radius: ${({ theme }) => theme.borderRadius.sm};

  > div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: ${({ theme }) =>
      `${theme.spacing['2xs']} ${theme.spacing['2xs']}`};

    > div {
      width: 50px;
      height: 50px;
      overflow: hidden;
      border-radius: 50%;
      margin-bottom: ${({ theme }) => theme.spacing['2xs']};

      display: flex;
      align-items: center;
      justify-content: center;
    }

    > strong {
      font-weight: 700;
      margin-bottom: ${({ theme }) => theme.spacing.xxs};
      color: ${({ theme }) => theme.colors.onSurface};
      font-size: 15px;
      line-height: 15px;
      text-align: center;
    }

    > span {
      color: ${({ theme }) => theme.colors.onSurface};
      font-size: 14px;
      line-height: 14px;
      opacity: 0.75;
      text-align: center;
    }
  }

  > button,
  > a {
    display: flex;
    align-items: center;
    justify-content: center;

    border-top: 1px solid ${({ theme }) => theme.colors.background};
    width: 100%;
    padding: ${({ theme }) => `${theme.spacing.xs} ${theme.spacing['2xs']}`};
    background: ${({ theme }) => theme.colors.surface};
    transition: all 0.3s;

    font-size: 14px;
    font-weight: 700;
    color: ${({ theme }) => theme.colors.onSurface};

    &:hover {
      filter: brightness(0.98);
    }
  }
`;

export const ClosePopoverButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  top: ${({ theme }) => theme.spacing.sm};
  right: ${({ theme }) => theme.spacing.sm};

  transition: all 0.4s;

  &:hover {
    opacity: 0.4;
  }

  svg {
    height: 14px;
    width: 14px;
    stroke-width: 1px;
    color: ${({ theme }) => theme.colors.onSurface};
  }
`;
