import styled from 'styled-components';

export const CourseVideoButtonContainer = styled.div`
  position: relative;
  width: 175px;
  height: 100px;
  border: 1px solid ${({ theme }) => theme.colors.lightGray};
  cursor: pointer;
  > div {
    height: 100% !important;

    :hover {
      > img {
        transition: 0.25s;
        transform: scale(1.05);
      }
    }
  }
`;

interface PlayButtonProps {
  readonly visible: boolean;
}
export const PlayButton = styled.div<PlayButtonProps>`
  position: absolute;
  width: 175px;
  height: 100px;
  background-color: ${({ visible }) =>
    visible ? 'rgba(159, 159, 159, 0.45)' : 'rgba(159, 159, 159, 0)'};
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  > div {
    transition: 0.25s;
    background-color: ${({ theme }) => theme.colors.primary};
    padding: 1px;
    border-radius: 50%;
    opacity: ${({ visible }) => (visible ? 1 : 0)};
  }
`;
