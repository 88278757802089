import {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
  useCallback,
} from 'react';

import { useRouter } from 'next/router';
import { FCWithChildren } from '../types/FCWithChildren';

interface IToolbarContextData {
  leftContent: ReactNode;
  renderLeftContent(content: ReactNode): void;
}

const ToolbarContext = createContext({} as IToolbarContextData);

const ToolbarProvider: FCWithChildren = ({ children }) => {
  const router = useRouter();

  const [leftContent, setLeftContent] = useState<ReactNode>(null);

  useEffect(() => {
    const clearLeftContent = (): void => {
      setLeftContent(null);
    };

    router.events.on('routeChangeStart', clearLeftContent);

    return () => {
      router.events.off('routeChangeStart', clearLeftContent);
    };
  }, [router]);

  const renderLeftContent = useCallback((content: ReactNode) => {
    setLeftContent(content);
  }, []);

  return (
    <ToolbarContext.Provider value={{ leftContent, renderLeftContent }}>
      {children}
    </ToolbarContext.Provider>
  );
};

const useToolbar = (): IToolbarContextData => {
  return useContext(ToolbarContext);
};

export { ToolbarProvider, useToolbar };
