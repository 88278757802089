import { FC } from 'react';

import { IoImageOutline } from 'react-icons/io5';

import { CSSProperties } from 'styled-components';
import { Tooltip } from '@chakra-ui/react';

import { Image } from '~/shared/components/Image';

import { Container } from './styles';

export type AvatarNoImageType = 'first-letter' | 'icon' | 'hidden';

interface AvatarProps {
  size?: number;
  textSize?: number;
  tooltip?: string;
  alt: string;
  src?: string;
  isRounded?: boolean;
  noImageType?: AvatarNoImageType;
  noImageColor?: string;
  noImageBackgroundColor?: string;
  containerStyle?: CSSProperties;
}

export const Avatar: FC<AvatarProps> = ({
  size = 42,
  isRounded = false,
  alt = '',
  src = '',
  tooltip = '',
  textSize = 14,
  noImageType = 'icon',
  noImageColor,
  noImageBackgroundColor,
  containerStyle,
}) => {
  if (noImageType === 'hidden' && !src) return null;

  const firstLetterOfName = alt?.split(' ')[0].slice(0, 1).toUpperCase();

  return (
    <Tooltip label={tooltip} hasArrow>
      <Container
        id="avatar"
        placeholder="blur"
        isRounded={isRounded}
        size={size}
        textSize={textSize}
        hasImage={!!src}
        noImageColor={noImageColor}
        noImageBackgroundColor={noImageBackgroundColor}
        style={containerStyle}
      >
        {src ? (
          <Image
            src={src}
            height={size}
            width={size}
            objectFit="contain"
            alt={alt}
          />
        ) : noImageType === 'first-letter' ? (
          <span>{firstLetterOfName}</span>
        ) : (
          <IoImageOutline />
        )}
      </Container>
    </Tooltip>
  );
};
