import styled, { css } from 'styled-components';

interface CustomFieldTypeProps {
  isSelected?: boolean;
  height: number;
}

export const CustomFieldType = styled.div<CustomFieldTypeProps>`
  flex: 1;
  display: flex;
  height: ${({ height }) => `${height}px`};

  aspect-ratio: 1 / 1;

  > button {
    transition: 0.25s;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing.xxs};

    border: 1px solid #ddd;
    border-radius: ${({ theme }) => theme.borderRadius.md};
    background: ${({ theme }) => theme.colors.surface};
    padding: ${({ theme }) => theme.spacing.xxs};

    span {
      font-size: 14px;
    }

    transition: filter 0.4s;

    &:not(:disabled):hover {
      filter: brightness(0.95);
    }

    ${({ isSelected }) =>
      isSelected &&
      css`
        border: ${({ theme }) => `1px solid ${theme.colors.primary}`};
        filter: brightness(0.95);
      `}

    &:disabled {
      filter: brightness(0.95);
    }
  }
`;
