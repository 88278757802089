import { FC } from 'react';

import { Tooltip } from '@chakra-ui/react';

import { Image } from '~/shared/components/Image';

import { Container } from './styles';
import { IApp } from '../../interfaces/IApp';

interface AppBannerProps {
  name: string;
  backgroundColor?: string;
  image: string;
  showTooltip?: boolean;
}

export const AppBanner: FC<AppBannerProps> = ({
  backgroundColor = '#f6f6f6',
  image,
  name,
  showTooltip = false,
}) => {
  return (
    <Tooltip
      hasArrow
      closeOnClick={false}
      label={name}
      isDisabled={!showTooltip}
    >
      <Container backgroundColor={backgroundColor}>
        {image && (
          <Image
            src={image}
            width={35}
            height={35}
            objectFit="contain"
            alt={name}
          />
        )}
      </Container>
    </Tooltip>
  );
};
