/* eslint-disable no-empty */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { createContext, useContext, useState } from 'react';

import { SidebarGroupsEnum } from '~/shared/enums/SidebarGroupsEnum';

import { IGroupedSidebar } from '../interfaces/IGroupedSidebar';
import { SidebarPagesEnum } from '../enums/SidebarPagesEnum';
import { localStorageKeys } from '../constants/local-storage';
import { FCWithChildren } from '../types/FCWithChildren';

interface IPageProperties {
  readonly title: string;
  readonly description: string;
}

interface IDynamicSidebar {
  readonly sidebarData: Array<IGroupedSidebar>;
  readonly getPageProperties: (
    groupName: SidebarGroupsEnum,
    pageName: SidebarPagesEnum
  ) => IPageProperties;
  readonly loadSidebarConfigurations: () => void;
  readonly clearSidebarConfigurations: () => void;
  readonly saveSidebarConfigurations: (
    sidebarConfigurations: Array<IGroupedSidebar>
  ) => void;
}

const DynamicSidebarContext = createContext<IDynamicSidebar>(
  {} as IDynamicSidebar
);

const DynamicSidebarProvider: FCWithChildren = ({ children }) => {
  const [sidebarData, setSidebarData] = useState<Array<IGroupedSidebar>>([]);

  const loadSidebarConfigurations = (): void => {
    const rawSidebarData = localStorage.getItem(
      localStorageKeys.DYNAMIC_SIDEBAR_DATA
    );

    if (!rawSidebarData || rawSidebarData === 'undefined') {
      return;
    }

    setSidebarData(JSON.parse(rawSidebarData));
  };

  const saveSidebarConfigurations = (
    sidebarConfigurations: Array<IGroupedSidebar>
  ): void => {
    setSidebarData(sidebarConfigurations);
    localStorage.setItem(
      localStorageKeys.DYNAMIC_SIDEBAR_DATA,
      JSON.stringify(sidebarConfigurations)
    );
  };

  const clearSidebarConfigurations = (): void => {
    localStorage.removeItem(localStorageKeys.DYNAMIC_SIDEBAR_DATA);
  };

  const getPageProperties = (
    groupName: SidebarGroupsEnum,
    pageName: SidebarPagesEnum
  ): IPageProperties => {
    if (sidebarData?.length > 0) {
      const sidebarItems = sidebarData.reduce((acc, group) => {
        return [...acc, ...(group.items || [])];
      }, []);

      const { customDescription: description, customTitle: title } =
        sidebarItems.find((item) => item._id === pageName);

      return { title, description };
    }

    return { title: '', description: '' };
  };

  return (
    <DynamicSidebarContext.Provider
      value={{
        sidebarData,
        getPageProperties,
        loadSidebarConfigurations,
        clearSidebarConfigurations,
        saveSidebarConfigurations,
      }}
    >
      {children}
    </DynamicSidebarContext.Provider>
  );
};

const useDynamicSidebar = () => useContext(DynamicSidebarContext);

export { DynamicSidebarProvider, useDynamicSidebar };
