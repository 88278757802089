import styled from 'styled-components';

interface TextProps {
  cursor: string;
}

export const Text = styled.span<TextProps>`
  display: block;
  text-align: left;
  white-space: pre-line;
  cursor: default;

  &::after {
    content: attr(cursor);

    @keyframes blink {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }

    animation: blink 0.36s infinite alternate;
  }
`;
