import styled from 'styled-components';

export const Container = styled.main`
  display: flex;

  height: 100vh;
  width: 100vw;
`;

export const LeftBox = styled.section`
  flex: 1;
  background-color: ${({ theme }) => theme.colors.primary};
  padding: ${({ theme }) => theme.spacing.xl};

  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  position: relative;

  @media (max-width: 800px) {
    > h1 {
      font-size: 30px;
    }
  }

  @media (max-width: 775px) {
    display: none;
  }
`;

export const TitleContainer = styled.div`
  position: relative;
  z-index: 10;
  top: 0;
  right: 0;

  > span {
    color: ${({ theme }) => theme.colors.onPrimary};
    font-weight: 700;
    font-size: 42px;
    font-weight: 700;
    line-height: 52px;
  }
`;

export const ImageContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 5;

  img {
    object-fit: cover;
    object-position: right;
  }
`;

export const LeftBoxPoweredByContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 5;
  padding: ${({ theme }) => theme.spacing.lg};

  svg {
    width: auto;
    height: 55px;

    path:not(#Vector_10) {
      fill: ${({ theme }) => theme.colors.onPrimary};
    }

    #Vector_10 {
      stroke: ${({ theme }) => theme.colors.onPrimary};
    }
  }

  @media (max-width: 1050px) {
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const RightBoxPowredByContainer = styled.div`
  display: none;
  margin-top: ${({ theme }) => theme.spacing.lg};

  svg {
    width: auto;
    height: 45px;

    path:not(#Vector_10) {
      fill: ${({ theme }) => theme.colors.onSurface};
    }

    #Vector_10 {
      stroke: ${({ theme }) => theme.colors.onSurface};
    }
  }

  @media (max-width: 775px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const RightBox = styled.main`
  display: grid;
  place-items: center;

  overflow-y: auto;
  overflow-x: hidden;

  background: ${({ theme }) => theme.colors.surface};
  width: 100%;
  max-width: 620px;
  padding: 0 ${({ theme }) => theme.spacing.lg};
  height: 100vh;

  box-shadow: -5px 3px 6px #00000026;
  z-index: 15;

  @media (max-width: 775px) {
    box-shadow: none;
    max-width: 100%;
  }
`;

export const RightBoxContent = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  max-width: 370px;
  width: 100%;
  padding: ${({ theme }) => theme.spacing.lg} 0;

  form {
    display: flex;
    flex-direction: column;

    width: 100%;

    padding: ${({ theme }) =>
      `calc(${theme.spacing.lg} + ${theme.spacing.lg}) 0 ${theme.spacing.lg}`};
  }

  @media (max-width: 775px) {
    img {
      width: 200px;
    }
  }
`;
