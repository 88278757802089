import { createContext, useCallback, useContext, useState } from 'react';

import { VideoModal } from '../components/VideoModal';
import { FCWithChildren } from '../types/FCWithChildren';

interface IVideoModalContextData {
  open(url: string): void;
  close(): void;
}

const VideoModalContext = createContext({} as IVideoModalContextData);

const VideoModalProvider: FCWithChildren = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [url, setUrl] = useState('');

  const open = useCallback((videoUrl: string) => {
    setUrl(videoUrl);
    setIsOpen(true);
  }, []);

  const close = useCallback(() => {
    setUrl('');
    setIsOpen(false);
  }, []);

  return (
    <VideoModalContext.Provider value={{ open, close }}>
      {children}

      {isOpen && <VideoModal onClose={close} url={url} />}
    </VideoModalContext.Provider>
  );
};

const useVideoModal = (): IVideoModalContextData => {
  return useContext(VideoModalContext);
};

export { VideoModalProvider, useVideoModal };
