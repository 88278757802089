import styled, { css } from 'styled-components';

export const CoursesListContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: ${({ theme }) => theme.spacing.md};
  padding-left: ${({ theme }) => theme.spacing.md};
  max-height: 405px;
  overflow: auto;
  scrollbar-width: thin;
`;

export const CourseItem = styled.div`
  display: flex;

  @media (max-width: 750px) {
    flex-direction: column;
  }

  @media (min-width: 750px) {
    flex-direction: row;
  }
  :not(:first-of-type) {
    margin-top: ${({ theme }) => theme.spacing.lg};
  }
`;

export const CourseVideoWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 750px) {
    justify-content: center;
    align-items: center;
  }
`;

export const CourseVideoLineConector = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  align-items: center;

  div {
    height: 3px;
    width: 2px;
    background: black;
  }
`;

export const CoursePercentageWrapper = styled.div`
  width: 175px;
  height: 22px;
  border: 1px solid black;
  border-radius: 10px;
  padding: 2px;
`;

interface CourseCurrentPercentageProps {
  readonly percentage: number;
}
export const CourseCurrentPercentage = styled.div<CourseCurrentPercentageProps>`
  background-color: ${({ theme }) => theme.colors.primary};
  position: relative;
  border-radius: 10px;
  height: 100%;
  width: ${({ percentage }) => `${percentage}%`};
`;

interface CourseTitleWrapperProps {
  readonly hasDescription: boolean;
}
export const CourseTitleWrapper = styled.div<CourseTitleWrapperProps>`
  width: 100%;
  display: flex;
  flex-direction: column;
  @media (min-width: 750px) {
    margin-left: ${({ theme }) => theme.spacing['2xs']};
    ${({ hasDescription }) =>
      !hasDescription &&
      css`
        > span {
          margin-top: auto;
        }
      `}
  }

  @media (max-width: 750px) {
    > span {
      margin-top: ${({ theme }) => theme.spacing['2xs']};
      ${({ hasDescription }) =>
        !hasDescription &&
        css`
          > span {
            margin-bottom: ${({ theme }) => theme.spacing['2xs']};
          }
        `}
    }
    align-items: center;
  }

  #description {
    max-height: 72px;
    overflow-y: auto;
    scrollbar-width: thin;
    > span {
      font-size: 14px;
    }

    @media (max-width: 750px) {
      max-height: unset;
      margin-bottom: ${({ theme }) => theme.spacing['2xs']};
    }
  }
`;

interface CourseButtonsWrapperProps {
  readonly hasContinue: boolean;
}
export const CourseButtonsWrapper = styled.div<CourseButtonsWrapperProps>`
  display: flex;

  @media (min-width: 750px) {
    flex-direction: row;
    justify-content: space-between;
    margin-top: auto;
    gap: ${({ theme }) => theme.spacing.xs};
    > div > span > button {
      width: ${({ hasContinue }) => (hasContinue ? '100%' : '50%')} !important;
    }
  }

  @media (max-width: 750px) {
    width: 100%;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing.xs};
    button {
      width: 100% !important;
    }
  }
`;

export const LoadingRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.spacing.xs};
  height: 150px;
  width: 100%;

  > div:first-of-type {
    width: 25%;
    height: 150px;
  }

  > div:last-of-type {
    width: 75%;
    height: 150px;
  }

  :not(:first-of-type) {
    margin-top: ${({ theme }) => theme.spacing.xs};
  }
`;
