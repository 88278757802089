import styled from 'styled-components';

export const CharacterImageMobile = styled.img`
  position: absolute;
  width: auto;
  object-fit: contain;

  transition: opacity 0.8s;

  right: 0;
  bottom: 0;
  opacity: 1;
  height: 40%;

  transform: translateX(25%) translateY(20%);

  @media (min-width: 500px) {
    display: none;
  }
`;

export const CharacterImageDesktop = styled.img`
  position: absolute;
  width: auto;
  object-fit: contain;

  transition: opacity 0.8s;

  left: 50%;
  bottom: 0;
  opacity: 1;
  height: 70%;

  @media (max-width: 1100px) {
    left: unset;
    right: 25%;
  }

  @media (max-width: 900px) {
    left: unset;
    right: 5%;
  }

  @media (max-width: 500px) {
    display: none;
  }
`;
