import { FC, useMemo } from 'react';

import { FaGraduationCap } from 'react-icons/fa';

import { Collapse, Fade } from '@chakra-ui/react';

import { useAuth } from '~/modules/auth/hooks/auth';

import { useSettings } from '~/shared/hooks/settings';

import {
  FirstCharacter,
  Container,
  Content,
  FirstArrow,
  Footer,
  Header,
  SecondArrow,
  SecondCharacter,
} from './styles';
import { IPageJourneyData } from '../../interfaces/IPageJourneyData';

interface FirstTimePopoverProps {
  readonly characterStep: 1 | 2;
  readonly data: IPageJourneyData;
  readonly onGoBack: () => void;
  readonly onNotNow: () => void;
  readonly onConfirm: () => void;
  readonly onFinish: () => Promise<void>;
}
export const FirstTimePopover: FC<FirstTimePopoverProps> = ({
  onNotNow,
  onConfirm,
  onFinish,
  onGoBack,
  characterStep,
  data: { label },
}) => {
  const { user } = useAuth();
  const firstName = useMemo(() => user?.name.split(' ')[0], [user]);

  const { settings } = useSettings();

  const FirstStepPopover = useMemo(
    () => (
      <Fade in={characterStep === 1} unmountOnExit>
        <Container>
          <FirstArrow />

          <Content>
            <Header>
              <div>
                <span>Olá {firstName},</span>
              </div>
            </Header>

            <div>
              <Collapse in>
                <p>
                  Reparei que é a sua primeira vez aqui
                  <br />
                  na opção &quot;{label}&quot;,{' '}
                  <b>
                    quer uma
                    <br />
                    ajuda para saber como funciona?
                  </b>
                </p>
              </Collapse>
            </div>

            <Footer>
              <button type="button" onClick={onConfirm}>
                Sim, eu quero
              </button>

              <button type="button" onClick={onNotNow}>
                agora não
              </button>
            </Footer>
          </Content>
        </Container>
        <FirstCharacter
          src={settings?.theme?.characters?.desktop}
          alt="..."
          draggable={false}
        />
      </Fade>
    ),
    [
      characterStep,
      firstName,
      label,
      onConfirm,
      onNotNow,
      settings?.theme?.characters?.desktop,
    ]
  );

  const SecondStepPopover = useMemo(
    () => (
      <Fade in={characterStep === 2} unmountOnExit>
        <Container>
          <SecondArrow />

          <Content>
            <Header>
              <div>
                <span>Sem problemas, {firstName}</span>
              </div>
            </Header>

            <div>
              <Collapse in>
                <p
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                  }}
                >
                  No ícone acima{' '}
                  <FaGraduationCap
                    style={{
                      width: '24px',
                      height: '24px',
                      marginLeft: '10px',
                    }}
                  />{' '}
                  os vídeos tutoriais estarão sempre disponíveis e você pode
                  assistir a qualquer momento.
                </p>
              </Collapse>
            </div>

            <Footer>
              <button type="button" onClick={onGoBack}>
                Voltar
              </button>

              <button type="button" onClick={onFinish}>
                Entendi
              </button>
            </Footer>
          </Content>
        </Container>
        <SecondCharacter
          src={settings.theme?.characters?.toolbar}
          alt="..."
          draggable={false}
        />
      </Fade>
    ),
    [
      characterStep,
      firstName,
      onFinish,
      onGoBack,
      settings.theme?.characters?.toolbar,
    ]
  );

  return (
    <>
      {FirstStepPopover}
      {SecondStepPopover}
    </>
  );
};
