import styled from 'styled-components';

export const Container = styled.li`
  position: relative;

  > div {
    position: absolute;
    bottom: -12px;
    right: -15px;
    width: 250px;
  }
`;

export const PopoverArrow = styled.div`
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid ${({ theme }) => theme.colors.surface};
  top: -7px;
  position: absolute;
  right: ${({ theme }) => `calc(${theme.spacing.sm} + 2px)`};
`;

export const PopoverContainer = styled.div`
  background: ${({ theme }) => theme.colors.surface};
  position: absolute;
  width: 100%;

  box-shadow: 3px 3px 15px #00000015;
  border-radius: ${({ theme }) => theme.borderRadius.sm};
`;

export const PopoverContent = styled.div`
  overflow: hidden;
  border-radius: ${({ theme }) => theme.borderRadius.sm};

  > header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin: ${({ theme }) =>
      `${theme.spacing['2xs']} ${theme.spacing['2xs']} 0`};
    padding-bottom: ${({ theme }) => theme.spacing.xs};
    border-bottom: 1px solid ${({ theme }) => theme.colors.background};

    h3 {
      color: ${({ theme }) => theme.colors.onSurface};
      font-size: 14px;
      font-weight: 700;
    }

    button {
      display: flex;
      align-items: center;
      justify-content: center;

      transition: all 0.4s;

      &:hover {
        opacity: 0.4;
      }

      svg {
        height: 14px;
        width: 14px;
        stroke-width: 1px;
        color: ${({ theme }) => theme.colors.onSurface};
      }
    }
  }

  ul {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: ${({ theme }) => `${theme.spacing.sm} ${theme.spacing['2xs']}`};

    overflow: auto;

    max-height: 330px;

    padding: ${({ theme }) => theme.spacing['2xs']};
  }
`;

export const AlertWrapper = styled.div`
  padding: ${({ theme }) => theme.spacing['2xs']};
`;
