/* eslint-disable @typescript-eslint/no-non-null-assertion */

import { FC } from 'react';

import { GrPlayFill } from 'react-icons/gr';

import Image from 'next/image';

import { IOfferStepSettings } from '~/modules/offers/interfaces/IOfferStepSettings';
import { IOfferStepButtonEmailSettings } from '~/modules/offers/interfaces/IOfferStepButtonEmailSettings';
import { IOfferStepButtonApiSettings } from '~/modules/offers/interfaces/IOfferStepButtonApiSettings';
import { OfferStepButtonType } from '~/modules/offers/interfaces/IOfferStepButton';
import { IOfferStep } from '~/modules/offers/interfaces/IOfferStep';

import { useVideoModal } from '~/shared/hooks/videoModal';

import { Container, Body, LogoContainer, VideoContainer } from './styles';
import { Button } from '../Button';

interface DefaultStepProps {
  offerName: string;
  settings: Omit<IOfferStepSettings, 'status'>;
  data: IOfferStep;
  onSubmit(
    type: OfferStepButtonType,
    apiSettings?: IOfferStepButtonApiSettings,
    emailSettings?: IOfferStepButtonEmailSettings,
    link?: string
  ): void;
}

export const DefaultStep: FC<DefaultStepProps> = ({
  offerName,
  data,
  settings,
  onSubmit,
}) => {
  const { open } = useVideoModal();

  return (
    <Container settings={settings}>
      <Body settings={settings} hasPicture={!!data.picture}>
        <div>
          {settings.logo && (
            <LogoContainer>
              <Image
                src={settings.logo}
                alt={offerName}
                width={120}
                height={70}
              />
            </LogoContainer>
          )}

          <h2>{data.title}</h2>

          {data.description && <p>{data.description}</p>}
        </div>

        {!!data.picture && (
          <aside>
            <Image
              src={data.picture}
              alt={offerName}
              width={250}
              height={250}
              objectFit="contain"
              objectPosition="right"
            />
          </aside>
        )}
      </Body>

      {data.videoDescription && data.videoUrl && (
        <VideoContainer
          settings={settings}
          textColor={data.videoTextColor || settings?.colors?.text}
          backgroundColor={
            data.videoBackgroundColor || settings?.colors?.secondary
          }
        >
          <div className="video-details-bordeless">
            <button type="button">
              <GrPlayFill color="#fff" />
            </button>

            <p>{data.videoDescription}</p>
          </div>

          <div className="video-details-shadow">
            <button type="button" onClick={() => open(data.videoUrl!)}>
              <GrPlayFill color="#fff" />
            </button>

            <p>{data.videoDescription}</p>
          </div>
        </VideoContainer>
      )}

      {data.buttons.length > 0 && (
        <footer>
          {data.buttons.map((button) => (
            <Button
              key={button._id}
              data={button}
              onClick={() =>
                onSubmit(
                  button.type,
                  button.apiSettings,
                  button.emailSettings,
                  button.link
                )
              }
            />
          ))}
        </footer>
      )}
    </Container>
  );
};
