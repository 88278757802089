import { FC, useEffect, useRef } from 'react';

import { Fade } from '@chakra-ui/react';

import { useOnboarding } from '../../hooks/onboarding';

import { Container, Arrow } from './styles';

import { PopoverContent } from '../PopoverContent';

interface ToolbarPopoverProps {
  isVisible: boolean;
  referenceId: string;
}

export const ToolbarPopover: FC<ToolbarPopoverProps> = ({
  isVisible,
  referenceId,
}) => {
  const { step } = useOnboarding();

  const containerRef = useRef<HTMLDivElement>(null);
  const arrowRef = useRef<HTMLDivElement>(null);
  const hasUpdated = useRef<boolean>(false);

  useEffect(() => {
    const containerElement = containerRef.current;
    const arrowElement = arrowRef.current;

    const timeout = setTimeout(() => {
      if (isVisible && containerElement && arrowElement) {
        containerElement.style.transition = 'all 0.4s';
        arrowElement.style.transition = 'all 0.8s';
      }
    }, 400);

    return () => {
      clearTimeout(timeout);

      if (containerElement && arrowElement) {
        arrowElement.style.transition = '';
        containerElement.style.transition = '';
      }
    };
  }, [isVisible]);

  useEffect(() => {
    hasUpdated.current = false;
  }, [step]);

  function calculateOffsets(): void {
    const toolbarItem = document.getElementById(referenceId);

    if (toolbarItem && containerRef.current && arrowRef.current) {
      hasUpdated.current = true;

      const arrowWidth = arrowRef.current.offsetWidth;
      const containerWidth = containerRef.current.offsetWidth;
      const windowWidth = window.innerWidth;

      const {
        right: toolbarItemRight,
        bottom: toolbarItemBottom,
        width: toolbarItemWidth,
      } = toolbarItem.getBoundingClientRect();

      let newContainerLeft =
        toolbarItemRight - containerWidth / 2 + toolbarItemWidth / 2;
      const newContainerTop = toolbarItemBottom + 12;

      const newArrowLeft =
        toolbarItemRight - arrowWidth / 2 - toolbarItemWidth / 2;

      if (
        windowWidth - toolbarItemRight <= 150 &&
        windowWidth - toolbarItemRight >= 0
      ) {
        newContainerLeft = windowWidth - containerWidth - 15;
      }

      containerRef.current.style.left = `${newContainerLeft}px`;
      containerRef.current.style.top = `${newContainerTop}px`;

      arrowRef.current.style.left = `${newArrowLeft}px`;
      arrowRef.current.style.top = `${newContainerTop - 7}px`;
    }
  }

  useEffect(() => {
    if (!isVisible || hasUpdated.current) return () => null;

    const interval = setInterval(() => {
      calculateOffsets();
    }, 10);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible, referenceId]);

  useEffect(() => {
    window.addEventListener('resize', calculateOffsets);

    return () => {
      window.removeEventListener('resize', calculateOffsets);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fade in={!!isVisible} unmountOnExit>
      <Arrow ref={arrowRef} />

      <Container ref={containerRef} id="toolbar-popover">
        <PopoverContent />
      </Container>
    </Fade>
  );
};
