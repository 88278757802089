import {
  createContext,
  useContext,
  useCallback,
  useState,
  FunctionComponent,
} from 'react';

import { ModalRoot } from '~/shared/components/ModalRoot';
import {
  ModalProps,
  closeModal as closeModalFn,
} from '~/shared/components/Modal';

import { FCWithChildren } from '../types/FCWithChildren';

export interface IModalConfig {
  onClose?: (...params: any) => void;
  props?: any;
  modalProps?: ModalProps;
}

export interface IModalProps {
  component: FunctionComponent;
  config?: IModalConfig;
}

interface IModalContextData {
  openModal: (component: FunctionComponent, config?: IModalConfig) => void;
  closeModal: () => void;
}

const ModalContext = createContext<IModalContextData>({} as IModalContextData);

const ModalProvider: FCWithChildren = ({ children }) => {
  const [modal, setModal] = useState<IModalProps | undefined>(undefined);

  const openModal = useCallback((component, config?: IModalConfig) => {
    setModal({
      component,
      config,
    });
  }, []);

  const closeModal = useCallback(() => {
    setModal(undefined);
  }, []);

  return (
    <ModalContext.Provider
      value={{ openModal, closeModal: () => closeModalFn(closeModal) }}
    >
      <ModalRoot modal={modal} handleClose={closeModal} />
      {children}
    </ModalContext.Provider>
  );
};

function useModal(): IModalContextData {
  return useContext(ModalContext);
}

export { ModalProvider, useModal };
