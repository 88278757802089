/* eslint-disable import/no-cycle */
import {
  createContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import Router, { useRouter } from 'next/router';

import { cache } from 'swr';
import { setCookie, destroyCookie, parseCookies } from 'nookies';
import decode from 'jwt-decode';

import { FCWithChildren } from '~/shared/types/FCWithChildren';
import { api } from '~/shared/services/api';
import { IResaleActivityBranchesOptions } from '~/shared/interfaces/IResaleActivityBranchesOptions';
import { IMenuJourneyData } from '~/shared/interfaces/IMenuJourneyData';
import { useSettings } from '~/shared/hooks/settings';
import { useOrigin } from '~/shared/hooks/origin';
import { useFacebookPixel } from '~/shared/hooks/facebookPixel';
import {
  DynamicSidebarProvider,
  useDynamicSidebar,
} from '~/shared/hooks/dynamicSidebar';
import { routes } from '~/shared/constants/routes';
import { cookies } from '~/shared/constants/cookies';

interface SignInCredentials {
  email: string;
  password: string;
}

export type PhoneVerificationWay = 'whatsapp' | 'sms';

export type AccountRecoveryOption = 'whatsapp' | 'sms' | 'email';

export type CheckUserExistsType = 'email' | 'document';

interface SendPhoneVerificationCodeRequest {
  phone: string;
  way: PhoneVerificationWay;
}

interface ValidatePhoneVerificationCodeRequest {
  verificationCode: string;
  phone: string;
}
interface SignUpRequest {
  name: string;
  email: string;
  document: string;
  password: string;
  phoneNumber: string;
}

interface ResetPasswordRequest {
  token: string;
  newPassword: string;
}

interface SendRecoveryAccountLinkRequest {
  option: AccountRecoveryOption;
  value: string;
}

interface CheckUserExistsRequest {
  type: CheckUserExistsType;
  value: string;
}

interface ISidebarItem {
  _id: string;
  customDescription: string;
  customTitle: string;
  defaultDescription: string;
  order: number;
  status: string;
}

interface IUserSidebarData {
  _id: string;
  customTitle: string;
  defaultTitle: string;
  items: ISidebarItem[];
  order: number;
}

export interface User {
  _id: string;
  master: boolean;
  email: string;
  segment: string;
  avatarUrl?: string;
  permissions: string[];
  name: string;
  phoneNumber: string;
  companiesCount: number;
  document: string;
  resale?: string;
  sidebar?: IUserSidebarData[];
  resaleConfig?: {
    integrations: {
      _id: string;
      order: number;
      saleChannelExtraInfo: any;
    }[];
    activityBranchesOptions: IResaleActivityBranchesOptions[];
    worksUnderContract?: boolean;
    imageOfUnsignedContract?: string;
  };
  hasFinishedOnboarding?: boolean;
  access?: Array<{
    _id: string;
    group: any;
    segment: string;
    companies: Array<{
      _id: string;
      name: string;
    }>;
  }>;

  screensJourneyConfirmations?: Array<string>;
  hasConfirmedPageJourneySecondStep?: boolean;
  menuJourneyData?: IMenuJourneyData;
  companiesWithoutContract?: {
    _id: string;
    name: string;
    document: string;
  }[];
  readonly balance?: number;
  readonly isEmailVerified?: boolean;
  readonly hasActiveVerifyEmailToken?: boolean;
  readonly transmissionsOrdernation?: Array<{
    readonly tag: string;
    readonly order: number;
  }>;
}

export interface DecodedUser {
  sub: string;
  name: string;
  email: string;
  segment: string;
}

interface IAuthContextData {
  signIn(credentials: SignInCredentials): Promise<void>;
  sendPhoneVerificationCode(
    data: SendPhoneVerificationCodeRequest
  ): Promise<void>;
  validatePhoneVerificationCode(
    data: ValidatePhoneVerificationCodeRequest
  ): Promise<void>;
  isAuthenticated: boolean;
  user: User | null;
  phoneNumber: string;
  setPhoneNumber: Dispatch<SetStateAction<string>>;
  checkUserExists(data: CheckUserExistsRequest): Promise<boolean>;
  sendRecoveryAccountLink(data: SendRecoveryAccountLinkRequest): Promise<void>;
  signUp(data: SignUpRequest): Promise<void>;
  resetPassword(data: ResetPasswordRequest): Promise<void>;
  addLocalUserScreenConfirmation(screenId: string): void;
  addLocalUserConfirmedSecondStepJourney(): void;
  setActiveEmailVerificationToken(): void;
}

const AuthContext = createContext({} as IAuthContextData);

export function signOut(): void {
  destroyCookie(null, cookies.AUTH_TOKEN, { path: '/' });
  destroyCookie(null, cookies.SEGMENT_ID, { path: '/' });
  destroyCookie(null, cookies.USER_COMPANIES, { path: '/' });
  destroyCookie(null, cookies.APP_EDITING_DATA, { path: '/' });
  destroyCookie(null, cookies.SELECTED_PLAN, { path: '/' });
  destroyCookie(null, cookies.CURRENT_RESALE_CODE, { path: '/' });

  cache.clear();

  Router.push(routes.auth.signIn);
}

const AuthProvider: FCWithChildren = ({ children }) => {
  const {
    query: { resale },
  } = useRouter();
  const { origin } = useOrigin();

  const {
    loadSidebarConfigurations,
    saveSidebarConfigurations,
    clearSidebarConfigurations,
  } = useDynamicSidebar();
  const { settings } = useSettings();
  const { track } = useFacebookPixel();

  const [user, setUser] = useState<User | null>(null);
  const [phoneNumber, setPhoneNumber] = useState('');

  const isAuthenticated = !!user;

  const loadUserData = useCallback(async () => {
    loadSidebarConfigurations();

    const { [cookies.AUTH_TOKEN]: token } = parseCookies();
    const { [cookies.SEGMENT_ID]: segment } = parseCookies();

    if (token && segment) {
      try {
        const { sub: userId } = decode<DecodedUser>(token);

        const response = await api.get(`/segments/${segment}/users/${userId}`);

        if (response.data) {
          const { sidebar } = response.data;

          saveSidebarConfigurations(sidebar);

          const companiesCount = response.data.access?.[0].companies;

          setCookie(
            undefined,
            cookies.CURRENT_RESALE_CODE,
            response.data.resale,
            {
              maxAge: 60 * 60 * 24, // 24 hours
              path: '/',
            }
          );

          const companyIds = response.data.access?.[0].companies?.map(
            (company) => company._id
          );

          setCookie(
            undefined,
            cookies.USER_COMPANIES,
            companyIds?.join('|') || '',
            {
              maxAge: 60 * 60 * 24, // 24 hours
              path: '/',
            }
          );

          setUser({
            ...response.data,
            companiesCount,
            segment,
          });

          if (response.data?.companiesWithoutContract?.length > 0) {
            Router.push(routes.contract);
            return;
          }
        } else {
          clearSidebarConfigurations();
          signOut();
        }
      } catch {
        clearSidebarConfigurations();
        signOut();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clearSidebarConfigurations, saveSidebarConfigurations]);

  useEffect(() => {
    (async () => {
      await loadUserData();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const signIn = useCallback(
    async (credentials: SignInCredentials) => {
      cache.clear();

      const response = await api.post('/auth/signin', credentials, {
        headers: {
          resalecode: settings.code,
        },
      });

      const { token, resale: resaleCode, sidebar, ...rest } = response.data;
      saveSidebarConfigurations(sidebar);

      const segment = rest.access?.[0]?.segment?._id;

      setCookie(undefined, cookies.AUTH_TOKEN, token, {
        maxAge: 60 * 60 * 24, // 24 hours
        path: '/',
      });

      setCookie(undefined, cookies.CURRENT_RESALE_CODE, resaleCode, {
        maxAge: 60 * 60 * 24, // 24 hours
        path: '/',
      });

      if (segment) {
        setCookie(undefined, cookies.SEGMENT_ID, segment, {
          maxAge: 60 * 60 * 24, // 24 hours
          path: '/',
        });
      }

      const companyIds =
        rest.access?.[0]?.companies?.map((company) => company._id) || [];

      setCookie(undefined, cookies.USER_COMPANIES, companyIds.join('|') || '', {
        maxAge: 60 * 60 * 24, // 24 hours
        path: '/',
      });

      api.defaults.headers.Authorization = `Bearer ${token}`;

      setUser({
        _id: rest._id,
        email: rest.email,
        name: rest.name,
        permissions: rest.permissions,
        avatarUrl: rest.avatarUrl,
        phoneNumber: rest.phoneNumber,
        resale: rest.resale,
        resaleConfig: rest.resaleConfig,
        menuJourneyData: rest.menuJourneyData,
        screensJourneyConfirmations: rest.screensJourneyConfirmations,
        segment,
        companiesCount: rest.access?.[0]?.companies?.lenght || 0,
        ...rest,
      });

      if (response?.data?.companiesWithoutContract?.length > 0) {
        Router.push(routes.contract);
      } else {
        Router.push(routes.setup);
      }
    },
    [saveSidebarConfigurations, settings?.code]
  );

  const sendPhoneVerificationCode = useCallback(
    async ({ way, phone }: SendPhoneVerificationCodeRequest) => {
      const formattedWay = way === 'whatsapp' ? 'whats-app' : 'sms';

      const response = await api.post('/auth/send-phone-verification', {
        phoneNumber: phone,
        type: formattedWay,
        resale: resale || settings?.code,
      });

      return response.data;
    },
    [resale, settings]
  );

  const validatePhoneVerificationCode = useCallback(
    async ({
      verificationCode,
      phone,
    }: ValidatePhoneVerificationCodeRequest) => {
      const response = await api.post('/auth/check-phone-verification', {
        verificationCode,
        phoneNumber: phone,
      });

      if (response.data) {
        if (typeof window.gtag !== 'undefined') {
          window.gtag('event', 'conversion', {
            send_to: 'AW-591117712/3hN6CNK1tfgCEJD77pkC',
          });
        }

        track('Contact');

        setPhoneNumber(phone);
      }
    },
    [track]
  );

  const sendRecoveryAccountLink = useCallback(
    async ({ option, value }: SendRecoveryAccountLinkRequest) => {
      await api.post('/auth/forgot-password', {
        option,
        value,
      });
    },
    []
  );

  const checkUserExists = useCallback(
    async ({ type, value }: CheckUserExistsRequest) => {
      const response = await api.get('/auth/check', {
        params: {
          ...(type === 'document' ? { document: value } : { email: value }),
        },
      });

      return response.data;
    },
    []
  );

  const signUp = useCallback(
    async (requestDto: SignUpRequest) => {
      await api.post('/auth/signup', {
        ...requestDto,
        resale: resale || settings?.code,
      });

      track('Lead');

      if (typeof window.gtag !== 'undefined') {
        let sendTo = 'AW-591117712/J4hICK2z2eUCEJD77pkC';

        if (origin === 'pdv-facil-site') {
          sendTo = 'AW-10901029545/-7TVCPXJob4DEKmFgs4o';
        }

        if (origin === 'menu-facil-site') {
          sendTo = 'AW-10901702762/Z0cgCP68ob4DEOqQq84o';
        }

        if (origin === 'totem-site') {
          sendTo = 'AW-10901680226/Ex7PCOOF7r0DEOLgqc4o';
        }

        window.gtag('event', 'conversion', {
          send_to: sendTo,
          value: 1.0,
          currency: 'BRL',
        });
      }
    },
    [origin, resale, settings?.code, track]
  );

  const resetPassword = useCallback(
    async ({ token, newPassword }: ResetPasswordRequest) => {
      await api.post('/auth/change-forgot-password', {
        token,
        newPassword,
      });
    },
    []
  );

  const addLocalUserScreenConfirmation = useCallback((screenId: string) => {
    setUser((prevState) => ({
      ...prevState,
      screensJourneyConfirmations: [
        ...(prevState.screensJourneyConfirmations || []),
        screenId,
      ],
    }));
  }, []);

  const addLocalUserConfirmedSecondStepJourney = useCallback(() => {
    setUser((prevState) => ({
      ...prevState,
      hasConfirmedPageJourneySecondStep: true,
    }));
  }, []);

  const setActiveEmailVerificationToken = useCallback(() => {
    setUser((prevState) => ({
      ...prevState,
      hasActiveVerifyEmailToken: true,
    }));
  }, []);

  return (
    <AuthContext.Provider
      value={{
        signIn,
        sendRecoveryAccountLink,
        sendPhoneVerificationCode,
        checkUserExists,
        validatePhoneVerificationCode,
        addLocalUserScreenConfirmation,
        addLocalUserConfirmedSecondStepJourney,
        setActiveEmailVerificationToken,
        resetPassword,
        phoneNumber,
        setPhoneNumber,
        isAuthenticated,
        signUp,
        user,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = (): IAuthContextData => {
  return useContext(AuthContext);
};

const AuthProviderHOC: FCWithChildren = ({ children }) => (
  <DynamicSidebarProvider>
    <AuthProvider>{children}</AuthProvider>
  </DynamicSidebarProvider>
);

export { AuthProviderHOC, useAuth };
