import { RiCloseLine } from 'react-icons/ri';

import styled, { ColorScheme, css } from 'styled-components';

export type AlertType =
  | 'primary'
  | 'info'
  | 'success'
  | 'error'
  | 'warning'
  | 'terciary'
  | 'secondary'
  | 'gray';

interface AlertContainerProps {
  type: AlertType;
  isFullWidth: boolean;
  backgroundOpacity: number;
  forceTextColor?: ColorScheme;
  closeOnX?: boolean;
}

const textColor: Record<AlertType, 'surface' | 'onSurface' | 'onBackground'> = {
  error: 'onSurface',
  info: 'onSurface',
  primary: 'surface',
  success: 'onSurface',
  secondary: 'onSurface',
  warning: 'onSurface',
  terciary: 'onSurface',
  gray: 'onBackground',
};

export const Container = styled.div<AlertContainerProps>`
  display: ${({ isFullWidth }) => (isFullWidth ? 'flex' : 'inline-flex')};
  align-items: center;
  min-height: 50px;
  border-radius: ${({ theme }) => theme.borderRadius.sm};
  padding: ${({ theme }) => `${theme.spacing.xs} ${theme.spacing.sm}`};

  ${({ closeOnX }) =>
    closeOnX &&
    css`
      position: relative;
    `};

  > div {
    p {
      color: ${({ theme, type, forceTextColor }) =>
        forceTextColor || theme.colors[textColor[type]]};
      font-size: 13px;
      line-height: 22px;
    }

    a {
      text-decoration: underline;
      font-weight: 600;
    }
  }

  font-size: 13px;
  line-height: 22px;
  background-color: ${({ theme, type, backgroundOpacity }) =>
    `${theme.colors[type]}${backgroundOpacity}`};
  border: ${({ theme, type }) => `1px solid ${theme.colors[type]}`};
  color: ${({ theme, type, forceTextColor }) =>
    forceTextColor || theme.colors[textColor[type]]};
  border-left-width: 5px;
`;

export const CloseIcon = styled(RiCloseLine)`
  width: 20px;
  height: 20px;
  margin-left: auto;
  cursor: pointer;

  position: relative;
`;

export const CloseOnXContainer = styled.div`
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0;
  padding: 5px;
`;
