import styled from 'styled-components';

export const Container = styled.div`
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
`;

export const Content = styled.div`
  height: 100%;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  padding: 20px;

  > button {
    background: none;
    border: none;
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 99;
    padding: 0;
    margin: 0;

    i {
      font-size: 25px;
      color: #fff;

      @media (max-width: 700px) {
        font-size: 20px;
      }
    }
  }

  > div {
    max-width: 760px !important;
    width: 100%;
    max-height: 520px !important;
    height: 100%;

    iframe {
      width: 100% !important;
      height: 100% !important;
    }
  }
`;
