/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { ChangeEvent, FC, useCallback, useRef, useState } from 'react';

import { RiCloseLine, RiQuestionFill } from 'react-icons/ri';
import { BiTrash } from 'react-icons/bi';

import { useTheme } from 'styled-components';
import { Collapse, ScaleFade, useToast } from '@chakra-ui/react';

import { maskPhoneNumber } from '~/shared/utils/masks';
import { api } from '~/shared/services/api';
import { useFiles } from '~/shared/hooks/files';
import { useClickOutside } from '~/shared/hooks/clickOutside';
import { ReferenceIdEnum } from '~/shared/enums/ReferenceIdEnum';
import { ToolbarIconButton } from '~/shared/components/Toolbar/ToolbarIconButton';
import { Textarea } from '~/shared/components/Form/Textarea';
import { LabelText } from '~/shared/components/Form/LabelText';
import { Input } from '~/shared/components/Form/Input';
import { FormGroup } from '~/shared/components/Form/FormGroup';
import { Button } from '~/shared/components/Form/Button';
import { CustomOptionButton } from '~/shared/components/CustomOptionButton';

import {
  HelpDropdownContainer,
  HelpTypesRow,
  PopoverArrow,
  PopoverContainer,
  PopoverContent,
  PopoverHeader,
} from './styles';
import { IHelp } from '../../interfaces/IHelp';
import {
  HelpTypesEnum,
  HelpTypesEnumFormated,
} from '../../enums/HelpTypesEnum';

export const HelpDropdown: FC = () => {
  const { registerImage } = useFiles();
  const theme = useTheme();
  const attachFileRef = useRef<HTMLInputElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [formData, setFormData] = useState<IHelp>({} as IHelp);
  const [uploadImageLoading, setUploadImageLoading] = useState<boolean>(false);
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<IHelp>({} as IHelp);
  const toast = useToast({
    isClosable: true,
    status: 'error',
    position: 'top-right',
  });

  const closePopover = useCallback(() => {
    setIsOpen(false);
  }, []);

  const handleAttachFile = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files[0];

      const releasedFileTypes = ['png', 'jpeg', 'svg'];

      const fileType = file?.type?.split('/')[1];

      if (!releasedFileTypes.includes(fileType)) {
        toast({
          status: 'warning',
          title: 'Esse tipo de arquivo não é permitido',
        });

        return;
      }

      setUploadImageLoading(true);
      await registerImage(file)
        .then((fileUrl) =>
          setFormData((prevState) => ({ ...prevState, evidence: fileUrl }))
        )
        .catch(() =>
          toast({
            title: 'Erro',
            description: 'Houve um erro ao carregar a imagem',
          })
        )
        .finally(() => setUploadImageLoading(false));
    },
    [registerImage]
  );

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { value, name } = event.target;
      setFormData((prevState) => ({ ...prevState, [name]: value }));
    },
    []
  );

  const changeDoubtType = useCallback((type: HelpTypesEnum) => {
    setFormData((prevState) => ({ ...prevState, type }));
  }, []);

  const handleSubmit = useCallback(async () => {
    const currentErrors: IHelp = {} as IHelp;

    !formData.name &&
      Object.assign(currentErrors, {
        name: 'Nome é obrigatório',
      });

    !formData.phoneNumber &&
      Object.assign(currentErrors, {
        phoneNumber: 'Número de telefone é obrigatório',
      });

    !formData.email &&
      Object.assign(currentErrors, {
        email: 'E-mail é obrigatório',
      });

    !formData.data &&
      Object.assign(currentErrors, {
        data: `${HelpTypesEnumFormated[formData.type]} é obrigatório`,
      });

    if (Object.keys(currentErrors)?.length > 0) {
      setErrors(currentErrors);
    } else {
      errors && Object.keys(errors)?.length > 0 && setErrors(undefined);
      setSubmitLoading(true);

      await api
        .post('/help', formData)
        .then(() => {
          toast({
            title: 'Sucesso',
            description: 'Informações salvas com sucesso',
            status: 'success',
          });
          closePopover();
        })
        .catch(() =>
          toast({
            title: 'Erro',
            description: 'Houve um erro ao enviar as informações',
          })
        )
        .finally(() => setSubmitLoading(false));
    }
  }, [formData, errors, closePopover]);

  const popoverRef = useClickOutside<HTMLLIElement>(closePopover);

  return (
    <HelpDropdownContainer ref={popoverRef} id="help">
      <ToolbarIconButton
        tooltip="Ajuda"
        aria-label="help"
        id={ReferenceIdEnum['help-item']}
        icon={RiQuestionFill}
        iconSize={24}
        onClick={() => setIsOpen(true)}
      />

      <ScaleFade in={isOpen} initialScale={0.9} unmountOnExit>
        <PopoverContainer>
          <PopoverArrow />
          <div>
            <PopoverHeader>
              <span>Você está na área de ajuda!</span>
              <RiCloseLine
                style={{ cursor: 'pointer', height: '30px', width: '30px' }}
                onClick={closePopover}
              />
            </PopoverHeader>
            <PopoverContent>
              <hr />
              <LabelText>Selecione o tipo de ajuda que precisa!</LabelText>
              <HelpTypesRow>
                <CustomOptionButton
                  height={75}
                  isSelected={formData.type === HelpTypesEnum.DOUBT}
                  onClick={() =>
                    changeDoubtType(
                      formData.type === HelpTypesEnum.DOUBT
                        ? undefined
                        : HelpTypesEnum.DOUBT
                    )
                  }
                  text={HelpTypesEnumFormated.doubt}
                />
                <CustomOptionButton
                  height={75}
                  isSelected={formData.type === HelpTypesEnum.SUGESTION}
                  onClick={() =>
                    changeDoubtType(
                      formData.type === HelpTypesEnum.SUGESTION
                        ? undefined
                        : HelpTypesEnum.SUGESTION
                    )
                  }
                  text={HelpTypesEnumFormated.sugestion}
                />
                <CustomOptionButton
                  height={75}
                  isSelected={formData.type === HelpTypesEnum.PROBLEM}
                  onClick={() =>
                    changeDoubtType(
                      formData.type === HelpTypesEnum.PROBLEM
                        ? undefined
                        : HelpTypesEnum.PROBLEM
                    )
                  }
                  text={HelpTypesEnumFormated.problem}
                />
              </HelpTypesRow>
              <Collapse in={!!formData.type}>
                <FormGroup style={{ marginTop: theme.spacing['2xs'] }}>
                  <Input
                    name="name"
                    label="Qual seu nome?"
                    placeholder="Digite o seu nome"
                    onChange={handleChange}
                    value={formData.name}
                    error={errors?.name}
                  />
                </FormGroup>

                <FormGroup>
                  <Input
                    name="phoneNumber"
                    label="Qual seu telefone?"
                    placeholder="Digite o seu telefone com DDD"
                    onChange={handleChange}
                    error={errors?.phoneNumber}
                    value={
                      formData.phoneNumber &&
                      maskPhoneNumber(formData.phoneNumber)
                    }
                  />
                </FormGroup>

                <FormGroup>
                  <Input
                    name="email"
                    label="Qual seu e-mail?"
                    placeholder="Digite o seu email"
                    onChange={handleChange}
                    error={errors?.email}
                    value={formData.email}
                  />
                </FormGroup>

                {formData.type === 'doubt' ? (
                  <>
                    <FormGroup>
                      <Textarea
                        name="data"
                        label="Qual a sua dúvida?"
                        placeholder="Informe a sua dúvida (500 caracteres)"
                        onChange={handleChange}
                        value={formData.data}
                        error={errors?.data && 'Dúvida é obrigatória'}
                        maxLength={500}
                      />
                    </FormGroup>

                    <FormGroup>
                      <Button
                        style={{ width: '120px', marginLeft: 'auto' }}
                        onClick={handleSubmit}
                        isLoading={submitLoading}
                      >
                        Enviar
                      </Button>
                    </FormGroup>
                  </>
                ) : formData.type === 'sugestion' ? (
                  <>
                    <FormGroup>
                      <Textarea
                        name="data"
                        label="Qual a sua sugestão?"
                        placeholder="Informe a sua sugestão (500 caracteres)"
                        onChange={handleChange}
                        value={formData.data}
                        error={errors?.data && 'Sugestão é obrigatória'}
                        maxLength={500}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Button
                        style={{ width: '120px', marginLeft: 'auto' }}
                        onClick={handleSubmit}
                        isLoading={submitLoading}
                      >
                        Enviar
                      </Button>
                    </FormGroup>
                  </>
                ) : (
                  formData.type === 'problem' && (
                    <>
                      <FormGroup>
                        <Button
                          onClick={() => attachFileRef?.current?.click()}
                          isLoading={uploadImageLoading}
                        >
                          {formData.evidence
                            ? 'Alterar documento'
                            : 'Anexar documento (Opcional)'}
                        </Button>
                        <input
                          hidden
                          type="file"
                          accept=".jpg, .bmp, .png, .jpeg"
                          maxLength={1}
                          ref={attachFileRef}
                          onChange={handleAttachFile}
                        />
                        {formData.evidence && (
                          <Button
                            style={{ width: '50px', marginLeft: 'auto' }}
                            backgroundColor="surface"
                            borderColor="primary"
                            onClick={() =>
                              setFormData((prevState) => ({
                                ...prevState,
                                evidence: undefined,
                              }))
                            }
                          >
                            <BiTrash color={theme.colors.primary} />
                          </Button>
                        )}
                      </FormGroup>

                      <FormGroup>
                        <Textarea
                          name="data"
                          label="Qual é o seu problema?"
                          placeholder="Informe o seu problema (500 caracteres)"
                          onChange={handleChange}
                          value={formData.data}
                          maxLength={500}
                          error={errors?.data && 'Problema é obrigatório'}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Button
                          style={{ width: '120px', marginLeft: 'auto' }}
                          onClick={handleSubmit}
                          isLoading={submitLoading}
                        >
                          Enviar
                        </Button>
                      </FormGroup>
                    </>
                  )
                )}
              </Collapse>
            </PopoverContent>
          </div>
        </PopoverContainer>
      </ScaleFade>
    </HelpDropdownContainer>
  );
};
