import styled from 'styled-components';

import { IOfferStepSettings } from '~/modules/offers/interfaces/IOfferStepSettings';

interface ContainerProps {
  settings: Omit<IOfferStepSettings, 'status'>;
}

export const Container = styled.div<ContainerProps>`
  background-color: ${({ settings }) => settings?.colors?.primary};
  background-image: ${({ settings }) => `url(${settings.backgroundImage})`};
  background-position: center;
  background-size: cover;
  flex: 1;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;

    padding: ${({ theme }) => theme.spacing['2xs']};
    padding-bottom: 0;
    gap: ${({ theme }) => `calc(${theme.spacing['2xs']} / 2)`};

    > h2 {
      font-size: 18px;
      font-weight: 800;
      color: ${({ settings }) => settings?.colors?.text};
      text-align: center;
    }

    > p {
      color: ${({ settings }) => settings?.colors?.text};
      font-size: 14px;
      text-align: center;
      line-height: 22px;
    }
  }

  > footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    margin-top: auto;

    padding: ${({ theme }) => theme.spacing['2xs']};
    margin-top: ${({ theme }) => theme.spacing['2xs']};
    border-top: 1px solid ${({ settings }) => settings?.colors?.secondary};
  }
`;

export const LogoContainer = styled.div`
  height: 70px;
  width: auto;
  display: flex;
  margin-bottom: ${({ theme }) => `calc(${theme.spacing['2xs']} / 2)`};
`;
