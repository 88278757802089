import { FC } from 'react';

import { Fade } from '@chakra-ui/react';

import { useSettings } from '~/shared/hooks/settings';

import { CharacterImageDesktop, CharacterImageMobile } from './styles';

interface SideBarCharacterProps {
  isVisible: boolean;
}

export const SideBarCharacter: FC<SideBarCharacterProps> = ({ isVisible }) => {
  const { settings } = useSettings();
  const { theme } = settings || {};
  const {
    mobileSidebar: sideBarMobileCharacter,
    desktop: sideBarDesktopCharacter,
  } = theme?.characters || {};

  // const characterRef = useRef<HTMLImageElement>(null);
  //
  // const hasUpdated = useRef<boolean>(false);
  //
  // useEffect(() => {
  //   hasUpdated.current = false;
  //
  //   if (characterRef.current && step === 5) {
  //     characterRef.current.style.opacity = '1';
  //   }
  // }, [step]);
  //
  // const calculateHeight = useCallback(() => {
  //   if (!isVisible) return;
  //
  //   const sideBarPopover = document.getElementById('sidebar-popover');
  //
  //   if (sideBarPopover && sideBarPopover) {
  //     hasUpdated.current = true;
  //
  //     const characterWidth = characterRef.current.getBoundingClientRect().width;
  //
  //     const { bottom: sideBarPopoverBottom, right: sideBarPopoverRight } =
  //       sideBarPopover.getBoundingClientRect();
  //     const windowHeight = window.innerHeight;
  //
  //     const newCharacterHeight = windowHeight - sideBarPopoverBottom - 30;
  //
  //     const newCharacterLeft = sideBarPopoverRight - characterWidth / 2;
  //
  //     if (windowHeight - sideBarPopoverBottom - 30 - 500 <= 30) {
  //       characterRef.current.style.height = `${500}px`;
  //       characterRef.current.style.left = `${sideBarPopoverRight + 60}px`;
  //     } else {
  //       characterRef.current.style.height = `${newCharacterHeight}px`;
  //       characterRef.current.style.left = `${newCharacterLeft}px`;
  //     }
  //   }
  // }, [isVisible]);
  //
  // useEffect(() => {
  //   if (!isVisible || hasUpdated.current) return () => null;
  //
  //   const interval = setInterval(() => {
  //     calculateHeight();
  //   }, 10);
  //
  //   return () => clearInterval(interval);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [step]);
  //
  // useEffect(() => {
  //   window.addEventListener('resize', calculateHeight);
  //
  //   return () => {
  //     window.removeEventListener('resize', calculateHeight);
  //   };
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [step]);

  return (
    <Fade in={isVisible}>
      <CharacterImageMobile src={sideBarMobileCharacter} alt="..." />
      <CharacterImageDesktop src={sideBarDesktopCharacter} alt="..." />
    </Fade>
  );
};
