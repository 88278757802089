import { FC, useEffect, useRef } from 'react';

import Link from 'next/link';

import { AppBanner } from '~/modules/apps/components/AppBanner';

import { useElementSize } from '~/shared/hooks/elementSize';
import { routes } from '~/shared/constants/routes';

import { Container } from './styles';

interface FavoriteAppProps {
  name: string;
  image: string;
  backgroundColor: string;
  tag: string;
}

export const FavoriteApp: FC<FavoriteAppProps> = ({
  name,
  image,
  backgroundColor,
  tag,
}) => {
  const nameContainerRef = useRef<HTMLSpanElement>(null);
  const nameRef = useRef<HTMLParagraphElement>(null);
  const invisbleNameRef = useRef<HTMLParagraphElement>(null);

  const { height: nameHeight } = useElementSize(invisbleNameRef);

  useEffect(() => {
    if (nameHeight > 0 && nameHeight / 17 > 1) {
      nameRef.current.innerHTML = `${nameRef.current.innerHTML.substring(
        0,
        6
      )}...`;
    }
  }, [nameHeight]);

  const handleMouseEnter = (): void => {
    if (
      !nameContainerRef.current ||
      !nameRef.current ||
      !invisbleNameRef.current
    )
      return;

    const { height: invisibleNameHeight } =
      invisbleNameRef.current.getBoundingClientRect();

    nameRef.current.innerHTML = name;

    nameContainerRef.current.style.height = `${invisibleNameHeight + 5}px`;
  };

  const handleMouseLeave = (): void => {
    if (
      !nameContainerRef.current ||
      !nameRef.current ||
      !invisbleNameRef.current
    )
      return;

    nameContainerRef.current.style.height = '22px';

    setTimeout(() => {
      const { height: invisibleNameHeight } =
        invisbleNameRef.current.getBoundingClientRect();

      if (invisibleNameHeight / 17 > 1) {
        nameRef.current.innerHTML = `${nameRef.current.innerHTML.substring(
          0,
          6
        )}...`;
      }
    }, 250);
  };

  return (
    <Container>
      <Link
        href={routes.apps.settings(tag)}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <AppBanner
          backgroundColor={backgroundColor}
          image={image}
          name={name}
        />

        <span ref={nameContainerRef}>
          <p ref={nameRef}>{name}</p>

          <p className="isInvisble" ref={invisbleNameRef}>
            {name}
          </p>
        </span>
      </Link>
    </Container>
  );
};
