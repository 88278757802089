import styled from 'styled-components';

export const Container = styled.header`
  display: flex;
  align-items: center;

  width: 100%;

  background: ${({ theme }) => theme.colors.background};
  border-bottom: 1px solid #ddd;

  z-index: 10;

  > div {
    padding: ${({ theme }) => `${theme.spacing['2xs']} ${theme.spacing.md}`};
    height: 60px;
    width: 100%;

    display: flex;
    align-items: center;
  }
`;

export const LeftContent = styled.div`
  display: flex;
  align-items: center;

  flex: 1;
  overflow: hidden;
  margin-right: ${({ theme }) => theme.spacing['2xs']};

  gap: ${({ theme }) => theme.spacing.sm};

  > span {
    max-width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`;

export const ActionsWrapper = styled.ul`
  display: flex;
  align-items: center;

  gap: 24px;

  > li {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
