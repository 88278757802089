import { useCallback } from 'react';

import useSWR from 'swr';

import { getSegmentFromCookies } from '~/shared/utils/getSegmentFromCookies';

import { api } from '~/shared/services/api';

import { IFavoriteApp } from '../interfaces/IFavoriteApp';

interface UseFavoriteAppsResponse {
  favoriteApps: IFavoriteApp[];
  isLoading: boolean;
  toggleIsFavorited(appId: string): Promise<void>;
}

export function useFavoriteApps(): UseFavoriteAppsResponse {
  const segment = getSegmentFromCookies();

  const {
    data: favoriteApps,
    error,
    mutate,
  } = useSWR<IFavoriteApp[]>(`/segments/${segment}/favorite-apps`);

  const toggleIsFavorited = useCallback(
    async (appId: string) => {
      const response = await api.post(`/segments/${segment}/favorite-apps`, {
        app: appId,
      });

      if (response.data) {
        mutate((old) => [response.data, ...old], false);
      } else {
        mutate(
          (old) =>
            old?.length > 0
              ? old.filter((item) => item.app?._id !== appId)
              : [],
          false
        );
      }
    },
    [mutate, segment]
  );

  return {
    favoriteApps,
    isLoading: !favoriteApps && !error,
    toggleIsFavorited,
  };
}
