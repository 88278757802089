/* eslint-disable react/require-default-props */
import { useEffect, useMemo, useState } from 'react';

import { Text } from './styles';

interface TypeWriterProps {
  children: string;
  cursor?: string;
  speed?: number;
  letterWidth?: number;
}

export const TypeWriter = ({
  children: text = '',
  letterWidth = 22,
  speed = 140,
  cursor = '_',
}: TypeWriterProps): JSX.Element => {
  const [index, setIndex] = useState<number>(0);

  const [currentText, setCurrentText] = useState('');

  const minTextWidth = useMemo(() => {
    const longerTextLength = text?.split('\n').reduce((acc, value) => {
      let newAcc = acc || 0;

      if (value.length > newAcc) newAcc = value.length;

      return newAcc;
    }, 0);

    return longerTextLength * letterWidth;
  }, [text, letterWidth]);

  useEffect(() => {
    setCurrentText('');
  }, [text]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setCurrentText((prevState) => prevState + text?.charAt(index));
      setIndex((prevState) => prevState + 1);
    }, speed);

    return () => clearTimeout(timeout);
  }, [text, currentText, speed, index]);

  function reset(): void {
    setIndex(0);
    setCurrentText('');
  }

  return (
    <Text style={{ minWidth: minTextWidth }} cursor={cursor} onClick={reset}>
      {currentText}
    </Text>
  );
};
