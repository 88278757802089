import { useMemo } from 'react';

import { useAuth } from '~/modules/auth/hooks/auth';

interface Balance {
  readonly balance: number;
}
export const useBalance = (): Balance => {
  const { user } = useAuth();

  const memoBalance = useMemo(() => {
    if (user) {
      const { balance } = user;
      if (balance) {
        return balance;
      }
    }

    return 0;
  }, [user]);

  return { balance: memoBalance };
};
