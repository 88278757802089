import { FC } from 'react';

import { IoClose, IoSchool } from 'react-icons/io5';

import { Collapse, Tooltip } from '@chakra-ui/react';

import { useOnboarding } from '../../hooks/onboarding';

import { Container, Header, Footer } from './styles';

export const PopoverContent: FC = () => {
  const { next, step, close, content, maxStep } = useOnboarding();

  return (
    <Container>
      <Header>
        <div>
          <IoSchool />

          <span>
            {step}/{maxStep}
          </span>
        </div>

        <Tooltip label="Fechar" hasArrow>
          <button type="button" onClick={() => close()}>
            <IoClose />
          </button>
        </Tooltip>
      </Header>

      <Collapse in={!!content} animateOpacity unmountOnExit>
        <div>
          <p>{content}</p>
        </div>
      </Collapse>

      <Footer>
        <button type="button" onClick={next}>
          {step === maxStep ? 'finalizar' : 'avançar'}
        </button>
      </Footer>
    </Container>
  );
};
