export enum HelpTypesEnum {
  DOUBT = 'doubt',
  SUGESTION = 'sugestion',
  PROBLEM = 'problem',
}

export enum HelpTypesEnumFormated {
  doubt = 'Dúvida',
  sugestion = 'Sugestão',
  problem = 'Problema',
}
