import styled, { ColorScheme, css } from 'styled-components';

interface ContainerProps {
  color?: ColorScheme;
  noWrap?: boolean;
  fontSize: number;
  fontWeight: number;
}

export const Container = styled.span<ContainerProps>`
  display: flex;
  align-items: center;

  gap: ${({ theme }) => theme.spacing.xxs};

  margin-bottom: ${({ theme }) => theme.spacing.xxs};

  > span {
    display: block;

    color: ${({ theme, color }) => theme.colors[color]};
    font-size: ${({ fontSize }) => `${fontSize}px`};
    line-height: 15px;
    font-weight: ${({ fontWeight }) => fontWeight};
    ${({ noWrap }) =>
      noWrap &&
      css<ContainerProps>`
        white-space: nowrap;
      `};
  }

  svg {
    width: 15px;
    height: 15px;
    color: ${({ theme, color }) => theme.colors[color]};
  }
`;
