import styled from 'styled-components';

export const Container = styled.div`
  height: 28px;
  display: flex;
  flex-direction: row;
  box-shadow: 0px 3px 6px #00000021;
  border-radius: 10px;
`;

export const LeftContent = styled.div`
  background-color: ${({ theme }) => theme.colors.primary};
  height: 28px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;

  > svg {
    cursor: pointer;
    margin-right: ${({ theme }) => theme.spacing.xs};
    margin-bottom: unset;
    color: ${({ theme }) => theme.colors.surface};
  }

  > span {
    user-select: none;
    pointer-events: none;
    color: ${({ theme }) => theme.colors.surface};
    line-height: 15px;
    font-weight: 600;
    font-size: 11px;
    opacity: 0.9;
    margin-top: 2px;
    margin-bottom: unset;
  }
`;

export const RightContent = styled.div`
  background-color: #cffff5;
  opacity: 0.5;
  height: 28px;
  border: 1px solid #4bc2a9;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;

  > span {
    font-size: 16px;
    line-height: 15px;
    font-weight: 700;
    margin-bottom: unset;
    > span {
      color: #006a54 !important;
      font-weight: bold !important;
    }
  }
`;
