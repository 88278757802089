import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1;  }
`;

export const Container = styled.div`
  position: absolute;
  z-index: 110;
  width: 270px;
  animation: ${fadeIn} 0.5s;
`;

export const Arrow = styled.div`
  width: 0;
  height: 0;
  border-right: 7px solid transparent;
  border-left: 7px solid transparent;
  border-bottom: 7px solid #fff;
  position: absolute;
  animation: ${fadeIn} 0.5s;
  z-index: 130;
`;
