import { FC, useState } from 'react';

import { MdPlayArrow } from 'react-icons/md';

import { useTheme } from 'styled-components';

import { Image } from '~/shared/components/Image';

import { CourseVideoButtonContainer, PlayButton } from './styles';

interface CourseVideoButtonProps {
  readonly name: string;
  readonly source: string;
  readonly onClick: () => void;
}
export const CourseVideoButton: FC<CourseVideoButtonProps> = ({
  onClick,
  source,
  name,
}) => {
  const theme = useTheme();
  const [hover, setHover] = useState<boolean>(false);

  const applyHover = (): void => {
    setHover(true);
  };

  const clearHover = (): void => {
    setHover(false);
  };

  return (
    <CourseVideoButtonContainer
      onClick={onClick}
      onMouseOver={applyHover}
      onMouseLeave={clearHover}
    >
      <PlayButton visible={hover}>
        <div>
          <MdPlayArrow
            style={{ width: '35px', height: '35px', opacity: 1 }}
            color={theme.colors.surface}
          />
        </div>
      </PlayButton>
      <Image src={source} alt={name} objectFit="contain" width={175} />
    </CourseVideoButtonContainer>
  );
};
