import { ReferenceIdEnum } from './ReferenceIdEnum';

export enum SidebarPagesMapEnum {
  '8d2d5099-555e-44db-aa7c-c0e99b368dd1' = ReferenceIdEnum.home,
  '8fdca110-31ea-4462-972c-f96b8cb9c982' = ReferenceIdEnum.companies,
  '77d8df8b-8f0a-4a5d-9c95-b8058c38f98e' = ReferenceIdEnum['user-groups'],
  '6fc389d5-1a32-4195-8825-718d49271f43' = ReferenceIdEnum.users,
  '9ba69d44-d130-4138-a34d-73c2ced2d7a6' = ReferenceIdEnum[
    'marketplaces-management'
  ],
  'b307b733-9459-40a3-974a-70301d28bbb0' = ReferenceIdEnum.orders,
  '50afedaf-3b75-4f39-a64f-3c9256160ede' = ReferenceIdEnum['orders-posts'],
  '202f12f6-6cd3-4b9a-9237-73d8bba4c5cf' = ReferenceIdEnum.cashiers,
  '27519618-b23c-4f85-b0be-d2be63b0f70b' = ReferenceIdEnum.customers,
  '88034c43-e851-4616-8c9e-91ce23c35f88' = ReferenceIdEnum.sellers,
  '4cd33490-926f-462b-b233-1b27790e5ea4' = ReferenceIdEnum['cashiers-reports'],
  'fa10fe80-e982-47b7-8198-6052edcd54d3' = ReferenceIdEnum['collects-control'],
  '6d2d98c9-8c71-4180-8311-48406675580f' = ReferenceIdEnum['abandoned-carts'],
  '91d2544a-02ed-4a68-9d3d-3a35d17daf3f' = ReferenceIdEnum['orders-reports'],
  '47fe78a3-1f23-4ce4-a7e7-72d58b6bfcc7' = ReferenceIdEnum.products,
  'ea935f56-fc6f-448e-8f9e-8e73ba58eca9' = ReferenceIdEnum.categories,
  '399d2e24-cd97-4db7-854e-4f1a9bb012e0' = ReferenceIdEnum.brands,
  'f1423f7e-1d0a-4bb9-b02d-4ac220948250' = ReferenceIdEnum[
    'product-environments'
  ],
  '8651f7a6-6bb7-4dec-bfaa-0a1adfc86017' = ReferenceIdEnum['taxation-rules'],
  'c3e09928-7126-46e8-80ac-fb27ccf0c588' = ReferenceIdEnum['products-reports'],
  'dd7baa68-7087-48cf-b61b-04ac3d8c919f' = ReferenceIdEnum.promotions,
  '5dde5f50-0aca-440f-bd6e-749865a8290b' = ReferenceIdEnum['stock-locals'],
  '950aba14-dc24-4ce5-a97b-a7947d257a22' = ReferenceIdEnum['stock-adjustment'],
  '0000cfa3-dfbb-4a70-9d6f-7f2adb339783' = ReferenceIdEnum['stock-monitoring'],
  '441b5620-b753-4bbc-8ebe-43a09b9d9228' = ReferenceIdEnum[
    'stock-transfer-group'
  ],
  '88f076c3-1b94-4c34-b0cd-b497c266908d' = ReferenceIdEnum['stock-reports'],
  '25270f99-c3fc-4d7a-84fe-109133f37061' = ReferenceIdEnum.integrations,
  'c9d0b2c1-e862-405f-a846-2f7542c12e43' = ReferenceIdEnum.events,
  '9ec2c76d-87c0-4075-a130-73ad93bd1f28' = ReferenceIdEnum[
    'file-transmissions'
  ],
  '637d19a4-4f0f-46f7-becc-4c66c8f1f58b' = ReferenceIdEnum.devices,
  '25f6aa09-5b91-4b8a-a62a-8f2589aa432a' = ReferenceIdEnum.dashboard,
  'ddd47464-d92b-4200-9cd4-6a35cd6187a2' = ReferenceIdEnum.loans,
  'cd154acf-6f75-470c-b64e-56b68663ab6c' = ReferenceIdEnum['last-transactions'],
  'b40e48ba-2305-4648-bad7-c5c32a4b131b' = ReferenceIdEnum.recharges,
  '1bbbddd6-f8a3-4bed-b20e-06d24fb40b5e' = ReferenceIdEnum['bill-payments'],
  'fdd4d9be-0f0d-4e96-b50a-7297527af71b' = ReferenceIdEnum[
    'products-batch-update'
  ],
  '68bb137b-61b1-4be2-b7ef-3c411f500095' = ReferenceIdEnum[
    'products-price-list'
  ],
  'bc70b9f3-bcb8-41f4-b881-a4c70a3accb6' = ReferenceIdEnum.catalogs,
}
