import { useEffect, useState } from 'react';

interface IGetElementVisibleByIdResponse {
  element: HTMLElement;
  isVisible: boolean;
}

export const useGetElementVisibleById = (
  idElement: string
): IGetElementVisibleByIdResponse => {
  const [isVisible, setIsVisible] = useState(false);
  const [element, setElement] = useState<HTMLElement>(null);

  useEffect(() => {
    const interval = setInterval(() => {
      if (typeof document !== 'undefined') {
        const $element = document.getElementById(idElement);

        if ($element) {
          setElement($element);
          setIsVisible(true);
        }

        clearInterval(interval);
      }
    }, 100);
  }, [idElement]);

  return { element, isVisible };
};
