import styled from 'styled-components';

export const CharacterImage = styled.img`
  position: absolute;
  bottom: 0;
  z-index: 200;
  width: auto;
  object-fit: contain;

  left: -1000px;

  transition: opacity 0.8s;
  opacity: 0;
`;
