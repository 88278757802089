import styled from 'styled-components';

interface ContainerProps {
  size: number;
  isRounded: boolean;
  textSize: number;
  hasImage: boolean;
  noImageBackgroundColor?: string;
  noImageColor?: string;
}

export const Container = styled.div<ContainerProps>`
  height: ${({ size }) => size}px;
  width: ${({ size }) => size}px;
  overflow: hidden;
  background: ${({ hasImage, noImageBackgroundColor }) =>
    hasImage ? 'transparent' : noImageBackgroundColor || '#ededed'};

  border-radius: ${({ isRounded, hasImage }) =>
    isRounded ? '50%' : !hasImage ? '4px' : 0};

  display: flex;
  align-items: center;
  justify-content: center;

  > span {
    font-weight: 700;
    color: ${({ theme, hasImage, noImageColor }) =>
      !hasImage ? noImageColor || theme.colors.darkGray : theme.colors.surface};
    font-size: ${({ textSize }) => textSize}px;
    line-height: ${({ size }) => size}px;
  }

  svg {
    margin: 0 !important;
    width: 18px !important;
    height: 18px !important;
    color: ${({ theme, hasImage, noImageColor }) =>
      !hasImage
        ? noImageColor || theme.colors.darkGray
        : theme.colors.surface} !important;
  }
`;
