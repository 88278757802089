import {
  forwardRef,
  ForwardRefRenderFunction,
  ReactNode,
  useEffect,
  useState,
} from 'react';

import ReactDOM from 'react-dom';

import { Container } from './styles';

interface BackdropProps {
  children: ReactNode;
}

const BaseBackdrop: ForwardRefRenderFunction<HTMLDivElement, BackdropProps> = (
  { children },
  ref
) => {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);

    return () => setIsMounted(false);
  }, []);

  if (!isMounted) return null;

  return ReactDOM.createPortal(
    <Container ref={ref}>{children}</Container>,
    document.getElementById('backdrop-root')
  );
};

export const Backdrop = forwardRef(BaseBackdrop);
