import { HTMLAttributes, useCallback, useEffect, useState } from 'react';

import { RiCloseLine } from 'react-icons/ri';

import { ColorScheme } from 'styled-components';
import { Tooltip } from '@chakra-ui/react';

import { FCWithChildren } from '~/shared/types/FCWithChildren';

import { AlertType, CloseIcon, CloseOnXContainer, Container } from './styles';

interface ILink {
  readonly text: string;
  readonly href: string;
}
interface AlertProps extends HTMLAttributes<HTMLDivElement> {
  type?: AlertType;
  text?: string;
  link?: ILink;
  isFullWidth?: boolean;
  isClosable?: boolean;
  onClose?: () => void;
  backgroundOpacity?: number;
  forceTextColor?: ColorScheme;
  readonly closeOnX?: () => void;
  isVisible?: boolean;
}

export const Alert: FCWithChildren<AlertProps> = ({
  type = 'warning',
  text,
  link,
  children,
  isFullWidth = true,
  isClosable = false,
  backgroundOpacity = 16,
  forceTextColor,
  closeOnX,
  onClose = () => null,
  isVisible = true,
  ...props
}) => {
  const [visible, setVisible] = useState<boolean>(true);

  useEffect(() => {
    setVisible(isVisible);
  }, [isVisible]);

  const handleClose = useCallback(() => {
    setVisible(false);
    onClose();
  }, [onClose]);

  return visible ? (
    <Container
      type={type}
      className="alert"
      isFullWidth={isFullWidth}
      backgroundOpacity={backgroundOpacity}
      forceTextColor={forceTextColor}
      closeOnX={!!closeOnX}
      {...props}
    >
      <div id="text">
        {!!text && <p>{text}</p>}
        {!!link && <a href={link.href}>{link.text}</a>}
      </div>
      {isClosable && <CloseIcon onClick={handleClose} />}
      {Boolean(closeOnX) && (
        <Tooltip label="Fechar essa caixa" hasArrow>
          <CloseOnXContainer onClick={closeOnX}>
            <RiCloseLine style={{ width: '15px', height: '15px' }} />
          </CloseOnXContainer>
        </Tooltip>
      )}
      {children}
    </Container>
  ) : (
    <></>
  );
};
