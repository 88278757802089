import styled from 'styled-components';

export const Container = styled.section`
  width: 100%;

  & + section {
    hr {
      display: block;
    }
  }

  hr {
    display: none;
    height: 2px;
    margin: ${({ theme }) => `${theme.spacing.sm} ${theme.spacing.md}`};
    border: 0;
    background: ${({ theme }) => theme.colors.background};
  }

  ul {
    width: 100%;

    display: flex;
    flex-direction: column;
  }
`;

interface ToggleTabsButtonProps {
  tabsAreVisible: boolean;
}

export const ToggleTabsButton = styled.button<ToggleTabsButtonProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 ${({ theme }) => theme.spacing.md};
  width: 100%;

  transition: all 0.4s;

  &:hover {
    opacity: 0.75;
  }

  h4 {
    font-size: 12px;
    color: ${({ theme }) => theme.colors.onSurface};
    font-weight: 800;
    text-transform: uppercase;
    letter-spacing: 1px;
  }

  svg {
    color: ${({ theme }) => theme.colors.onSurface};
    width: 14px;
    height: 14px;

    transition: all 0.5s;

    transform: ${({ tabsAreVisible }) =>
      tabsAreVisible ? 'rotate(180deg)' : 'rotate(0)'};
  }

  & + div {
    > ul {
      padding-top: ${({ theme }) => theme.spacing.xs};
    }
  }
`;
