import {
  createContext,
  useContext,
  useCallback,
  useState,
  useEffect,
} from 'react';

import { useToast } from '@chakra-ui/react';

import { FCWithChildren } from '~/shared/types/FCWithChildren';
import { api } from '~/shared/services/api';

import { IVideoProgress } from '../interfaces/IVideoProgress';
import { IVideo } from '../interfaces/IVideo';
import { ICourseData } from '../interfaces/ICourseData';

interface ICoursesContextData {
  readonly videos: Array<IVideo>;
  readonly progress: Array<IVideoProgress>;
  readonly loading: boolean;
  readonly hasNotShowcase: boolean;
  readonly updateProgress: () => Promise<void>;
  readonly applyFilter: (alias: string) => Promise<void>;
}

const CoursesContext = createContext({} as ICoursesContextData);

const CoursesProvider: FCWithChildren = ({ children }) => {
  const [hasNotShowcase, setHasNotShowcase] = useState<boolean>(false);
  const [coursesData, setCoursesData] = useState<ICourseData>(
    {} as ICourseData
  );
  const [loading, setLoading] = useState<boolean>(false);
  const toast = useToast({
    isClosable: true,
    status: 'error',
    position: 'top-right',
  });

  const fetch = useCallback(
    async (alias?: string) => {
      setLoading(true);
      await api
        .get<ICourseData>('/courses', { params: { alias } })
        .then(({ data: response }) => {
          setCoursesData(response);
        })
        .catch((err) => {
          let withoutShowcase = false;
          const response = err?.response?.data;
          if (response?.tag === 'resale_without_showcase') {
            withoutShowcase = true;
            setHasNotShowcase(true);
          }

          if (!withoutShowcase) {
            hasNotShowcase && setHasNotShowcase(false);
            toast({
              title: 'Ops',
              description:
                'Houve um erro ao carregar os cursos, tente novamente mais tarde',
            });
          }
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [hasNotShowcase]
  );

  const applyFilter = useCallback(async (alias: string) => {
    await fetch(alias);
  }, []);

  const updateProgress = useCallback(async () => {
    const { data: newProgress } = await api.get(`/courses/progress`);
    setCoursesData((prevState) => ({
      ...prevState,
      progress: newProgress,
    }));
  }, []);

  useEffect(() => {
    (async () => {
      await fetch();
    })();
  }, []);

  return (
    <CoursesContext.Provider
      value={{
        ...coursesData,
        loading,
        hasNotShowcase,
        updateProgress,
        applyFilter,
      }}
    >
      {children}
    </CoursesContext.Provider>
  );
};

const useCourses = (): ICoursesContextData => {
  return useContext(CoursesContext);
};

export { CoursesProvider, useCourses };
