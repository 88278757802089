import { ChangeEventHandler, FC, useCallback } from 'react';

import { RiCloseLine } from 'react-icons/ri';
import { FaSearch } from 'react-icons/fa';

import { ScaleFade } from '@chakra-ui/react';

import { useDebounce } from '~/shared/hooks/debounce';
import { Input } from '~/shared/components/Form/Input';

import { Container, CoursesHeader, CoursesTitle, PopoverArrow } from './styles';
import { CoursesList } from '../CoursesList';
import { useCourses } from '../../hooks/courses';

interface CoursesPopoverProps {
  readonly isOpen: boolean;
  readonly close: () => void;
}
export const CoursesPopover: FC<CoursesPopoverProps> = ({ isOpen, close }) => {
  const { applyFilter } = useCourses();
  const debounce = useDebounce(applyFilter);

  const handleChangeAlias: ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      debounce(event.target.value);
    },
    [debounce]
  );

  return (
    <ScaleFade in={isOpen} initialScale={0.9} unmountOnExit>
      <Container id="popover-courses">
        <PopoverArrow />
        <CoursesHeader>
          <Input
            name="search"
            placeholder="Pesquisar"
            endIcon={FaSearch}
            onChange={handleChangeAlias}
          />
          <RiCloseLine
            style={{ cursor: 'pointer', width: '30px', height: '30px' }}
            onClick={close}
          />
        </CoursesHeader>
        <CoursesTitle>
          <div>
            <b>
              Assista aos vídeos tutoriais para se tornar um expert na
              plataforma.
            </b>
          </div>
        </CoursesTitle>

        <div>
          <CoursesList />
        </div>
      </Container>
    </ScaleFade>
  );
};
