import styled, { css, ColorScheme } from 'styled-components';

export type InputVariant = 'default' | 'outlined';

export type InputSize = 'sm' | 'md';

interface InputContainerProps {
  type: string;
  hasError: boolean;
  hasStartIcon: boolean;
  hasEndIcon: boolean;
  isFocused: boolean;
  variant: InputVariant;
  size: InputSize;
  color: ColorScheme;
}

interface IconContainerProps {
  isFocused: boolean;
  hasError: boolean;
  size: InputSize;
  color: ColorScheme;
  variant: InputVariant;
}

interface LoadingContainerProps {
  variant: InputVariant;
  isDisabled?: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  > label {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
`;

export const InputContainer = styled.div<InputContainerProps>`
  display: flex;
  align-items: center;

  width: 100%;
  overflow: hidden;
  min-height: ${({ size }) => (size === 'sm' ? '32px' : '48px')};

  border-bottom: ${({ hasError, theme, size, color }) =>
    `${size === 'sm' ? '1px' : '2px'} solid ${
      hasError ? theme.colors.error : theme.colors[color]
    }`};

  background: ${({ theme, variant }) =>
    variant === 'outlined' ? theme.colors.surface : 'transparent'};

  > input {
    width: 100%;
    flex: 1;
    height: ${({ size }) => (size === 'sm' ? 25 : 46)}px;
    font-size: ${({ size }) => (size === 'sm' ? '13px' : '15px')};
    font-weight: 400;

    font-weight: 500;
    color: ${({ theme }) => theme.colors.onSurface};

    ${({ hasStartIcon }) =>
      hasStartIcon &&
      css`
        padding-left: 0 !important;
      `};

    ${({ hasEndIcon }) =>
      hasEndIcon &&
      css`
        padding-right: 0 !important;
      `};

    &::placeholder {
      color: ${({ theme }) => theme.colors.onSurface};
      font-weight: 400;
      opacity: 0.4;
      font-weight: 500;
    }
  }

  > button {
    display: flex;
    align-items: center;
    justify-content: center;

    transition: all 0.4s;

    padding-right: ${({ variant, theme }) =>
      variant === 'outlined' ? theme.spacing.xs : 0};
    padding-left: ${({ theme }) => theme.spacing.xs};

    &:hover {
      opacity: 0.6;
    }

    svg {
      height: 20px;
      width: 20px;
      color: ${({ isFocused, theme, hasError, color }) =>
        hasError
          ? theme.colors.error
          : isFocused
          ? theme.colors[color]
          : theme.colors.onSurface};

      transition: all 0.3s;
    }
  }

  @media (max-width: 550px) {
    > button {
      svg {
        height: 18px;
        width: 18px;
      }
    }
  }

  ${({ theme, variant, hasError, isFocused, color, size }) =>
    variant === 'outlined' &&
    css`
      border: 1px solid
        ${hasError
          ? theme.colors.error
          : isFocused
          ? theme.colors[color]
          : '#ddd'};
      border-radius: ${theme.borderRadius.md};
      height: ${size === 'sm' ? '32px' : '46px'};

      > input {
        padding: ${theme.spacing.xs};

        &[type='password'] {
          padding-right: 0;
        }

        > button {
          svg {
            height: 18px;
            width: 18px;
          }
        }
      }
    `}
`;

export const StartIconContainer = styled.div<IconContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  padding-left: ${({ variant, theme }) =>
    variant === 'outlined' ? theme.spacing.xs : 0};
  padding-right: ${({ theme }) => theme.spacing.xs};

  svg {
    height: ${({ size }) => (size === 'sm' ? '13px' : '20px')};
    width: ${({ size }) => (size === 'sm' ? '13px' : '20px')};
    color: ${({ isFocused, theme, hasError, color }) =>
      hasError
        ? theme.colors.error
        : isFocused
        ? theme.colors[color]
        : theme.colors.onSurface};

    transition: all 0.3s;
  }
`;

export const LoadingContainer = styled.div<LoadingContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  padding-right: ${({ variant, theme }) =>
    variant === 'outlined' ? theme.spacing.xs : 0};
  padding-left: ${({ theme }) => theme.spacing.xs};
`;

export const EndContent = styled.div<LoadingContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 100%;

  padding-right: ${({ variant, theme }) =>
    variant === 'outlined' ? theme.spacing.xs : 0};

  ${({ isDisabled, theme }) =>
    isDisabled &&
    css`
      background: ${theme.colors.background};
    `}
`;

export const EndIconContainer = styled.div<IconContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  padding-right: ${({ variant, theme }) =>
    variant === 'outlined' ? theme.spacing.xs : 0};
  padding-left: ${({ theme }) => theme.spacing.xs};

  svg {
    height: ${({ size }) => (size === 'sm' ? '13px' : '20px')};
    width: ${({ size }) => (size === 'sm' ? '13px' : '20px')};
    color: ${({ isFocused, theme, hasError, color }) =>
      hasError
        ? theme.colors.error
        : isFocused
        ? theme.colors[color]
        : theme.colors.onSurface};

    transition: all 0.3s;
  }
`;

export const ObservationContainer = styled.div`
  & > span {
    font-style: italic;
    font-size: 12px;
    color: '#4D4D4D';
  }
`;

export const LabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;
