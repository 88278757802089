import styled from 'styled-components';

import { IOfferStepButtonColors } from '~/modules/offers/interfaces/IOfferStepButtonColors';

interface ContainerProps {
  colors: IOfferStepButtonColors;
}

export const Container = styled.button<ContainerProps>`
  min-width: 100px;
  border-radius: ${({ theme }) => theme.borderRadius.sm};
  padding: ${({ theme }) => `0 ${theme.spacing.sm}`};
  height: 36px;
  font-size: 13px;
  font-weight: 700;
  background: ${({ colors }) => colors.background};
  color: ${({ colors }) => colors.text};

  transition: all 0.5s;

  & + button {
    margin-left: 10px;
  }

  &:hover:not(:disabled) {
    filter: brightness(0.9);
  }
`;
