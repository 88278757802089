import { useState } from 'react';

import { IoChevronDown } from 'react-icons/io5';

import { Collapse } from '@chakra-ui/react';

import { FCWithChildren } from '~/shared/types/FCWithChildren';

import { Container, ToggleTabsButton } from './styles';

interface NavSectionProps {
  title?: string;
  tag: string;
  tabsStartsVisible?: boolean;
}

export const NavSection: FCWithChildren<NavSectionProps> = ({
  tabsStartsVisible,
  title,
  children,
}) => {
  const [tabsAreVisible, setTabsAreVisible] = useState(tabsStartsVisible);

  function toggleTabsVisibility(): void {
    setTabsAreVisible((prevState) => !prevState);
  }

  return (
    <Container>
      <hr />

      {!!title && (
        <ToggleTabsButton
          type="button"
          onClick={toggleTabsVisibility}
          tabsAreVisible={tabsAreVisible}
        >
          {!!title && <h4>{title}</h4>}

          <IoChevronDown />
        </ToggleTabsButton>
      )}

      <Collapse in={!title || tabsAreVisible} unmountOnExit animateOpacity>
        <ul>{children}</ul>
      </Collapse>
    </Container>
  );
};
