import { setupApi } from './api';
import { ISettings } from '../interfaces/ISettings';

// eslint-disable-next-line prettier/prettier
export async function loadWhiteLabelSettings(domain: string): Promise<ISettings> {
  try {
    const { data } = await setupApi().get<ISettings>('/resales/domain', {
      params: {
        domain,
      },
    });

    return data;
  } catch (error) {
    return null;
  }
}
