import { FC, useEffect, useRef } from 'react';

import { IoClose } from 'react-icons/io5';

import { Collapse, Fade, Tooltip } from '@chakra-ui/react';

import { useAuth } from '~/modules/auth/hooks/auth';

import { useSettings } from '~/shared/hooks/settings';

import { Container, Arrow, Content, Footer, Header } from './styles';
import { useOnboarding } from '../../hooks/onboarding';

interface WelcomePopoverProps {
  isVisible: boolean;
}

export const WelcomePopover: FC<WelcomePopoverProps> = ({ isVisible }) => {
  const { step, next, close } = useOnboarding();
  const { settings } = useSettings();
  const { name } = settings || {};
  const { user } = useAuth();

  const containerRef = useRef<HTMLDivElement>(null);
  const hasUpdated = useRef<boolean>(false);

  useEffect(() => {
    hasUpdated.current = false;
  }, [step]);

  const firstName = user?.name.split(' ')[0];

  return (
    <Fade in={!!isVisible} unmountOnExit>
      <Container ref={containerRef} id="welcome-popover">
        <Arrow />

        <Content>
          <Header>
            <div>
              <span>Olá {firstName}, seja bem vindo!</span>
            </div>

            <Tooltip label="Fechar" hasArrow>
              <button type="button" onClick={() => close()}>
                <IoClose />
              </button>
            </Tooltip>
          </Header>

          <div>
            <Collapse in>
              <p>
                Notei que é o seu primeiro acesso ao
                <br />
                {name}, deseja saber como funciona?
              </p>
            </Collapse>
          </div>

          <Footer>
            <button type="button" onClick={next}>
              sim
            </button>

            <button type="button" onClick={() => close(true)}>
              depois
            </button>
          </Footer>
        </Content>
      </Container>
    </Fade>
  );
};
