import {
  createContext,
  useContext,
  useState,
  useCallback,
  useEffect,
} from 'react';

import { useRouter } from 'next/router';

import { parseCookies, setCookie } from 'nookies';

import { FCWithChildren } from '~/shared/types/FCWithChildren';
import { api } from '~/shared/services/api';
import { useFacebookPixel } from '~/shared/hooks/facebookPixel';
import { cookies } from '~/shared/constants/cookies';

import { ISubscription } from '../interfaces/ISubscription';
import { IPlan } from '../interfaces/IPlan';
import { ICard } from '../interfaces/ICard';
import { SubscriptionRecurrencyTypeEnum } from '../enums/PlanRecurrencyTypeEnum';
import { PaymentWayEnum } from '../enums/PaymentWayEnum';
import { SubscriptionRequestDTO } from '../dtos/SubscriptionRequestDTO';

interface ICheckoutContextData {
  selectedPlan: IPlan | undefined;
  selectPlan(plan: IPlan): void;
  selectedPaymentWay: PaymentWayEnum;
  toggleSelectedPaymentWay(paymentWay: PaymentWayEnum): void;
  createSubscription(card?: ICard): Promise<ISubscription>;
  currentSubscription: ISubscription | undefined;
}

const CheckoutContext = createContext({} as ICheckoutContextData);

const CheckoutProvider: FCWithChildren = ({ children }) => {
  const { query } = useRouter();
  const { track } = useFacebookPixel();

  const [selectedPlan, setSelectedPlan] = useState<IPlan | undefined>();
  const [selectedPaymentWay, setSelectedPaymentWay] = useState<PaymentWayEnum>(
    PaymentWayEnum.credit_card
  );

  const [currentSubscription, setCurrentSubscription] = useState<
    ISubscription | undefined
  >();

  useEffect(() => {
    const {
      [cookies.SELECTED_PLAN]: storagedPlan,
      // [cookies.CURRENT_SUSBSCRIPTION_PAYMENT_WAY]: currentPaymentWay
    } = parseCookies();

    if (storagedPlan) {
      setSelectedPlan(JSON.parse(storagedPlan));
    }
  }, []);

  const selectPlan = useCallback(
    (plan: IPlan) => {
      setSelectedPlan(plan);

      setCookie(
        undefined,
        cookies.SELECTED_PLAN,
        JSON.stringify({
          _id: plan._id,
          name: plan.name,
          minimumAmount: plan.minimumAmount,
        }),
        {
          path: '/',
        }
      );

      track('InitiateCheckout', {
        planName: plan.name,
      });

      if (typeof window.gtag !== 'undefined') {
        window.gtag('event', 'conversion', {
          send_to: 'AW-591117712/3O_GCNP5oPgCEJD77pkC',
          planName: plan.name,
        });
      }
    },
    [track]
  );

  const toggleSelectedPaymentWay = useCallback((paymentWay: PaymentWayEnum) => {
    setSelectedPaymentWay(paymentWay);
  }, []);

  const createSubscription = useCallback(
    async (card?: ICard) => {
      const newCard: ICard = {
        cvv: '',
        expirationDate: '',
        holderName: '',
        number: '',
        ...card,
      };

      const data: SubscriptionRequestDTO = {
        card: newCard,
        paymentType: selectedPaymentWay,
        plan: selectedPlan._id,
        company: query.companyId as string,
        recurrencyType: SubscriptionRecurrencyTypeEnum.monthly,
      };

      const response = await api.post('/subscriptions', data);

      if (response.data) {
        track('Purchase', {
          paymentType: selectedPaymentWay,
          planName: selectedPlan.name,
        });

        if (typeof window.gtag !== 'undefined') {
          let sendTo = 'AW-591117712/lV-mCNiczPgCEJD77pkC';

          if (origin === 'pdv-facil-site') {
            sendTo = 'AW-10901029545/YP4ECL7Sor4DEKmFgs4o';
          }

          if (origin === 'menu-facil-site') {
            sendTo = 'AW-10901702762/j9TXCIG9ob4DEOqQq84o';
          }

          if (origin === 'totem-site') {
            sendTo = 'AW-10901680226/40h1CNfJor4DEOLgqc4o';
          }

          window.gtag('event', 'conversion', {
            send_to: sendTo,
            paymentType: selectedPaymentWay,
            planName: selectedPlan.name,
          });
        }

        const subscription: ISubscription = {
          _id: response.data._id,
          paymentType: selectedPaymentWay,
          currentBoletoUrl: response.data.currentBoletoUrl,
        };

        setCurrentSubscription(subscription);

        return subscription;
      }
    },
    [query.companyId, selectedPaymentWay, selectedPlan, track]
  );

  return (
    <CheckoutContext.Provider
      value={{
        selectedPlan,
        currentSubscription,
        selectPlan,
        selectedPaymentWay,
        toggleSelectedPaymentWay,
        createSubscription,
      }}
    >
      {children}
    </CheckoutContext.Provider>
  );
};

const useCheckout = (): ICheckoutContextData => {
  return useContext(CheckoutContext);
};

export { CheckoutProvider, useCheckout };
