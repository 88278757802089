import styled, { ColorScheme } from 'styled-components';
import { Spinner as ChakraSpinner } from '@chakra-ui/react';

interface StyledSpinnerProps {
  color: ColorScheme;
}

export const StyledSpinner = styled(ChakraSpinner)<StyledSpinnerProps>`
  border-color: ${({ theme, color }) => theme.colors[color]} !important;
  border-left-color: transparent !important;
  border-bottom-color: transparent !important;
`;
