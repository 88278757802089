import { FC, useCallback, useEffect, useRef, useState } from 'react';

import { Fade } from '@chakra-ui/react';

import { useSideBarDrawer } from '~/shared/hooks/sideBarDrawer';
import { useGetElementVisibleById } from '~/shared/hooks/getElementVisibleById';

import { Wrapper } from './styles';

interface CoursesWrapperProps {
  readonly visible: boolean;
}
export const CoursesWrapper: FC<CoursesWrapperProps> = ({ visible }) => {
  const { isOpen } = useSideBarDrawer();
  const { element: courses } = useGetElementVisibleById('courses-item');
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [glow, setGlow] = useState<boolean>(false);

  const calculateOffset = useCallback(() => {
    if (courses) {
      const selectedElement = wrapperRef.current;
      const bounding = courses.getBoundingClientRect();

      if (visible) {
        selectedElement.style.transform = `translateX(${bounding.left}px) translateY(${bounding.top}px) scale(1.5)`;
        selectedElement.style.borderRadius = `50%`;
      } else {
        selectedElement.style.borderRadius = `0`;
        selectedElement.style.transform = `translateX(${bounding.left}px) translateY(${bounding.top}px)`;
      }
      selectedElement.style.width = `${courses.offsetWidth}px`;
      selectedElement.style.height = `${courses.offsetHeight}px`;
    }
  }, [courses, visible]);

  useEffect(() => {
    calculateOffset();
  }, [calculateOffset, courses]);

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        calculateOffset();
      }, 500);
    }
  }, [calculateOffset, isOpen]);

  useEffect(() => {
    const sideBar = document.getElementById('sidebar');
    if (sideBar) {
      sideBar.addEventListener('scroll', calculateOffset);
    }

    return () => {
      if (sideBar) {
        sideBar.removeEventListener('scroll', calculateOffset);
      }
    };
  }, [calculateOffset]);

  const applyGlow = (): void => {
    setGlow((prevState) => !prevState);
  };

  useEffect(() => {
    const interval = setInterval(applyGlow, 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <Fade in={visible}>
      <Wrapper ref={wrapperRef} glow={glow} />
    </Fade>
  );
};
