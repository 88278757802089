import {
  ForwardRefRenderFunction,
  useState,
  forwardRef,
  CSSProperties,
  TextareaHTMLAttributes,
  ChangeEvent,
  ReactNode,
} from 'react';

import { Container, ObservationContainer, TextareaContainer } from './styles';
import { LabelText } from '../LabelText';
import { ErrorMessage } from '../ErrorMessage';

export interface TextareaProps
  extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  name: string;
  error?: string;
  label?: string;
  containerStyle?: CSSProperties;
  observation?: string;
  hint?: ReactNode;
  onChange?: (event: ChangeEvent<HTMLTextAreaElement>) => void;
}

const BaseTextarea: ForwardRefRenderFunction<
  HTMLTextAreaElement,
  TextareaProps
> = (
  {
    name,
    error,
    label,
    hint,
    containerStyle,
    observation,
    onChange = () => null,
    ...props
  },
  ref
) => {
  const [isFocused, setIsFocused] = useState(false);

  return (
    <Container id="textarea" style={containerStyle}>
      <label htmlFor={name}>
        {!!label && (
          <LabelText tooltip={hint} noWrap>
            {label}
          </LabelText>
        )}

        <TextareaContainer hasError={!!error} isFocused={isFocused}>
          <textarea
            name={name}
            id={name}
            ref={ref}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            onChange={onChange}
            {...props}
          />
        </TextareaContainer>
        {observation && (
          <ObservationContainer>
            <span>{observation}</span>
          </ObservationContainer>
        )}
        <ErrorMessage>{error}</ErrorMessage>
      </label>
    </Container>
  );
};

export const Textarea = forwardRef(BaseTextarea);
