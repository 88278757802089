import styled from 'styled-components';

import { IOfferStepSettings } from '~/modules/offers/interfaces/IOfferStepSettings';

interface ComponentWithSettingsProps {
  settings: Omit<IOfferStepSettings, 'status'>;
}

interface BodyProps extends ComponentWithSettingsProps {
  hasPicture: boolean;
}

interface VideoContainerProps extends ComponentWithSettingsProps {
  textColor: string;
  backgroundColor: string;
}

export const Container = styled.form<ComponentWithSettingsProps>`
  background-color: ${({ settings }) => settings?.colors?.primary};
  background-image: ${({ settings }) => `url(${settings.backgroundImage})`};
  background-position: center;
  background-size: cover;
  flex: 1;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  > footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;

    padding: ${({ theme }) => theme.spacing['2xs']};
    margin-top: ${({ theme }) => theme.spacing['2xs']};
    border-top: 1px solid ${({ settings }) => settings?.colors?.secondary};
  }
`;

export const Body = styled.div<BodyProps>`
  display: flex;

  padding: ${({ theme }) => theme.spacing['2xs']};
  padding-bottom: 0;

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;

    gap: ${({ theme }) => `calc(${theme.spacing['2xs']} / 2)`};

    > h2 {
      font-size: 18px;
      font-weight: 800;
      color: ${({ settings }) => settings?.colors?.text};
      text-align: center;
    }

    > p {
      color: ${({ settings }) => settings?.colors?.text};
      font-size: 14px;
      text-align: center;

      line-height: 22px;
    }
  }

  > aside {
    display: flex;
    min-width: 40%;
    min-height: 240px;
    margin-left: ${({ theme }) => theme.spacing['2xs']};
  }
`;

export const LogoContainer = styled.div`
  height: 70px;
  width: auto;
  display: flex;
  margin-bottom: ${({ theme }) => `calc(${theme.spacing['2xs']} / 2)`};

  img {
    object-fit: contain;
  }
`;

export const VideoContainer = styled.div<VideoContainerProps>`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  padding-top: ${({ theme }) => theme.spacing['2xs']};
  padding-bottom: 7px;
  padding-right: 10px;
  margin-top: ${({ theme }) => theme.spacing['2xs']};
  border-top: 1px solid ${({ settings }) => settings?.colors?.secondary};

  position: relative;

  > div {
    display: flex;
    align-items: center;

    background: ${({ backgroundColor }) => backgroundColor};
    padding: ${({ theme }) => theme.spacing.xs};
    width: 290px;

    > button {
      display: flex;
      align-items: center;
      justify-content: center;

      padding: ${({ theme }) => theme.spacing.sm};

      border-radius: 50%;
      border: 1px solid ${({ textColor }) => textColor};

      transition: all 0.3s;

      &:hover {
        opacity: 0.6;
      }

      svg {
        margin-left: 2px;
        width: 16px;
        height: 16px;

        path {
          stroke: ${({ textColor }) => textColor};
        }
      }
    }

    > p {
      color: ${({ textColor }) => textColor};
      font-size: 13px;
      line-height: 20px;
      margin-left: ${({ theme }) => theme.spacing.xs};
    }
  }

  .video-details-bordeless {
    background: none;
    border: 1px solid ${({ settings }) => settings?.colors?.secondary};

    button,
    p {
      pointer-events: none;
      opacity: 0;
    }
  }

  .video-details-shadow {
    position: absolute;
    right: 0;
    bottom: 0;
  }
`;
