import { FC, useEffect, useState, useRef, ReactNode } from 'react';

import { IoClose } from 'react-icons/io5';
import ReactDOM from 'react-dom';

import { useTheme } from 'styled-components';
import { Tooltip } from '@chakra-ui/react';

import { useAuth } from '~/modules/auth/hooks/auth';

import { useIsMounted } from '~/shared/hooks/isMounted';
import { useConfirmationModal } from '~/shared/hooks/confirmationModal';
import { useClickOutside } from '~/shared/hooks/clickOutside';

import { Container, Content, Header, ButtonsContainer } from './styles';
import { Spinner } from '../Spinner';

interface ConfirmationModalProps {
  content: ReactNode;
  title?: string;
  subtitle?: string;
  titleColor?: string;
}

export const ConfirmationModal: FC<ConfirmationModalProps> = ({
  content,
  title,
  subtitle,
  titleColor,
}) => {
  const theme = useTheme();
  const { user } = useAuth();
  const { close, onConfirm, onCancel, options, size } = useConfirmationModal();
  const [loading, setLoading] = useState<'cancel' | 'confirm'>();

  const backdropRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const isMounted = useIsMounted();

  function handleClose(): void {
    const modalContent = containerRef.current;
    const modalBackdrop = backdropRef.current;

    if (modalContent && modalBackdrop) {
      modalContent.style.transform = 'translateY(1000px)';
      modalBackdrop.style.opacity = '0';
    }

    setTimeout(() => {
      close();
    }, 300);
  }

  const contentRef = useClickOutside<HTMLDivElement>(handleClose);

  useEffect(() => {
    const closeWithEsc = (event: any): void => {
      if (event.keyCode === 27) {
        handleClose();
      }
    };

    document.addEventListener('keydown', closeWithEsc, false);

    return () => {
      document.removeEventListener('keydown', closeWithEsc, false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function handleConfirm(): Promise<void> {
    try {
      setLoading('confirm');
      await onConfirm();
      handleClose();
    } catch (error) {
      return;
    } finally {
      setLoading(null);
    }
  }

  async function handleCancel(): Promise<void> {
    try {
      setLoading('cancel');
      await onCancel?.();
      handleClose();
    } catch (error) {
      return;
    } finally {
      setLoading(null);
    }
  }

  if (!isMounted) return null;

  return ReactDOM.createPortal(
    <Container ref={backdropRef}>
      <Content
        ref={containerRef}
        style={{ maxWidth: size }}
        titleColor={titleColor}
      >
        <div>
          <div ref={contentRef}>
            <Header>
              <h1
                {...(titleColor && {
                  style: {
                    color: theme.colors[titleColor],
                    fontWeight: 'bold',
                  },
                })}
              >
                {title || user?.name.split(' ')[0]},
              </h1>

              <Tooltip label="Fechar" hasArrow>
                <button type="button" onClick={handleClose}>
                  <IoClose />
                </button>
              </Tooltip>
            </Header>

            <p>{content}</p>

            {subtitle && (
              <p
                {...(titleColor && {
                  style: {
                    color: theme.colors[titleColor],
                    fontWeight: 'bold',
                  },
                })}
              >
                {subtitle}
              </p>
            )}

            <ButtonsContainer loadingConfirm={loading === 'confirm'}>
              <li>
                {loading === 'cancel' ? (
                  <Spinner size="md" color="primary" />
                ) : (
                  <button type="button" onClick={handleCancel}>
                    {options.cancel}
                  </button>
                )}
              </li>

              <li>
                {loading === 'confirm' ? (
                  <Spinner size="md" color="primary" />
                ) : (
                  <button type="button" onClick={handleConfirm} data-canclose>
                    {options.confirm}
                  </button>
                )}
              </li>
            </ButtonsContainer>
          </div>
        </div>
      </Content>
    </Container>,
    document.getElementById('modal-root')
  );
};
