export enum PaymentWayEnum {
  'credit_card' = 'credit_card',
  'boleto' = 'boleto',
  'pix' = 'pix',
}

export enum PaymentWayEnumFormated {
  'credit_card' = 'Cartão de Crédito',
  'boleto' = 'Boleto',
  'pix' = 'Pix',
}
