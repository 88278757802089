import {
  createContext,
  useContext,
  useRef,
  useState,
  useCallback,
  useEffect,
  useMemo,
} from 'react';

import { useAuth } from '~/modules/auth/hooks/auth';

import { FCWithChildren } from '~/shared/types/FCWithChildren';
import { api } from '~/shared/services/api';
import { useEventListener } from '~/shared/hooks/eventListener';
import { useConfirmationModal } from '~/shared/hooks/confirmationModal';
import { SidebarPagesMapEnum } from '~/shared/enums/SidebarPagesMapEnum';
import { ReferenceIdEnum } from '~/shared/enums/ReferenceIdEnum';
import { SidebarSelectedStep } from '~/shared/components/SidebarSelectedStep';
import { tabProps } from '~/shared/components/SideBar/SideBarNav';
import { Overflow } from '~/shared/components/Backdrop/styles';

import { WelcomePopover } from '../components/WelcomePopover';
import { WelcomeCharacter } from '../components/WelcomeCharacter';
import { ToolbarPopover } from '../components/ToolbarPopover';
import { ToolbarCharacter } from '../components/ToolbarCharacter';
import { SideBarPopover } from '../components/SideBarPopover';
import { SideBarCharacter } from '../components/SideBarCharacter';
import { Backdrop } from '../../../shared/components/Backdrop';

interface IOnboardingContextData {
  close(force?: boolean): void;
  next(): void;
  step: number;
  maxStep: number;
  content: string;
  isVisible: boolean;
}

const OnboardingContext = createContext({} as IOnboardingContextData);

const getContent: any = {
  [ReferenceIdEnum['my-account-item']]:
    'Veja quem está conectado na plataforma e tenha acesso aos dados da sua assinatura.',
  [ReferenceIdEnum['favorite-apps']]:
    'Aqui é o atalho para suas integrações favoritas.',
  [ReferenceIdEnum['help-item']]:
    'Ajude nossa plataforma evoluir cada vez mais informando uma sugestão, dúvida ou problema.',
  [ReferenceIdEnum['notifications-item']]:
    'Fique sempre atento as notificações, pois é aqui que você será notificado sobre uma nova venda.',
  [ReferenceIdEnum['courses-item']]:
    'Aprenda a usar e aproveitar 100% da plataforma e conquiste o certificado.',
  [ReferenceIdEnum.home]:
    'Fique por dentro das novidades e ofertas que a plataforma oferece.',
  [ReferenceIdEnum.companies]: 'Aqui está o cadastro da sua empresa.',
  [ReferenceIdEnum.cashiers]:
    'Organize o dia da sua empresa abrindo e fechando o caixa de forma fácil.',
  [ReferenceIdEnum.events]:
    'Crie e gerencie eventos de maneira simples e objetiva.',
  [ReferenceIdEnum.dashboard]:
    'Monitore o faturamento do seu negócio por aqui.',
  [ReferenceIdEnum.orders]:
    'Acompanhe em tempo real os pedidos que chegam pra você!',
  [ReferenceIdEnum['marketplaces-management']]:
    'Conecte aos marketplaces os seus produtos.',
  [ReferenceIdEnum['customers']]: 'Pesquise ou cadastre um novo cliente.',
  [ReferenceIdEnum['sellers']]: 'Pesquise ou cadastre um novo vendedor',
  [ReferenceIdEnum['orders-posts']]:
    'Despache os pedidos que estão prontos usando a tela de postagens.',
  [ReferenceIdEnum['abandoned-carts']]:
    'Recupere uma venda perdida acompanhando o carrinho abandonado.',
  [ReferenceIdEnum.products]: 'Cadastre aqui os seus produtos.',
  [ReferenceIdEnum.categories]:
    'Organize o cadastro dos seus produtos criando categorias.',
  [ReferenceIdEnum.brands]:
    'Cadastre as marcas dos produtos, isso ajuda o cliente a pesquisar na hora da compra.',
  [ReferenceIdEnum['product-environments']]:
    'Cadastre os ambientes que farão parte do seu processo de preparação dos produtos, exemplos: Bar, Cozinha, copa, etc.',
  [ReferenceIdEnum['taxation-rules']]:
    'Cadastre aqui as informações fiscais para emissão do cupom.',
  [ReferenceIdEnum.promotions]: 'Crie promoções e aumente suas vendas.',
  [ReferenceIdEnum['stock-locals']]:
    'Para controlar o estoque tudo começa por aqui, cadastre o nome do seu estoque de forma fácil.',
  [ReferenceIdEnum['stock-adjustment']]:
    'Insira ou retire manualmente um produto do seu estoque de forma objetiva.',
  [ReferenceIdEnum['stock-monitoring']]:
    'Monitore em tempo real o estoque da sua empresa.',
  [ReferenceIdEnum['stock-transfer-group']]:
    'Transfira um produto de estoque de forma simples.',
  [ReferenceIdEnum.integrations]:
    'Configure os canais de venda e tenha os seus produtos nas principais lojas online.',
  [ReferenceIdEnum.devices]:
    'Monitore os equipamentos que estão sendo utilizados na sua operação, exemplo: Máquina de cartão, Celular, etc.',
  [ReferenceIdEnum['user-groups']]:
    'Organize o acesso de cada usuário criando grupo de permissões.',
  [ReferenceIdEnum.users]:
    'Cadastre aqui os usuários que poderão usar a plataforma.',
  [ReferenceIdEnum['orders-reports']]:
    'Emita relatórios de vendas de forma fácil e rápida, o usuário poderá salvar em PDF, compartilhar ou até mesmo exportar para Excel.',
  [ReferenceIdEnum['products-reports']]:
    'Emita relatórios de produtos de forma fácil e rápida, o usuário poderá salvar em PDF, compartilhar ou até mesmo exportar para Excel.',
  [ReferenceIdEnum['stock-reports']]:
    'Emita relatórios de estoque de forma fácil e rápida, o usuário poderá salvar em PDF, compartilhar ou até mesmo exportar para Excel.',
  [ReferenceIdEnum['file-transmissions']]:
    'Você está na área de transmissões de arquivos. Confira aqui as plataformas integradas ao POS Fácil para ficar em dia com as suas obrigações fiscais.',
  [ReferenceIdEnum.loans]: 'Empréstimos',
  [ReferenceIdEnum['last-transactions']]: 'Ultimas transações',
  [ReferenceIdEnum.recharges]: 'Recargas',
  [ReferenceIdEnum['bill-payments']]: 'Pagamento de contas',
  [ReferenceIdEnum['collects-control']]: 'Controle de Coleta',
};

const OnboardingProvider: FCWithChildren = ({ children }) => {
  const { open, isOpen: isConfirmationModalOpen } = useConfirmationModal();
  const { user } = useAuth();

  const refIds = useMemo(() => {
    let baseList = [
      ReferenceIdEnum['my-account-item'],
      ReferenceIdEnum['favorite-apps'],
      ReferenceIdEnum['notifications-item'],
      ReferenceIdEnum['courses-item'],
      ReferenceIdEnum['help-item'],
    ];

    if (!user || !user.sidebar || !user.sidebar.length) {
      baseList = [
        ...baseList,
        ReferenceIdEnum.home,
        ReferenceIdEnum.users,
        ReferenceIdEnum['user-groups'],
        ReferenceIdEnum.companies,
        ReferenceIdEnum['marketplaces-management'],
        ReferenceIdEnum.orders,
        ReferenceIdEnum['orders-posts'],
        ReferenceIdEnum.cashiers,
        ReferenceIdEnum.customers,
        ReferenceIdEnum['abandoned-carts'],
        ReferenceIdEnum.sellers,
        ReferenceIdEnum['orders-reports'],
        ReferenceIdEnum.products,
        ReferenceIdEnum.categories,
        ReferenceIdEnum.brands,
        ReferenceIdEnum['product-environments'],
        ReferenceIdEnum['taxation-rules'],
        ReferenceIdEnum['products-reports'],
        ReferenceIdEnum.promotions,
        ReferenceIdEnum['stock-locals'],
        ReferenceIdEnum['stock-adjustment'],
        ReferenceIdEnum['stock-monitoring'],
        ReferenceIdEnum['stock-transfer-group'],
        ReferenceIdEnum['stock-reports'],
        ReferenceIdEnum.integrations,
        ReferenceIdEnum.events,
        ReferenceIdEnum['file-transmissions'],
        ReferenceIdEnum.devices,
      ];

      return baseList;
    }

    for (const nav of user.sidebar) {
      const { items } = nav;
      if ((items || []).length) {
        for (const item of items) {
          // @fixme - filtrando id da tela de cupons, se um dia a tela entrar no portal do cliente, somente remover o if

          const includedOnTab = tabProps[item._id];
          if (
            includedOnTab &&
            item.status === 'active' &&
            item._id !== '3594d1ba-5034-481d-a4d4-6c21dd782a9d'
          ) {
            const ref = SidebarPagesMapEnum[item._id] as ReferenceIdEnum;
            baseList.push(ref);
          }
        }
      }
    }

    return baseList;
  }, [user]);

  const maxStep = useMemo(() => refIds?.length || 0, [refIds]);
  const backdropRef = useRef<HTMLDivElement>(null);
  const hasAlreadyVisible = useRef<boolean>(false);

  const [isVisible, setIsVisible] = useState(false);
  const [step, setStep] = useState(0);

  const referenceId = useMemo(() => {
    const findRefId = refIds.find((_, index) => index + 1 === step);

    return findRefId || null;
  }, [refIds, step]);

  useEffect(() => {
    if (!!user && !user.hasFinishedOnboarding && !hasAlreadyVisible.current) {
      setIsVisible(true);

      hasAlreadyVisible.current = true;
    }
  }, [user]);

  const content = useMemo(() => {
    const findContent = getContent[referenceId];

    return findContent || '';
  }, [referenceId]);

  const close = useCallback(
    (shouldNeverShowAgain?: boolean) => {
      if (backdropRef.current) {
        backdropRef.current.style.opacity = '0';

        setTimeout(() => {
          setIsVisible(false);
        }, 300);

        if (shouldNeverShowAgain && user) {
          api.post('/finish-onboarding', { userId: user._id });
        }
      }
    },
    [user]
  );

  const next = useCallback(() => {
    setStep((prevState) => {
      const newState = prevState + 1;

      if (newState > maxStep) {
        setIsVisible(false);
        api.post('/finish-onboarding', { userId: user._id });
        return 1;
      }

      return newState;
    });
  }, [maxStep, user]);

  useEventListener('keydown', (ev: any) => {
    if (isConfirmationModalOpen) return;

    if (ev.keyCode === 39) next();
  });

  useEventListener('keydown', (ev: any) => {
    if (ev.key === 'Escape') {
      if (step === 0) {
        close();
      } else {
        open({
          onConfirm: () => close(true),
          content:
            'Você deseja realmente fechar? Isso não poderá ser visto novamente.',
        });
      }
    }
  });

  return (
    <OnboardingContext.Provider
      value={{
        isVisible,
        close: (force = false) =>
          force
            ? close()
            : open({
                onConfirm: () => close(true),
                content:
                  'Você deseja realmente fechar? Isso não poderá ser visto novamente.',
              }),
        next,
        step,
        maxStep,
        content,
      }}
    >
      {isVisible && (
        <>
          <Overflow>
            <SidebarSelectedStep referenceId={referenceId} step={step} />
          </Overflow>

          <Backdrop ref={backdropRef}>
            <WelcomePopover isVisible={step === 0} />

            <ToolbarPopover
              isVisible={step >= 1 && step <= 5}
              referenceId={referenceId}
            />

            <SideBarPopover
              isVisible={step > 5 && step <= maxStep}
              referenceId={referenceId}
            />

            <WelcomeCharacter isVisible={step >= 0} />

            <ToolbarCharacter isVisible={step >= 1 && step <= 5} />

            <SideBarCharacter isVisible={step > 5 && step <= maxStep} />
          </Backdrop>
        </>
      )}

      {children}
    </OnboardingContext.Provider>
  );
};

const useOnboarding = (): IOnboardingContextData => {
  return useContext(OnboardingContext);
};

export { OnboardingProvider, useOnboarding };
