import styled from 'styled-components';

export const SideBarContainer = styled.aside`
  max-width: 265px;
  min-width: 265px;
  height: 100vh;
  background: ${({ theme }) => theme.colors.surface};
  box-shadow: 3px 3px 15px #00000010;
  overflow-y: auto;
  z-index: 20;

  display: flex;
  flex-direction: column;

  > header {
    display: flex;
    width: 100%;
    padding: ${({ theme }) =>
      `${theme.spacing.md} ${theme.spacing.md} ${theme.spacing.sm}`};

    img {
      object-fit: contain;
      object-position: left;
    }

    a {
      display: flex;
      width: 100%;

      transition: all 0.4s;

      &:hover {
        opacity: 0.75;
      }
    }
  }

  > footer {
    display: flex;
    align-items: center;
    justify-content: center;

    padding: ${({ theme }) => `${theme.spacing.sm} ${theme.spacing.md}`};

    > div {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      padding-top: ${({ theme }) => theme.spacing.sm};

      cursor: default;

      border-top: ${({ theme }) => `2px solid ${theme.colors.background}`};

      span {
        font-size: 12px;
        color: ${({ theme }) => theme.colors.onSurface};
        font-weight: 700;
      }
    }
  }

  @media (max-width: 850px) {
    position: absolute;
    transform: translateX(-300px);
  }
`;

export const SideBarBackdrop = styled.div`
  position: absolute;
  width: 100vw;
  height: 100vh;
  background: ${({ theme }) => theme.colors.backdrop};
  z-index: 15;
  opacity: 0;
  transition: opacity 0.5s;
  display: none;

  cursor: pointer;
`;
