import { useCallback } from 'react';

import { getSegmentFromCookies } from '../utils/getSegmentFromCookies';
import { api } from '../services/api';

interface UseFilesResponse {
  registerImage(file: File): Promise<string | undefined>;
  registerFile: (file: File) => Promise<any>;
}

export function useFiles(): UseFilesResponse {
  const segment = getSegmentFromCookies();

  const registerImage = useCallback(
    async (file: File) => {
      if (!file) return undefined;

      const fileData = new FormData();

      fileData.append('file', file);

      const response = await api.post(
        `/segments/${segment}/files/img`,
        fileData
      );

      return response.data;
    },
    [segment]
  );

  const registerFile = useCallback(
    async (file: File) => {
      if (!file) return undefined;

      const fileData = new FormData();

      fileData.append('file', file);

      const response = await api.post(`/segments/${segment}/files`, fileData);

      return response.data;
    },
    [segment]
  );

  return {
    registerImage,
    registerFile,
  };
}
