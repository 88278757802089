import { FC, useCallback, useRef } from 'react';
import { useEffect } from 'react';

import { Fade } from '@chakra-ui/react';

import { useSettings } from '~/shared/hooks/settings';

import { CharacterImage } from './styles';
import { useOnboarding } from '../../hooks/onboarding';

interface ToolbarCharacterProps {
  isVisible: boolean;
}

export const ToolbarCharacter: FC<ToolbarCharacterProps> = ({ isVisible }) => {
  const { step } = useOnboarding();
  const { settings } = useSettings();
  const { toolbar: toolbarCharacter } = settings?.theme?.characters || {};

  const characterRef = useRef<HTMLImageElement>(null);

  const hasUpdated = useRef<boolean>(false);

  useEffect(() => {
    hasUpdated.current = false;

    if (characterRef.current && step === 1) {
      characterRef.current.style.opacity = '1';
    }
  }, [step]);

  const calculateHeight = useCallback(() => {
    if (!isVisible) return;

    const toolbarPopover = document.getElementById('toolbar-popover');

    if (toolbarPopover && toolbarPopover) {
      hasUpdated.current = true;

      const characterWidth = characterRef.current.getBoundingClientRect().width;

      const { bottom: toolbarPopoverBottom, left: toolbarPopoverLeft } =
        toolbarPopover.getBoundingClientRect();
      const windowHeight = window.innerHeight;

      const newCharacterHeight = windowHeight - toolbarPopoverBottom - 60;

      const newCharacterLeft = toolbarPopoverLeft - characterWidth / 2;

      characterRef.current.style.height = `${newCharacterHeight}px`;
      characterRef.current.style.left = `${newCharacterLeft}px`;
    }
  }, [isVisible]);

  useEffect(() => {
    if (!isVisible || hasUpdated.current || step > 1) return () => null;

    const interval = setInterval(() => {
      calculateHeight();
    }, 10);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  useEffect(() => {
    window.addEventListener('resize', calculateHeight);

    return () => {
      window.removeEventListener('resize', calculateHeight);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  return (
    <Fade in={isVisible}>
      <CharacterImage ref={characterRef} src={toolbarCharacter} alt="..." />
    </Fade>
  );
};
