export enum SubscriptionPaymentTypeEnum {
  'credit_card' = 'credit_card',
  boleto = 'boleto',
  pix = 'pix',
}

export enum SubscriptionFormattedPaymentTypeEnum {
  'credit_card' = 'Cartão de crédito',
  boleto = 'Boleto',
  pix = 'Pix',
}
