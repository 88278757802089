import { FC, useRef } from 'react';

import Link from 'next/link';
import Image from 'next/image';

import { Tooltip } from '@chakra-ui/react';

import { useSideBarDrawer } from '~/shared/hooks/sideBarDrawer';
import { useSettings } from '~/shared/hooks/settings';
import { routes } from '~/shared/constants/routes';

import { SideBarContainer, SideBarBackdrop } from './styles';
import { SideBarNav } from './SideBarNav';
import { TypeWriter } from '../TypeWriter';
import packageInfo from '../../../../package.json';

export const SideBar: FC = () => {
  const { settings } = useSettings();

  const { isSmallerThan850 } = useSideBarDrawer();

  const sideBarRef = useRef<HTMLDivElement>(null);

  return (
    <>
      <SideBarContainer ref={sideBarRef} id="sidebar">
        <header>
          {settings?.theme?.logo && (
            <Link href={routes.home} passHref>
              <Image
                src={settings.theme.logo}
                alt="..."
                height={60}
                width={160}
              />
            </Link>
          )}
        </header>

        <SideBarNav />

        <footer>
          <div>
            <Tooltip hasArrow label="Versão do sistema" closeOnClick={false}>
              <div>
                <TypeWriter
                  letterWidth={0}
                >{`v${packageInfo.version}`}</TypeWriter>
              </div>
            </Tooltip>
          </div>
        </footer>
      </SideBarContainer>

      {isSmallerThan850 && <SideBarBackdrop id="sidebar-backdrop" />}
    </>
  );
};
