import styled, { css, keyframes } from 'styled-components';

const backdropAnimation = keyframes`
  from { opacity: 0 }
  to { opacity: 1 }
`;

const modalAnimation = keyframes`
  from { transform: translateY(1000px); }
  to { transform: translateY(0); }
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  background: ${({ theme }) => theme.colors.backdrop};
  left: 0;
  top: 0;
  z-index: 101;
  overflow: hidden;
  animation: ${backdropAnimation} 0.3s;
  transition: opacity 0.3s;

  padding: ${({ theme }) => theme.spacing.md};

  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

interface IContainer {
  readonly titleColor?: string;
}
export const Content = styled.div<IContainer>`
  width: 100%;
  background-color: #f9f9f9;
  border-radius: ${({ theme }) => theme.borderRadius.sm};
  box-shadow: 3px 3px 15px #00000010;
  overflow: hidden;
  margin: ${({ theme }) => theme.spacing.md};
  animation: ${modalAnimation} 0.3s;
  transition: all 0.3s;
  z-index: 40;

  cursor: default;

  > div {
    height: 100%;

    > div {
      max-height: 730px;
      height: 100%;
      overflow: auto;
      padding: ${({ theme }) => theme.spacing.lg};

      > p {
        margin: ${({ theme }) => `${theme.spacing.xl} 0`};
        text-align: left;
        font-size: 18px;
        color: ${({ theme }) => theme.colors.onBackground};
        line-height: 34px;
      }
    }
  }

  ${({ titleColor, theme }) =>
    !!titleColor &&
    css`
      p {
        color: ${theme.colors[titleColor]};
      }
    `}
`;

export const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding-bottom: ${({ theme }) => theme.spacing['2xs']};

  h1 {
    font-size: 18px;
    color: ${({ theme }) => theme.colors.onBackground};
    line-height: 13px;
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;

    background: none;
    border: none;
    cursor: pointer;

    transition: all 0.4s;

    &:hover {
      opacity: 0.6;
    }

    svg {
      height: 20px;
      width: 20px;
      color: ${({ theme }) => theme.colors.onBackground};
      stroke-width: 1px;
    }
  }
`;

interface IButtonsContainerProps {
  readonly loadingConfirm: boolean;
}
export const ButtonsContainer = styled.ul<IButtonsContainerProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${({ theme }) => theme.spacing['2xs']};

  li {
    transition: all 0.3s;
    position: relative;

    ${({ loadingConfirm }) =>
      !loadingConfirm &&
      css`
        &:last-child {
          &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            height: 3px;
            border-radius: 2px;
            width: 100%;
            background: ${({ theme }) => theme.colors.secondary};
          }
        }
      `}

    > button {
      font-size: 22px;
      color: ${({ theme }) => theme.colors.secondary};
      font-weight: 700;
    }

    &:hover {
      opacity: 0.7;
    }
  }
`;
