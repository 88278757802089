import { Collapse } from '@chakra-ui/react';

import { FCWithChildren } from '~/shared/types/FCWithChildren';

import { Container } from './styles';

export const ErrorMessage: FCWithChildren = ({ children }) => {
  return (
    <Collapse in={!!children} animateOpacity unmountOnExit>
      <Container id="error-message">{children}</Container>
    </Collapse>
  );
};
