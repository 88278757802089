import styled, { ColorScheme, css } from 'styled-components';

export type ButtonSize = 'sm' | 'md';
export type ButtonVariant = 'outlined' | 'default';

interface ContainerProps {
  backgroundColor?: ColorScheme;
  textColor?: ColorScheme;
  size: ButtonSize;
  variant: ButtonVariant;
  borderColor: ColorScheme;
  scaleOnHover: boolean;
  backgroundOpacity: number;
  soon: boolean;
}

export const Container = styled.div<ContainerProps>`
  width: 100%;
  ${({ soon }) =>
    soon &&
    css`
      position: relative;
      pointer-events: none;
      #soon-badge {
        display: flex;
        justify-content: center;
        align-items: center;
        > div {
          position: absolute;
          > div {
            background-color: ${({ theme }) => theme.colors.terciary};
            color: ${({ theme }) => theme.colors.onTerciary};
          }
        }
      }
    `}

  > button {
    display: flex;
    align-items: center;
    justify-content: center;
    ${({ borderColor, theme }) =>
      Boolean(borderColor) &&
      css`
        border: 1px solid ${theme.colors[borderColor]};
      `}

    width: 100%;
    height: ${({ size }) => (size === 'md' ? '46' : '23')}px;
    padding: ${({ theme }) => `0 ${theme.spacing.sm}`};
    background: ${({ theme, backgroundColor, backgroundOpacity }) =>
      backgroundOpacity
        ? theme.colors[backgroundColor][backgroundOpacity]
        : theme.colors[backgroundColor]};
    border-radius: ${({ theme, size }) =>
      size === 'md' ? theme.borderRadius.md : theme.borderRadius.sm};
    font-weight: 700;
    color: ${({ theme, textColor }) => theme.colors[textColor]};
    font-size: ${({ size }) => (size === 'md' ? '16' : '14')}px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-weight: 600;

    transition: all 0.5s;

    &:hover:not(:disabled) {
      filter: brightness(0.89);
      ${({ scaleOnHover }) =>
        scaleOnHover &&
        css`
          transform: scale(1.04);
        `};
    }

    &:disabled {
      background: ${({ theme }) => theme.colors.disabledBackground} !important;
      color: ${({ theme }) => theme.colors.onDisabledBackground} !important;
      cursor: not-allowed;
    }

    ${({ variant, theme, textColor, backgroundColor }) =>
      variant === 'outlined' &&
      css`
        border: 1px solid ${theme.colors[textColor]};
        background: ${theme.colors[backgroundColor]};

        &:hover:not(:disabled) {
          filter: brightness(0.94);
        }
      `}

    @media (max-width: 550px) {
      font-size: 15px;
    }

    @media (max-width: 380px) {
      font-size: 14px;
    }
  }
`;
