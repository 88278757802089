export enum SubscriptionInvoiceStatusEnum {
  processing = 'processing',
  awaiting = 'awaiting',
  approved = 'approved',
  overdue = 'overdue',
  canceled = 'canceled',
  rejected = 'rejected',
}

export enum SubscriptionInvoiceFormattedStatusEnum {
  processing = 'Processando pagamento',
  awaiting = 'Aguardando pagamento',
  approved = 'Pagamento aprovado',
  overdue = 'Pagamento atrasado',
  canceled = 'Pagamento cancelado',
  rejected = 'Pagamento rejeitado',
}
