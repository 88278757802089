/* eslint-disable react/require-default-props */
import { ButtonHTMLAttributes, FC } from 'react';

import { IconType } from 'react-icons';

import { Tooltip } from '@chakra-ui/react';

import { BadgeContainer, Container } from './styles';
import { Badge } from '../../Badge';

interface ToolbarIconButtonProps
  extends ButtonHTMLAttributes<HTMLButtonElement> {
  tooltip?: string;
  icon: IconType;
  badge?: number;
  iconSize?: number;
  readonly extraBadgeInfo?: string;
}

export const ToolbarIconButton: FC<ToolbarIconButtonProps> = ({
  icon: Icon,
  tooltip,
  badge,
  iconSize = 25,
  extraBadgeInfo,
  ...props
}) => {
  return (
    <Tooltip label={tooltip} hasArrow closeOnClick={false}>
      <span>
        <Container type="button" {...props} iconSize={iconSize}>
          <Icon />

          {badge > 0 && (
            <div>
              <span>{badge}</span>
            </div>
          )}
        </Container>
        {extraBadgeInfo && (
          <BadgeContainer>
            <Badge>novo</Badge>
          </BadgeContainer>
        )}
      </span>
    </Tooltip>
  );
};
