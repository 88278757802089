import { useEffect } from 'react';

import { useRouter } from 'next/router';
import { FCWithChildren } from '~/shared/types/FCWithChildren';

export const ScrollToTop: FCWithChildren = ({ children }) => {
  const router = useRouter();

  useEffect(() => {
    const scrollToTop = (): void => {
      const mainElement = document.getElementById('main-element');

      if (mainElement) {
        mainElement.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });
      }
    };

    router.events.on('routeChangeComplete', scrollToTop);

    return () => {
      router.events.off('routeChangeComplete', scrollToTop);
    };
  }, [router]);

  return <>{children}</>;
};
