import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1;  }
`;

export const Container = styled.div`
  position: absolute;
  border-radius: ${({ theme }) => theme.borderRadius.sm};
  z-index: 110;
  width: 270px;
  background: #fff;
  animation: ${fadeIn} 0.5s;
  box-shadow: 3px 3px 15px #00000020;
  visibility: hidden;
  transition: all 0.3s;

  //@media (max-width: 850px) {
  //  &::after {
  //    content: '';
  //    border-bottom: 7px solid transparent;
  //    border-top: 7px solid transparent;
  //    border-right: 7px solid #fff;
  //    position: absolute;
  //
  //    top: calc(100% - 4px);
  //    left: 20%;
  //    transform: rotate(-90deg);
  //  }
  //}
`;

export const Arrow = styled.div`
  width: 0;
  height: 0;
  border-bottom: 7px solid transparent;
  border-top: 7px solid transparent;
  border-right: 7px solid #fff;
  position: absolute;
  animation: ${fadeIn} 0.5s;
  z-index: 130;
  visibility: hidden;
  transition: all 0.3s;

  //@media (max-width: 850px) {
  //  display: none;
  //}
`;
