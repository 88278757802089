import { useRef } from 'react';

type DebounceFunction = (...params: any[]) => void;

export function useDebounce<F extends DebounceFunction>(fn: F, delay = 500): F {
  const timeoutRef = useRef<number>(null);

  const debounceFn = (...params: Parameters<F>): void => {
    window.clearTimeout(timeoutRef.current);

    timeoutRef.current = window.setTimeout(() => fn(...params), delay);
  };

  return debounceFn as F;
}
