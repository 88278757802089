import { RefObject, useCallback, useEffect, useRef, useState } from 'react';

import { useEventListener } from './eventListener';

interface Size {
  width: number;
  height: number;
}

export function useElementSize<T extends HTMLElement = HTMLDivElement>(
  elementRef?: RefObject<T>,
  elementId?: string
): Size {
  const hasUpdated = useRef<boolean>(false);

  const [size, setSize] = useState<Size>({
    width: 0,
    height: 0,
  });

  const updateSize = useCallback(() => {
    if (hasUpdated.current) return;

    const node = elementId
      ? document.getElementById(elementId)
      : elementRef?.current;

    if (node) {
      hasUpdated.current = true;

      const { width, height } = node.getBoundingClientRect();

      setSize({
        width,
        height,
      });
    }
  }, [elementRef, elementId]);

  useEffect(() => {
    const interval = setInterval(updateSize, 10);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEventListener('resize', () => {
    hasUpdated.current = false;

    updateSize();
  });

  useEventListener('scroll', () => {
    hasUpdated.current = false;

    updateSize();
  });

  return size;
}
