import { FC } from 'react';

import Head from 'next/head';

import { useSettings } from '~/shared/hooks/settings';

export const SEO: FC = () => {
  const { settings } = useSettings();

  return (
    <Head>
      <meta name="author" content="RS Solutions" />
      <meta property="og:type" content="article" />
      <meta property="og:locale" content="pt_BR" />

      {settings?.theme?.socialMediaLogo && (
        <>
          <meta name="image" content={settings.theme.socialMediaLogo} />
          <meta property="og:image" content={settings.theme.socialMediaLogo} />
          <meta property="og:image:width" content="1200" />
          <meta property="og:image:height" content="630" />
        </>
      )}

      {settings?.description && (
        <>
          <meta name="description" content={settings.description} />
          <meta property="og:description" content={settings.description} />
        </>
      )}

      {settings?.title && (
        <>
          <meta property="og:title" content={settings.title} />
          <meta property="og:site_name" content={settings.title} />
        </>
      )}
      {/*
      {settings.currentUrl && (
        <>
          <meta name="url" content={settings.currentUrl} />
          <meta property="og:url" content={settings.currentUrl} />
        </>
      )} */}

      {settings?.theme?.favicon && (
        <link
          rel="shortcut icon"
          href={settings.theme.favicon}
          type="image/x-icon"
        />
      )}

      <title>{settings?.title || 'Portal do Cliente'}</title>
    </Head>
  );
};
