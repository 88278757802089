import styled, { keyframes } from 'styled-components';

import { IOfferStepSettings } from '../../interfaces/IOfferStepSettings';

const backdropAnimation = keyframes`
  from { opacity: 0 }
  to { opacity: 1 }
`;

const modalAnimation = keyframes`
  from { transform: translateY(1000px); }
  to { transform: translateY(0); }
`;

interface ContentProps {
  settings: Omit<IOfferStepSettings, 'status'>;
}

export const Backdrop = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  position: absolute;
  left: 0;
  top: 0;
  z-index: 30;
  overflow: hidden;
  animation: ${backdropAnimation} 0.3s;
  transition: opacity 0.3s;
  padding: 25px;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Content = styled.div<ContentProps>`
  position: relative;

  background-color: ${({ theme }) => theme.colors.surface};
  max-width: 350px;
  width: 100%;
  border-radius: ${({ theme }) => theme.borderRadius.md};
  box-shadow: 3px 3px 15px #00000010;

  animation: ${modalAnimation} 0.3s;
  transition: all 0.3s;

  > div {
    width: 100%;
    min-height: 500px;
    max-height: 700px;

    position: relative;
    display: flex;

    > button {
      position: absolute;
      box-shadow: 3px 3px 15px #00000050;

      top: -10px;
      right: -10px;

      display: flex;
      align-items: center;
      justify-content: center;
      background: ${({ settings }) => settings?.colors?.primary};
      width: 22px;
      height: 22px;
      border-radius: 50%;

      border: none;
      cursor: pointer;

      z-index: 5;

      transition: all 0.4s;

      &:hover {
        filter: brightness(0.85);
      }

      svg {
        height: 13px;
        width: 13px;
        color: ${({ settings }) => settings?.colors?.text};
        stroke-width: 1px;
      }
    }

    > div {
      width: 100%;

      display: flex;

      border-radius: ${({ theme }) => theme.borderRadius.md};
      overflow: hidden;
    }
  }
`;

export const LoaderWrapper = styled.div`
  padding: 50px 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;
