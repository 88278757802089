import styled, { keyframes } from 'styled-components';

export type ModalSize = 'sm' | 'md' | 'lg' | 'xlg' | 'big';

interface ContentProps {
  size: ModalSize;
}

const backdropAnimation = keyframes`
  from { opacity: 0 }
  to { opacity: 1 }
`;

const modalAnimation = keyframes`
  from { transform: translateY(1000px); }
  to { transform: translateY(0); }
`;

const modalSize: Record<ModalSize, string> = {
  big: '1200px',
  xlg: '850px',
  lg: '700px',
  md: '550px',
  sm: '400px',
};

export const Container = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  background: ${({ theme }) => theme.colors.backdrop};
  left: 0;
  top: 0;
  z-index: 20;
  overflow: hidden;
  animation: ${backdropAnimation} 0.3s;
  transition: opacity 0.3s;

  padding: ${({ theme }) => theme.spacing.md};

  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const Content = styled.div<ContentProps>`
  position: relative;

  max-width: ${({ size }) => modalSize[size]};
  width: 100%;
  background-color: #f9f9f9;
  border-radius: ${({ theme }) => theme.borderRadius.md};
  box-shadow: 3px 3px 15px #00000010;
  overflow: hidden;
  margin: ${({ theme }) => theme.spacing['2xs']};
  animation: ${modalAnimation} 0.3s;
  transition: all 0.3s;
  z-index: 40;

  cursor: default;

  > div {
    height: 100%;

    > div {
      max-height: 730px;
      height: 100%;
      overflow: auto;
      padding: ${({ theme }) => theme.spacing['2xs']};
    }
  }
`;

export const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding-bottom: ${({ theme }) => theme.spacing['2xs']};
  margin-bottom: ${({ theme }) => theme.spacing['2xs']};
  border-bottom: 1px solid #ddd;
  gap: ${({ theme }) => theme.spacing.sm};

  h1 {
    font-size: 14px;
    color: ${({ theme }) => theme.colors.onBackground};
    text-transform: uppercase;
    line-height: 13px;
    flex: 1;
    font-weight: bold;
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;

    background: none;
    border: none;
    cursor: pointer;

    transition: all 0.4s;

    &:hover {
      opacity: 0.6;
    }

    svg {
      height: 16px;
      width: 16px;
      color: ${({ theme }) => theme.colors.onBackground};
      stroke-width: 1px;
    }
  }
`;
