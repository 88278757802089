import styled from 'styled-components';

interface ContainerProps {
  iconSize: number;
}

export const Container = styled.button<ContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;

  transition: all 0.3s;

  svg {
    height: ${({ iconSize }) => iconSize}px;
    width: ${({ iconSize }) => iconSize}px;
    color: #888888;
  }

  > div {
    display: flex;
    align-items: center;
    justify-content: center;

    position: absolute;
    border-radius: 50%;
    background: ${({ theme }) => theme.colors.primary};
    width: 17px;
    height: 17px;
    top: -5px;
    right: -5px;
    border: 1px solid ${({ theme }) => theme.colors.background};

    transition: all 0.3s;

    span {
      text-align: center;
      font-size: 11px;
      line-height: 11px;
      color: ${({ theme }) => theme.colors.onPrimary};
      font-weight: 800;
    }
  }

  &:disabled {
    opacity: 0.75;
  }

  &:hover {
    opacity: 0.75;

    > div {
      transform: scale(1.3);
    }
  }
`;

export const BadgeContainer = styled.div`
  position: absolute;
  margin-top: 5px;
  left: -10px;
`;
