import styled, { keyframes } from 'styled-components';

const backdropAnimation = keyframes`
  from { opacity: 0 }
  to { opacity: 1 }
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 100;
  overflow: hidden;
  animation: ${backdropAnimation} 0.3s;
  transition: opacity 0.3s;
`;

export const Overflow = styled.div`
  background: rgba(0, 0, 0, 0.5);
  mix-blend-mode: hard-light;

  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 100;
  left: 0;
  top: 0;
`;

export const WrapperOption = styled.div`
  padding: 50px;
  position: absolute;
  background-color: gray;
`;
