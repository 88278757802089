import { CSSProperties } from 'styled-components';
import { Tooltip } from '@chakra-ui/react';

import { FCWithChildren } from '~/shared/types/FCWithChildren';

import { Container } from './styles';

interface IOutlinedButton {
  readonly onClick?: () => void;
  readonly tooltip?: string;
  readonly active?: boolean;
  readonly isDisabled?: boolean;
  readonly type?: 'button' | 'submit' | 'reset';
  readonly buttonStyle?: CSSProperties;
}

export const OutlinedButton: FCWithChildren<IOutlinedButton> = ({
  children,
  onClick = () => null,
  tooltip,
  active = false,
  isDisabled = false,
  type = 'button',
  buttonStyle = {},
}) => {
  return (
    <Tooltip hasArrow label={tooltip}>
      <span>
        <Container
          onClick={onClick}
          active={active}
          disabled={isDisabled}
          type={type}
          style={buttonStyle}
        >
          {children}
        </Container>
      </span>
    </Tooltip>
  );
};
