/* eslint-disable react/no-danger */
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';

import Player from '@vimeo/player';

import { api } from '~/shared/services/api';

import { Container, Content } from './styles';
import { IVideoProgress } from '../../interfaces/IVideoProgress';

interface IProps {
  onClose(): void;
}

export interface IVideoPlayerHandles {
  open(uri: string, startAt?: number): Promise<void>;
}

const VideoPlayer: React.ForwardRefRenderFunction<
  IVideoPlayerHandles,
  IProps
> = ({ onClose }, ref) => {
  const [videoId, setVideoId] = useState('');
  const [videoProgress, setVideoProgress] = useState<
    IVideoProgress | undefined
  >();
  const [currentPercentage, setCurrentPercentage] = useState(0);
  const [currentSeconds, setCurrentSeconds] = useState(0);
  const [vimeoPlayer, setVimeoPlayer] = useState<Player | null>(null);
  const [startAtTime, setStartAtTime] = useState<number>();

  useEffect(() => {
    if (vimeoPlayer) {
      vimeoPlayer.setCurrentTime(
        videoProgress && videoProgress.currentPercentage < 1
          ? videoProgress.currentSeconds
          : 0
      );
    }
  }, [vimeoPlayer, videoProgress]);

  useEffect(() => {
    if (vimeoPlayer) {
      vimeoPlayer.on('timeupdate', (value) => {
        setCurrentSeconds(value.seconds);
        setCurrentPercentage((prevState) =>
          prevState === 1 ? 1 : value.percent
        );
      });

      vimeoPlayer.on('pause', async () => {
        const data = {
          currentSeconds,
          currentPercentage,
          videoId,
        };

        if (videoProgress) {
          await api.put(`/courses/progress/${videoProgress._id}`, data);
        } else {
          await api.post('/courses/progress', data);
        }
      });
    }

    return () => {
      if (vimeoPlayer) {
        vimeoPlayer.off('pause');
        vimeoPlayer.off('timeupdate');
      }
    };
  }, [currentPercentage, currentSeconds, videoId, videoProgress, vimeoPlayer]);

  const open = useCallback(async (uri: string, startAt?: number) => {
    const splittedUri = uri.split('/').pop();

    const response = await api.get(`/courses/progress/video/${splittedUri}`);

    if (response.data) {
      setVideoProgress(response.data);
    } else {
      setVideoProgress(undefined);
    }

    if (startAt) {
      setStartAtTime(startAt);
    } else setStartAtTime(undefined);

    setVideoId(splittedUri);

    const iframe = document.querySelector('iframe');

    setVimeoPlayer(new Player(iframe));
  }, []);

  const handleClose = useCallback(
    async (e) => {
      if (
        e.target.id === 'modal-container' ||
        e.target.id === 'modal-content' ||
        e.target.id === 'close-button' ||
        e.target.id === 'close-button-icon'
      ) {
        const data = {
          currentSeconds,
          currentPercentage,
          videoId,
        };

        if (videoProgress) {
          await api.put(`/courses/progress/${videoProgress._id}`, data);
        } else {
          await api.post('/courses/progress', data);
        }

        setVideoId('');
        setCurrentPercentage(0);
        setCurrentSeconds(0);
        setVideoProgress(undefined);
        onClose();
      }
    },
    [currentPercentage, currentSeconds, videoId, videoProgress, onClose]
  );

  useImperativeHandle(
    ref,
    () => ({
      open,
    }),
    [open]
  );

  if (!videoId) {
    return <></>;
  }

  const getSource = (url: string): string => {
    if (!startAtTime) {
      return url;
    }

    const floor = Math.floor(startAtTime);
    let formatedTime = '';
    if (floor < 3600) {
      const isoTime = new Date(floor * 1000).toISOString().substr(14, 5);
      const splitted = isoTime.split(':');
      const minutes = Number(splitted[0]);
      const seconds = Number(splitted[1]);
      formatedTime = `${minutes}m${seconds}s`;
    } else {
      const isoTime = new Date(floor * 1000).toISOString().substr(11, 8);
      const splitted = isoTime.split(';');
      const hours = Number(splitted[0]);
      const minutes = Number(splitted[1]);
      const seconds = Number(splitted[2]);
      formatedTime = `${hours}h${minutes}m${seconds}s`;
    }

    const finalUrl = url.concat(`#t=${formatedTime}`);
    return finalUrl;
  };

  return (
    <>
      <Container id="modal-container">
        <Content onClick={handleClose} id="modal-content">
          <div>
            <iframe
              src={getSource(`https://player.vimeo.com/video/${videoId}`)}
              frameBorder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowFullScreen
              title="Curso / Treinamento"
            />
          </div>

          <button type="button" id="close-button">
            <i className="fa fa-times" id="close-button-icon" />
          </button>
        </Content>
      </Container>
    </>
  );
};

export default forwardRef(VideoPlayer);
