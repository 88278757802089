import styled, { ThemeBorderRadius, css } from 'styled-components';

export type BadgeType =
  | 'warning'
  | 'error'
  | 'success'
  | 'info'
  | 'primary'
  | 'secondary'
  | 'terciary'
  | 'yellow'
  | 'darkGray';

interface ContainerProps {
  type: BadgeType;
  borderRadius: ThemeBorderRadius;
  isDisabled: boolean;
  href: string;
}

export const Wrapper = styled.div`
  display: inline-block;
`;

export const Container = styled.div<ContainerProps>`
  display: inline-block;
  padding: 7px 10px;

  background: ${({ theme, type }) => theme.colors[type]}33;

  color: ${({ theme, type }) => theme.colors[type]};
  border-radius: ${({ theme, borderRadius }) =>
    theme.borderRadius[borderRadius]};
  line-height: 8px;
  font-size: 10px;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
  cursor: default;

  transition: all 0.3s;

  ${({ onClick, type, theme, href }) =>
    (!!onClick || !!href) &&
    css`
      cursor: pointer;

      &:hover {
        background: ${theme.colors[type]}55;
      }
    `}

  ${({ theme, type, isDisabled }) =>
    isDisabled &&
    css`
      cursor: not-allowed;
      opacity: 0.65;

      &:hover {
        background: ${theme.colors[type]}33;
      }

      pointer-events: none;
    `}
`;
