/* eslint-disable react/require-default-props */
/* eslint-disable react/button-has-type */
import { ButtonHTMLAttributes, CSSProperties } from 'react';

import { ColorScheme } from 'styled-components';
import { Tooltip } from '@chakra-ui/react';

import { FCWithChildren } from '~/shared/types/FCWithChildren';

import { ButtonSize, ButtonVariant, Container } from './styles';
import { Spinner } from '../../Spinner';
import { Badge } from '../../Badge';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  isLoading?: boolean;
  variant?: ButtonVariant;
  disabledTooltip?: string;
  backgroundColor?: ColorScheme;
  textColor?: ColorScheme;
  size?: ButtonSize;
  borderColor?: ColorScheme;
  scaleOnHover?: boolean;
  backgroundOpacity?: number;
  containerStyle?: CSSProperties;
  soon?: boolean;
}

export const Button: FCWithChildren<ButtonProps> = ({
  isLoading,
  backgroundColor = 'primary',
  textColor = 'onPrimary',
  disabled,
  disabledTooltip = '',
  children,
  variant = 'default',
  type = 'button',
  size = 'md',
  scaleOnHover = false,
  backgroundOpacity = null,
  soon = false,
  borderColor,
  containerStyle,
  ...props
}) => {
  return (
    <Tooltip label={disabledTooltip} hasArrow isDisabled={!disabled}>
      <Container
        backgroundColor={backgroundColor}
        textColor={textColor}
        size={size}
        variant={variant}
        borderColor={borderColor}
        scaleOnHover={scaleOnHover}
        backgroundOpacity={backgroundOpacity}
        style={containerStyle}
        soon={soon}
        id="button"
      >
        <button type={type} disabled={disabled || isLoading} {...props}>
          {isLoading ? <Spinner /> : children}
        </button>

        {soon && (
          <div id="soon-badge">
            <Badge type="primary">breve</Badge>
          </div>
        )}
      </Container>
    </Tooltip>
  );
};
