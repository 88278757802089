import { FC, useEffect, useRef, useState } from 'react';

import useIntersectionObserver from '~/shared/hooks/intersectionObserver';

import { WrapperImage, StyledImage } from './styles';

type TImage = {
  alt: string;
  src?: any;
  width?: number;
  height?: number;
  objectFit?: 'contain' | 'cover';
  objectPosition?: 'center' | 'top' | 'bottom';
};

export const Image: FC<TImage> = ({
  src,
  width,
  height,
  objectFit,
  objectPosition,
  alt,
}) => {
  const ref = useRef(null);
  const entry = useIntersectionObserver(ref, {});
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (!isVisible && entry?.isIntersecting) {
      setIsVisible(true);
    }
  }, [isVisible, entry]);

  if (!src) return null;

  return (
    <WrapperImage width={width} height={height} ref={ref}>
      {isVisible && (
        <StyledImage
          src={src}
          alt={alt}
          objectFit={objectFit}
          objectPosition={objectPosition}
        />
      )}
    </WrapperImage>
  );
};
