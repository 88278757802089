import {
  Dispatch,
  SetStateAction,
  useEffect,
  createContext,
  useCallback,
  useContext,
  useState,
} from 'react';

import useSWR from 'swr';

import { unmask } from '~/shared/utils/masks';
import { unformatCurrency } from '~/shared/utils/formatCurrency';
import { FCWithChildren } from '~/shared/types/FCWithChildren';

import { OfferStepFieldType } from '../interfaces/IOfferStepField';
import { IOffer } from '../interfaces/IOffer';
import { OfferModal } from '../components/OfferModal';

type Field = Record<string, any>;
type FieldError = Record<string, string>;

interface IOfferContextData {
  selectOffer(offerId: string, url?: string): void;
  selectedOfferId: string;
  isLoading: boolean;
  fieldsError: FieldError;
  setFieldsError: Dispatch<SetStateAction<FieldError>>;
  offer: IOffer | undefined;
  fieldsValue: Field;
  changeFieldValue(name: string, value: string, type: OfferStepFieldType): void;
}

const OfferContext = createContext({} as IOfferContextData);

const OfferProvider: FCWithChildren = ({ children }) => {
  const [selectedOfferId, setSelectedOfferId] = useState('');

  const { data: offer, error } = useSWR<IOffer>(`offers/${selectedOfferId}`);

  const [fieldsValue, setFieldsValue] = useState({} as Field);
  const [fieldsError, setFieldsError] = useState({} as FieldError);

  useEffect(() => {
    if (offer?.steps?.length > 0) {
      offer.steps.forEach((step) => {
        step.fields.forEach((field) =>
          setFieldsValue((prevState) => ({
            ...prevState,
            [field.name]: field.type === 'checkbox' ? false : '',
          }))
        );
      });
    }
  }, [offer]);

  const selectOffer = useCallback(async (offerId: string) => {
    setSelectedOfferId(offerId);
  }, []);

  const changeFieldValue = useCallback(
    (name: string, value: any, type: OfferStepFieldType) => {
      setFieldsValue((prevState) => {
        setFieldsError((prevFieldsError) => ({
          ...prevFieldsError,
          [name]: '',
        }));

        let newValue = value;

        const typesWithMask = [
          'phone-number',
          'postal-code',
          'document',
          'legal-document',
          'private-document',
          'rg',
        ];

        if (typesWithMask.includes(type)) {
          newValue = unmask(newValue);
        }

        if (type === 'checkbox') {
          newValue = !prevState[name];
        }

        if (type === 'price') {
          newValue = unformatCurrency(newValue);
        }

        return {
          ...prevState,
          [name]: newValue,
        };
      });
    },
    []
  );

  return (
    <OfferContext.Provider
      value={{
        selectOffer,
        selectedOfferId,
        offer,
        isLoading: !offer && !error,
        fieldsError,
        setFieldsError,
        fieldsValue,
        changeFieldValue,
      }}
    >
      {children}

      {!!selectedOfferId && (
        <OfferModal onClose={() => setSelectedOfferId('')} />
      )}
    </OfferContext.Provider>
  );
};

const useOffer = (): IOfferContextData => {
  return useContext(OfferContext);
};

export { OfferProvider, useOffer };
