import { FC, useEffect, useRef } from 'react';

import { Fade } from '@chakra-ui/react';

import { useSideBarDrawer } from '~/shared/hooks/sideBarDrawer';
import { useGetElementVisibleById } from '~/shared/hooks/getElementVisibleById';
import { useOnboarding } from '../../hooks/onboarding';

import { Container, Arrow } from './styles';

import { PopoverContent } from '../PopoverContent';

interface SideBarPopoverProps {
  isVisible: boolean;
  referenceId: string;
}

export const SideBarPopover: FC<SideBarPopoverProps> = ({
  isVisible,
  referenceId,
}) => {
  const { step, next: nextStep } = useOnboarding();
  const { close, open, isSmallerThan850 } = useSideBarDrawer();

  const containerRef = useRef<HTMLDivElement>(null);
  const arrowRef = useRef<HTMLDivElement>(null);
  const { element: tabButton, isVisible: elementIsVisible } =
    useGetElementVisibleById(referenceId);

  useEffect(() => {
    if (isSmallerThan850 && isVisible) open();

    return () => {
      if (isSmallerThan850) close();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSmallerThan850, isVisible]);

  useEffect(() => {
    if (isVisible && elementIsVisible) {
      setTimeout(() => {
        containerRef.current.style.visibility = 'visible';
        arrowRef.current.style.visibility = 'visible';
      }, 500);
    }
  }, [isVisible, elementIsVisible]);

  useEffect(() => {
    if (isVisible && tabButton) {
      const arrow = arrowRef.current;
      const board = containerRef.current;
      const sideBar = document.getElementById('sidebar');

      const boardHeight = board.offsetHeight;
      const arrowHeight = arrow.offsetHeight;
      const windowHeight = window.innerHeight;
      const tabButtonHeight = tabButton.offsetHeight;
      const tabButtonWidth = tabButton.offsetWidth;

      let newArrowTop: number;
      let newBoardTop: number;
      let newBoardLeft: number;
      let newArrowLeft: number;
      let newScrollTop: number;

      const tabButtonBounding = tabButton.getBoundingClientRect();

      const distanceTabButtonTop = tabButtonBounding.top;
      const distanceTabButtonBottom = tabButtonBounding.bottom;

      const tabButtonOffsetBottom = windowHeight - distanceTabButtonBottom;

      if (tabButtonOffsetBottom <= 100) {
        newScrollTop = tabButtonOffsetBottom + 100;

        const newScroll = newScrollTop + sideBar.scrollTop + windowHeight;

        if (newScroll >= sideBar.scrollHeight) {
          sideBar.scrollTo({
            behavior: 'smooth',
            top: sideBar.scrollHeight,
          });

          newScrollTop =
            sideBar.scrollHeight - (sideBar.scrollTop + windowHeight);
        } else {
          sideBar.scrollTo({
            behavior: 'smooth',
            top: newScrollTop + sideBar.scrollTop,
          });
        }
      }

      if (isSmallerThan850) {
        if (step <= 8) {
          newBoardTop = distanceTabButtonBottom + 10;

          board.style.left = `10px`;
          board.style.top = `${newBoardTop}px`;

          newArrowTop = newBoardTop - arrowHeight + 4;

          arrow.style.transform = 'rotate(90deg)';

          arrow.style.top = `${newArrowTop}px`;
          arrow.style.left = `${board.offsetLeft + board.offsetWidth * 0.2}px`;
        } else {
          newBoardTop =
            windowHeight - distanceTabButtonTop + 10 + (newScrollTop || 0);

          board.style.left = `10px`;
          board.style.top = `unset`;
          board.style.bottom = `${newBoardTop}px`;

          arrowRef.current.style.transform = 'rotate(-90deg)';

          newArrowTop = newBoardTop + board.offsetHeight - 4;

          arrow.style.top = `unset`;
          arrow.style.bottom = `${newArrowTop - boardHeight - 6}px`;
          arrow.style.left = `${board.offsetLeft + board.offsetWidth * 0.2}px`;
        }
      } else {
        const { top: tabTopUpdated } = tabButton.getBoundingClientRect();

        newBoardTop =
          tabTopUpdated -
          boardHeight / 2 +
          tabButtonHeight / 2 -
          (newScrollTop || 0);
        newBoardLeft = tabButtonWidth + 20;
        newArrowTop =
          tabTopUpdated -
          arrowHeight / 2 +
          tabButtonHeight / 2 -
          (newScrollTop || 0);
        newArrowLeft = newBoardLeft - 7;

        board.style.left = `${newBoardLeft}px`;
        board.style.top = `${newBoardTop}px`;

        arrow.style.left = `${newArrowLeft}px`;
        arrow.style.top = `${newArrowTop}px`;
      }
    }
  }, [isSmallerThan850, isVisible, step, tabButton]);

  useEffect(() => {
    if (!referenceId) return;

    const findElement = document.getElementById(referenceId);

    if (!findElement) {
      nextStep();
    }
  }, [referenceId]);

  return (
    <Fade in={!!isVisible && !!elementIsVisible}>
      <Arrow ref={arrowRef} />

      <Container ref={containerRef} id="sidebar-popover">
        <PopoverContent />
      </Container>
    </Fade>
  );
};
