import { SWRConfig } from 'swr';
import { ChakraProvider } from '@chakra-ui/react';

import { OfferProvider } from '~/modules/offers/hooks/offer';
import { NotificationsProvider } from '~/modules/notifications/hooks/notifications';
import { CheckoutProvider } from '~/modules/checkout/hooks/checkout';
import { MeliCategoriesProvider } from '~/modules/categories/hooks/meliCategories';
import { AuthProviderHOC } from '~/modules/auth/hooks/auth';

import { SideBarDrawerProvider } from '~/shared/hooks/sideBarDrawer';
import { ModalProvider } from '~/shared/hooks/modal';

import { VideoModalProvider } from './videoModal';
import { FacebookPixelProvider } from './facebookPixel';
import { ConfirmationModalProvider } from './confirmationModal';
import { api } from '../services/api';
import { ScrollToTop } from '../components/ScrollToTop';
import { MapProvider } from '../../modules/menuFacil/hooks/map';
import { FCWithChildren } from '../types/FCWithChildren';

const fetcher = (url: string): any => api.get(url).then((res) => res.data);

export const AppProvider: FCWithChildren = ({ children }) => {
  return (
    <ScrollToTop>
      <FacebookPixelProvider>
        <SWRConfig value={{ fetcher, focusThrottleInterval: 1000 * 30 }}>
          <AuthProviderHOC>
            <NotificationsProvider>
              <ChakraProvider>
                <VideoModalProvider>
                  <SideBarDrawerProvider>
                    <ConfirmationModalProvider>
                      <CheckoutProvider>
                        <OfferProvider>
                          <MeliCategoriesProvider>
                            <MapProvider>
                              <ModalProvider>{children}</ModalProvider>
                            </MapProvider>
                          </MeliCategoriesProvider>
                        </OfferProvider>
                      </CheckoutProvider>
                    </ConfirmationModalProvider>
                  </SideBarDrawerProvider>
                </VideoModalProvider>
              </ChakraProvider>
            </NotificationsProvider>
          </AuthProviderHOC>
        </SWRConfig>
      </FacebookPixelProvider>
    </ScrollToTop>
  );
};
