import { ChangeEvent, FC, useEffect } from 'react';

import { IOfferStepSettings } from '~/modules/offers/interfaces/IOfferStepSettings';
import { IOfferStepField } from '~/modules/offers/interfaces/IOfferStepField';
import { useOffer } from '~/modules/offers/hooks/offer';

import {
  maskDate,
  maskDocument,
  maskLegalDocument,
  maskPhoneNumber,
  maskPostalCode,
  maskPrivateDocument,
  maskRg,
} from '~/shared/utils/masks';
import { formatCurrency } from '~/shared/utils/formatCurrency';
import { ErrorMessage } from '~/shared/components/Form/ErrorMessage';

import { Container } from './styles';

interface FieldProps {
  data: IOfferStepField;
  settings: Omit<IOfferStepSettings, 'status'>;
}

export const Field: FC<FieldProps> = ({ data, settings }) => {
  const { fieldsValue, fieldsError, changeFieldValue } = useOffer();

  useEffect(() => {
    if (data.type === 'select') {
      changeFieldValue(data.name, data.options[0].value, 'select');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleChangeFieldValue(
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ): void {
    const { name, value } = e.target;

    changeFieldValue(name, value, data.type);
  }

  function maskValue(): any {
    const value = fieldsValue[data.name];

    if (!value) return value;

    if (data.type === 'price') {
      return formatCurrency(value);
    }

    if (data.type === 'rg') {
      return maskRg(value);
    }

    if (data.type === 'date') {
      return maskDate(value);
    }

    if (data.type === 'document') {
      return maskDocument(value);
    }

    if (data.type === 'phone-number') {
      return maskPhoneNumber(value);
    }

    if (data.type === 'postal-code') {
      return maskPostalCode(value);
    }

    if (data.type === 'legal-document') {
      return maskLegalDocument(value);
    }

    if (data.type === 'private-document') {
      return maskPrivateDocument(value);
    }

    return value;
  }

  return (
    <Container settings={settings}>
      {data.type !== 'select' &&
        data.type !== 'checkbox' &&
        data.type !== 'radio' && (
          <input
            type={data.type === 'date' ? 'text' : data.type}
            placeholder={data.placeholder}
            name={data.name}
            required={data.isRequired}
            value={maskValue()}
            onChange={handleChangeFieldValue}
          />
        )}

      {data.type === 'checkbox' && (
        <label htmlFor={data.name}>
          <input
            id={data.name}
            type="checkbox"
            name={data.name}
            checked={maskValue()}
            onChange={handleChangeFieldValue}
          />

          {!!data.placeholder && data.placeholder}
        </label>
      )}

      {data.type === 'radio' && data.options && data.options.length > 0 && (
        <div>
          {!!data.placeholder && <span>{data.placeholder}</span>}

          {data.options.map((option) => (
            <label htmlFor={option._id} key={option._id}>
              <input
                type="radio"
                id={option._id}
                name={data.name}
                value={option.value}
              />

              {option.label}
            </label>
          ))}
        </div>
      )}

      {data.type === 'select' && (
        <select
          name={data.name}
          value={maskValue()}
          onChange={handleChangeFieldValue}
        >
          {data.options && data.options.length > 0 && (
            <optgroup label={data.placeholder}>
              {data.options.map((option) => (
                <option key={option._id} value={option.value}>
                  {option.label}
                </option>
              ))}
            </optgroup>
          )}
        </select>
      )}

      <ErrorMessage>{fieldsError[data.name]}</ErrorMessage>
    </Container>
  );
};
