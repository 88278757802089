/* eslint-disable react/require-default-props */
import { useMemo } from 'react';

import { IconType } from 'react-icons';

import { useRouter } from 'next/router';
import NextLink, { LinkProps as NextLinkProps } from 'next/link';

import { useAuth } from '~/modules/auth/hooks/auth';

import { validateUserPermissions } from '~/shared/utils/validateUserPermissions';
import { FCWithChildren } from '~/shared/types/FCWithChildren';
import { Badge } from '~/shared/components/Badge';

import { Container } from './styles';

interface NavItemProps extends NextLinkProps {
  icon: IconType;
  permissions?: string[];
  shouldMatchExactHref?: boolean;
  isDisabled?: boolean;
  comingSoon?: boolean;
  isNew?: boolean;
  id?: string;
}

export const NavItem: FCWithChildren<NavItemProps> = ({
  icon: Icon,
  children,
  permissions,
  shouldMatchExactHref = false,
  isDisabled = false,
  comingSoon = false,
  isNew = false,
  href,
  as,
  id,
  ...props
}) => {
  const { user } = useAuth();

  const { asPath } = useRouter();

  const isActive = useMemo(() => {
    if (shouldMatchExactHref && (asPath === href || asPath === as)) {
      return true;
    }

    if (
      !shouldMatchExactHref &&
      (asPath.startsWith(String(href)) || asPath.startsWith(String(as)))
    ) {
      return true;
    }

    return false;
  }, [asPath, href, as, shouldMatchExactHref]);

  const userHasValidPermissions = validateUserPermissions({
    user,
    permissions,
  });

  if (!userHasValidPermissions) return null;

  return (
    <Container isActive={isActive} isDisabled={isDisabled} id={id}>
      <NextLink href={href} passHref {...props}>
        <Icon />

        <span>{children}</span>

        {comingSoon && <Badge type="primary">breve</Badge>}
        {isNew && <Badge type="primary">novo</Badge>}
      </NextLink>
    </Container>
  );
};
