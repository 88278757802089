import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: ${(p) => p.theme.borderRadius.sm};
  background-color: ${(p) => p.theme.colors.surface};
  color: ${({ theme }) => theme.colors.onSurface};

  > div {
    padding: ${({ theme }) => `0 ${theme.spacing['2xs']}`};

    p {
      font-size: 14px;

      letter-spacing: 0;
    }
  }
`;

export const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: ${({ theme }) => theme.spacing['2xs']};

  > div {
    display: flex;
    align-items: center;

    > svg {
      width: 15px;
      height: 15px;
      margin-right: ${({ theme }) => theme.spacing.xs};
    }

    > span {
      font-weight: 700;
      font-size: 15px;
      line-height: 15px;
      letter-spacing: 0;
    }
  }

  > button {
    display: flex;
    align-items: center;
    justify-content: center;

    background: none;
    border: none;
    cursor: pointer;

    transition: all 0.4s;

    &:hover {
      opacity: 0.6;
    }

    svg {
      height: 15px;
      width: 15px;
      stroke-width: 1px;
    }
  }
`;

export const Footer = styled.footer`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  padding: ${({ theme }) => theme.spacing['2xs']};

  > button {
    display: flex;
    align-items: center;
    justify-content: center;

    padding: ${({ theme }) => `0 ${theme.spacing.xs}`};
    height: 24px;
    background: ${({ theme }) => theme.colors.onInfo};
    color: ${({ theme }) => theme.colors.info};
    border-radius: ${({ theme }) => theme.borderRadius.lg};
    border: 1px solid ${({ theme }) => theme.colors.info};
    white-space: nowrap;

    font-size: 13px;
    font-weight: 700;
    line-height: 14px;

    transition: all 0.5s;

    &:hover {
      filter: brightness(0.92);
    }
  }
`;
