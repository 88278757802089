import { IconType } from 'react-icons';

import { useTheme } from 'styled-components';
import { ColorScheme } from 'styled-components';

import { FCWithChildren } from '~/shared/types/FCWithChildren';

import { CustomFieldType } from './styles';
import { Badge } from '../Badge';

interface ICustomOptionButton {
  readonly isSelected: boolean;
  readonly disabled?: boolean;
  readonly height?: number;
  readonly onClick: () => void;
  readonly text?: string;
  readonly soon?: boolean;
  readonly icon?: IconType;
  readonly iconSize?: number;
  readonly iconColor?: ColorScheme;
}

export const CustomOptionButton: FCWithChildren<ICustomOptionButton> = ({
  children,
  isSelected,
  disabled,
  onClick,
  height = 120,
  iconSize = 35,
  iconColor = 'primary',
  soon = false,
  icon: Icon,
  text,
}) => {
  const theme = useTheme();
  return (
    <CustomFieldType isSelected={isSelected} height={height}>
      <button type="button" disabled={disabled} onClick={onClick}>
        {children}
        {text && <span>{text}</span>}
        {Icon && <Icon size={iconSize} color={theme.colors[iconColor]} />}
        {soon && <Badge>Em breve</Badge>}
      </button>
    </CustomFieldType>
  );
};
