import { FC } from 'react';

import { Image } from '@chakra-ui/react';

import { IOfferStepSettings } from '~/modules/offers/interfaces/IOfferStepSettings';
import { IOfferStepButtonEmailSettings } from '~/modules/offers/interfaces/IOfferStepButtonEmailSettings';
import { IOfferStepButtonApiSettings } from '~/modules/offers/interfaces/IOfferStepButtonApiSettings';
import { OfferStepButtonType } from '~/modules/offers/interfaces/IOfferStepButton';
import { IOfferStep } from '~/modules/offers/interfaces/IOfferStep';
import { useOffer } from '~/modules/offers/hooks/offer';

import { Container, LogoContainer } from './styles';
import { Field } from './Field';
import { Button } from '../Button';

interface FormStepProps {
  offerName: string;
  settings: Omit<IOfferStepSettings, 'status'>;
  data: IOfferStep;
  onSubmit(
    type: OfferStepButtonType,
    apiSettings?: IOfferStepButtonApiSettings,
    emailSettings?: IOfferStepButtonEmailSettings,
    link?: string
  ): void;
}

export const FormStep: FC<FormStepProps> = ({
  offerName,
  data,
  settings,
  onSubmit,
}) => {
  const { fieldsValue, setFieldsError } = useOffer();

  async function handleSubmit(
    type: OfferStepButtonType,
    apiSettings?: IOfferStepButtonApiSettings,
    emailSettings?: IOfferStepButtonEmailSettings,
    link?: string
  ): Promise<void> {
    const fieldsWithError = [];

    Object.entries(fieldsValue).forEach(([key, value]) => {
      const findField = data.fields.find((field) => field.name === key);

      if (findField && findField.isRequired && !value) {
        setFieldsError((prevState: any) => ({
          ...prevState,
          [key]: 'Ops, campo obrigatório.',
        }));

        fieldsWithError.push(key);
      }
    });

    if (fieldsWithError.length === 0) {
      onSubmit(type, apiSettings, emailSettings, link);
    }
  }

  return (
    <Container settings={settings}>
      <div>
        {settings.logo && (
          <LogoContainer>
            <Image
              src={settings.logo}
              alt={offerName}
              width={120}
              height={70}
              objectFit="contain"
            />
          </LogoContainer>
        )}

        <h2>{data.title}</h2>

        {data.description && <p>{data.description}</p>}

        {data.fields?.length > 0 &&
          data.fields.map((field) => (
            <Field key={field._id} settings={settings} data={field} />
          ))}
      </div>

      {data.buttons.length > 0 && (
        <footer>
          {data.buttons.map((button) => (
            <Button
              key={button._id}
              data={button}
              onClick={() =>
                handleSubmit(
                  button.type,
                  button.apiSettings,
                  button.emailSettings,
                  button.link
                )
              }
            />
          ))}
        </footer>
      )}
    </Container>
  );
};
