import { maskDocument } from '~/shared/utils/masks';

import { ICompany } from '../interfaces/ICompany';
import { CompanyFormattedTypeEnum } from '../enums/CompanyTypeEnum';

export function formatCompany(company: ICompany): ICompany {
  const formattedDocument = maskDocument(company.document);
  const formattedType = CompanyFormattedTypeEnum[company.type];

  return {
    ...company,
    formattedDocument,
    formattedType,
  };
}
