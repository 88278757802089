export const theme = {
  colors: {
    primary: '#222222',
    onPrimary: '#FFFFFF',
    secondary: '#222222',
    onSecondary: '#FFFFFF',
    terciary: '#F1AE2F',
    onTerciary: '#FFFFFF',
    background: '#EFE9E5',
    onBackground: '#231D19',
    surface: '#FFFFFF',
    onSurface: '#231D19',
    disabledBackground: '#ECECEC',
    onDisabledBackground: '#999999',
    error: '#F5365C',
    onError: '#FFFFFF',
    warning: '#fb6340',
    onWarning: '#231D19',
    success: '#36962e',
    onSuccess: '#231D19',
    info: '#01739D',
    onInfo: '#FFFFFF',
    backdrop: 'rgba(0, 0, 0, 0.35)',
    lightGray: '#E9E9E9',
    darkGray: '#999999',
    gray: '#9f9f9f',
    transparent: '',
    yellow: '#F1AE2F',
    onYellow: '#231D19',
  },
  boxShadow: {
    sm: '3px 3px 15px #00000015',
  },
  borderRadius: {
    sm: '5px',
    md: '8px',
    lg: '12px',
    xlg: '25px',
    none: '0',
  },
  spacing: {
    xxs: '5px',
    xs: '12px',
    sm: '16px',
    md: '30px',
    xmd: '35px',
    lg: '40px',
    xl: '80px',
    '2xs': '20px',
  },
};
