import { FC, useCallback, useEffect, useState } from 'react';

import { IoApps, IoClose } from 'react-icons/io5';

import { useRouter } from 'next/router';

import { ScaleFade, Tooltip } from '@chakra-ui/react';

import { useEventListener } from '~/shared/hooks/eventListener';
import { useClickOutside } from '~/shared/hooks/clickOutside';
import { ReferenceIdEnum } from '~/shared/enums/ReferenceIdEnum';
import { Alert } from '~/shared/components/Alert';

import {
  AlertWrapper,
  Container,
  PopoverArrow,
  PopoverContainer,
  PopoverContent,
} from './styles';
import { FavoriteApp } from './FavoriteApp';
import { useFavoriteApps } from '../../hooks/favoriteApps';
import { ToolbarIconButton } from '../../../../shared/components/Toolbar/ToolbarIconButton';

export const FavoriteApps: FC = () => {
  const router = useRouter();

  const { favoriteApps } = useFavoriteApps();

  const [isOpen, setIsOpen] = useState(false);

  const closePopover = useCallback(() => {
    setIsOpen(false);
  }, []);

  useEffect(() => {
    router.events.on('routeChangeStart', closePopover);

    return () => {
      router.events.off('routeChangeStart', closePopover);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router]);

  const popoverRef = useClickOutside<HTMLLIElement>(closePopover);

  useEventListener('keydown', closePopover);

  function tooglePopperIsOpen(): void {
    setIsOpen((prevState) => !prevState);
  }

  return (
    <Container ref={popoverRef} id={ReferenceIdEnum['favorite-apps']}>
      <ToolbarIconButton
        aria-label="Integrações favoritas"
        tooltip="Integrações favoritas"
        iconSize={20}
        icon={IoApps}
        onClick={tooglePopperIsOpen}
      />

      <ScaleFade in={isOpen} initialScale={0.9} unmountOnExit>
        <PopoverContainer>
          <PopoverArrow />

          <PopoverContent>
            <header>
              <h3>Integrações favoritas</h3>

              <Tooltip label="Fechar" hasArrow closeOnClick={false}>
                <button type="button" onClick={closePopover}>
                  <IoClose />
                </button>
              </Tooltip>
            </header>

            {favoriteApps?.length === 0 && (
              <AlertWrapper>
                <Alert type="info">
                  Você não marcou nenhuma integração como favorita.
                </Alert>
              </AlertWrapper>
            )}

            {favoriteApps?.length > 0 && (
              <ul>
                {favoriteApps.map((favoriteApp) => (
                  <FavoriteApp
                    key={favoriteApp._id}
                    name={favoriteApp.app?.name}
                    image={favoriteApp.app?.bannerLogo}
                    backgroundColor={favoriteApp.app?.backgroundColor}
                    tag={favoriteApp.app?.tag}
                  />
                ))}
              </ul>
            )}
          </PopoverContent>
        </PopoverContainer>
      </ScaleFade>
    </Container>
  );
};
