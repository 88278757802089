import { FC, useCallback, useEffect, useState } from 'react';

import { MdNotifications } from 'react-icons/md';

import { ScaleFade, Fade } from '@chakra-ui/react';

import { useClickOutside } from '~/shared/hooks/clickOutside';
import { ReferenceIdEnum } from '~/shared/enums/ReferenceIdEnum';

import {
  Container,
  PopoverContainer,
  PopoverArrow,
  PopoverHeader,
  PopoverContent,
} from './styles';
import { Notification } from './Notification';
import { useNotifications } from '../../hooks/notifications';
import { ToolbarIconButton } from '../../../../shared/components/Toolbar/ToolbarIconButton';

export const Notifications: FC = () => {
  const { notifications, readAll, totalCount } = useNotifications();

  const [isOpen, setIsOpen] = useState(false);

  const closePopover = useCallback(() => {
    setIsOpen(false);
  }, []);

  const popoverRef = useClickOutside<HTMLLIElement>(closePopover);

  useEffect(() => {
    document.addEventListener('keydown', closePopover, false);

    return () => {
      document.removeEventListener('keydown', closePopover, false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function tooglePopperIsOpen(): void {
    setIsOpen((prevState) => !prevState);
  }

  return (
    <Container ref={popoverRef} id={ReferenceIdEnum['notifications-item']}>
      <ToolbarIconButton
        tooltip="Notificações"
        aria-label="Notificações"
        icon={MdNotifications}
        badge={totalCount}
        iconSize={24}
        onClick={tooglePopperIsOpen}
      />

      <ScaleFade in={isOpen} initialScale={0.9} unmountOnExit>
        <PopoverContainer>
          <PopoverArrow />

          <div>
            <PopoverHeader>
              <div>
                <span>
                  você tem <b>{totalCount || 0}</b>{' '}
                  {totalCount === 1
                    ? 'nova notificação'
                    : 'novas notificações'}
                </span>
              </div>

              <Fade in={totalCount > 1} unmountOnExit>
                <button type="button" onClick={readAll}>
                  limpar todas
                </button>
              </Fade>
            </PopoverHeader>

            {totalCount > 0 && (
              <PopoverContent>
                {notifications.map((notification) => (
                  <Notification
                    key={notification._id}
                    onClose={closePopover}
                    notification={notification}
                  />
                ))}
              </PopoverContent>
            )}
          </div>
        </PopoverContainer>
      </ScaleFade>
    </Container>
  );
};
