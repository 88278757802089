import { FC, useCallback, useRef } from 'react';
import { useEffect } from 'react';

import { Fade } from '@chakra-ui/react';

import { useSettings } from '~/shared/hooks/settings';

import { CharacterImage } from './styles';
import { useOnboarding } from '../../hooks/onboarding';

interface WelcomeCharacterProps {
  isVisible: boolean;
}

export const WelcomeCharacter: FC<WelcomeCharacterProps> = ({ isVisible }) => {
  const { step } = useOnboarding();
  const { settings } = useSettings();
  const { welcome: welcomeCharacter } = settings?.theme?.characters || {};

  const characterRef = useRef<HTMLImageElement>(null);

  const hasUpdated = useRef<boolean>(false);

  useEffect(() => {
    hasUpdated.current = false;
  }, [step]);

  const calculateHeight = useCallback(() => {
    if (!isVisible) return;

    const welcomePopover = document.getElementById('welcome-popover');

    if (welcomePopover && welcomePopover) {
      hasUpdated.current = true;

      const characterWidth = characterRef.current.getBoundingClientRect().width;

      const {
        bottom: welcomePopoverBottom,
        left: welcomePopoverLeft,
        width: welcomePopoverWidth,
      } = welcomePopover.getBoundingClientRect();
      const windowHeight = window.innerHeight;

      const newCharacterHeight = windowHeight - welcomePopoverBottom + 20;

      const newCharacterLeft =
        welcomePopoverLeft + welcomePopoverWidth / 2 - characterWidth / 2;

      characterRef.current.style.height = `${newCharacterHeight}px`;
      characterRef.current.style.left = `${newCharacterLeft + 100}px`;
    }
  }, [isVisible]);

  useEffect(() => {
    if (!isVisible || hasUpdated.current) return () => null;

    const interval = setInterval(() => {
      calculateHeight();
    }, 10);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.addEventListener('resize', calculateHeight);

    return () => {
      window.removeEventListener('resize', calculateHeight);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fade in={step === 0}>
      <CharacterImage ref={characterRef} src={welcomeCharacter} alt="..." />
    </Fade>
  );
};
