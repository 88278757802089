/* eslint-disable import-helpers/order-imports */
import { FC, useMemo, useState } from 'react';

import { IoEyeOff, IoEye } from 'react-icons/io5';

import { useTheme } from 'styled-components';
import { LabelText } from '~/shared/components/Form/LabelText';

import { useBalance } from '~/shared/hooks/balance';

import { formatCurrency } from '~/shared/utils/formatCurrency';
import { Container, LeftContent, RightContent } from './styles';

export const BalanceInfo: FC = () => {
  const { balance } = useBalance();
  const [balanceVisible, setBalanceVisible] = useState<boolean>(false);
  const theme = useTheme();

  const hideBalance = (): void => {
    setBalanceVisible(false);
  };

  const showBalance = (): void => {
    setBalanceVisible(true);
  };

  const balanceDisplay = useMemo(
    () => (balanceVisible ? formatCurrency(balance) : 'R$****'),
    [balanceVisible, balance]
  );

  return (
    <Container>
      <LeftContent>
        {balanceVisible ? (
          <IoEye
            style={{ width: '15px', height: '15px' }}
            color={theme.colors.surface}
            onClick={hideBalance}
          />
        ) : (
          <IoEyeOff
            style={{ width: '15px', height: '15px' }}
            color={theme.colors.surface}
            onClick={showBalance}
          />
        )}
        <LabelText color="surface" fontSize={13}>
          Saldo:
        </LabelText>
      </LeftContent>
      <RightContent>
        <LabelText fontSize={13} fontWeight={700}>
          {balanceDisplay}
        </LabelText>
      </RightContent>
    </Container>
  );
};
