import styled from 'styled-components';

export const HelpDropdownContainer = styled.li`
  position: relative;

  > div {
    position: absolute;
    bottom: -12px;

    @media (max-width: 750px) {
      width: 100vw;
    }

    @media (min-width: 750px) {
      width: 500px;
      right: -${({ theme }) => theme.spacing.sm};
    }
  }
`;

export const PopoverContainer = styled.div`
  @media (max-width: 750px) {
    position: fixed;
    top: 0;
    right: 0;
  }

  @media (min-width: 750px) {
    position: absolute;
  }

  box-shadow: 3px 3px 15px #00000020;
  width: 100%;
  z-index: 25;

  > div {
    border-radius: ${({ theme }) => theme.borderRadius.sm};
    background: ${({ theme }) => theme.colors.surface};
    width: 100%;
    overflow: hidden;
  }
`;

export const PopoverArrow = styled.span`
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid ${({ theme }) => theme.colors.surface};
  top: -7px;
  position: absolute;
  right: ${({ theme }) => `calc(${theme.spacing.sm} + 6px)`};
`;

export const PopoverHeader = styled.header`
  padding: ${({ theme }) => theme.spacing['2xs']};
  padding-bottom: unset;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  > span {
    font-weight: bold;
  }
`;

export const PopoverContent = styled.div`
  padding: ${({ theme }) => theme.spacing['2xs']};
  display: flex;
  flex-direction: column;

  button {
    flex: 1;
    gap: ${({ theme }) => theme.spacing.xxs};
  }

  > hr {
    margin-bottom: ${({ theme }) => theme.spacing['2xs']};
  }
`;

export const HelpTypesRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: ${({ theme }) => theme.spacing.xs};
  gap: ${({ theme }) => theme.spacing.xs};
`;

export const DocumentAnnexWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;

  > span {
    cursor: pointer;
    margin-left: ${({ theme }) => theme.spacing.xxs};
    color: ${({ theme }) => theme.colors.primary};
    text-decoration: underline;
  }
`;
