import { CSSProperties, ThemeSpacing } from 'styled-components';
import { FCWithChildren } from '~/shared/types/FCWithChildren';

import { Container } from './styles';

interface FromGroupProps {
  spacing?: ThemeSpacing;
  minWidth?: string;
  borderTop?: boolean;
  style?: CSSProperties;
}

export const FormGroup: FCWithChildren<FromGroupProps> = ({
  children,
  minWidth = '200px',
  spacing,
  borderTop,
  style,
}) => {
  return (
    <Container
      style={style}
      spacing={spacing}
      minWidth={minWidth}
      borderTop={borderTop}
      id="form-group"
    >
      {children}
    </Container>
  );
};
