import {
  createContext,
  useContext,
  useMemo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import useSWR from 'swr';
import io from 'socket.io-client';
import { useToast } from '@chakra-ui/react';

import { getSegmentFromCookies } from '~/shared/utils/getSegmentFromCookies';
import { FCWithChildren } from '~/shared/types/FCWithChildren';
import { api } from '~/shared/services/api';
import { IPagination } from '~/shared/interfaces/IPagination';
import { env } from '~/shared/constants/env';

import { INotification } from '../interfaces/INotifications';

interface INotificationsContextData {
  notifications: INotification[];
  totalCount: number;
  read(id: string): void;
  readAll(): void;
}

const NotificationsContext = createContext({} as INotificationsContextData);

const socket = io(`${env.API_URI}/notifications`, {
  transports: ['websocket'],
});

const NotificationsProvider: FCWithChildren = ({ children }) => {
  const segment = getSegmentFromCookies();
  const toast = useToast({
    isClosable: true,
    status: 'error',
    position: 'top-right',
  });

  const { data, mutate } = useSWR<IPagination<INotification>>(
    `/segments/${segment}/notifications?read=false&limit=20&page=1`
  );

  const notifications = useMemo(() => data?.data || [], [data]);

  const totalCount = useMemo(() => data?.totalCount || 0, [data]);

  const newNotificationsListener = (notification: INotification): void => {
    mutate((old) => {
      return {
        ...old,
        data: [notification, ...old.data],
        totalCount: old.totalCount + 1,
      };
    }, false);

    new Audio('/notification.mp3').play().catch(() => null);
  };

  const hasAlreadyVerified = useRef<boolean>(false);

  useEffect(() => {
    if (hasAlreadyVerified.current) {
      return;
    }

    hasAlreadyVerified.current = true;

    socket.on(`new-notification-${segment}`, newNotificationsListener);

    return () => {
      socket.off(`new-notification-${segment}`, newNotificationsListener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket]);

  const read = useCallback(
    (id: string) => {
      let oldData: IPagination<INotification>;

      mutate((old) => {
        if (old) oldData = old;

        return {
          ...old,
          data: old?.data?.filter((item) => item._id !== id),
          totalCount: old?.totalCount - 1,
        };
      }, false);

      mutate(async (old) => {
        try {
          await api.patch(`/segments/${segment}/notifications/${id}/read`);

          return old;
        } catch (error) {
          toast({
            title: 'Ops, ocorreu um erro ao limpar a notificação!',
            description: 'Recarregue a página e tente novamente.',
          });

          return oldData;
        }
      }, oldData?.data?.length === 1);
    },
    [mutate, segment]
  );

  const readAll = useCallback(() => {
    let oldData: IPagination<INotification>;

    mutate((old) => {
      if (old) oldData = old;

      return {
        data: [],
        totalCount: 0,
        page: 1,
        limit: 20,
        pagesCount: 0,
      };
    }, false);

    mutate(async () => {
      try {
        await api.patch(`/segments/${segment}/notifications/read-all`);

        return {
          data: [],
          totalCount: 0,
          page: 1,
          limit: 20,
          pagesCount: 0,
        };
      } catch (error) {
        toast({
          title: 'Ops, ocorreu um erro ao limpar todas as notificações!',
          description: 'Recarregue a página e tente novamente.',
        });

        return oldData;
      }
    });
  }, [mutate, segment]);

  return (
    <NotificationsContext.Provider
      value={{ notifications, readAll, read, totalCount }}
    >
      {children}
    </NotificationsContext.Provider>
  );
};

const useNotifications = (): INotificationsContextData => {
  return useContext(NotificationsContext);
};

export { NotificationsProvider, useNotifications };
