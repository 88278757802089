import { createContext, useContext } from 'react';

import { ThemeProvider } from 'styled-components';

import { FCWithChildren } from '../types/FCWithChildren';
import { theme } from '../styles/theme';
import { ISettings } from '../interfaces/ISettings';
import { SEO } from '../components/SEO';

interface ISettingsContextData {
  settings: ISettings;
}

const SettingsContext = createContext({} as ISettingsContextData);

interface SettingsProviderProps {
  settings: ISettings;
}

const SettingsProvider: FCWithChildren<SettingsProviderProps> = ({
  children,
  settings = {} as ISettings,
}) => {
  return (
    <ThemeProvider
      theme={{
        ...theme,
        colors: { ...theme.colors, ...(settings?.theme?.colors || {}) },
      }}
    >
      <SettingsContext.Provider value={{ settings }}>
        <SEO />

        {children}
      </SettingsContext.Provider>
    </ThemeProvider>
  );
};

const useSettings = (): ISettingsContextData => {
  return useContext(SettingsContext);
};

export { SettingsProvider, useSettings };
