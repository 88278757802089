import styled from 'styled-components';

export const Container = styled.li`
  > a {
    width: 55px;

    > span {
      display: block;

      height: 0;
      max-width: 55px;
      min-height: 22px;
      overflow: hidden;

      transition: height 0.4s;
      position: relative;

      > p {
        font-size: 13px;
        color: ${({ theme }) => theme.colors.onSurface};
        margin-top: ${({ theme }) => theme.spacing.xxs};
        text-align: center;
        line-height: 17px;

        .isInvisble {
          display: none;
          position: absolute;
        }
      }
    }
  }
`;
