import { FC, MouseEvent, useRef, useState } from 'react';

import { IoClose } from 'react-icons/io5';
import ReactDOM from 'react-dom';

import { Tooltip, useToast } from '@chakra-ui/react';

import { useCompanies } from '~/modules/companies/hooks/companies';
import { useAuth } from '~/modules/auth/hooks/auth';

import { getSegmentFromCookies } from '~/shared/utils/getSegmentFromCookies';
import { api } from '~/shared/services/api';
import { useIsMounted } from '~/shared/hooks/isMounted';
import { useConfirmationModal } from '~/shared/hooks/confirmationModal';
import { Spinner } from '~/shared/components/Spinner';

import { LoaderWrapper, Backdrop, Content } from './styles';
import { FormStep } from './FormStep';
import { DefaultStep } from './DefaultStep';
import { IOfferStepButtonEmailSettings } from '../../interfaces/IOfferStepButtonEmailSettings';
import { IOfferStepButtonApiSettings } from '../../interfaces/IOfferStepButtonApiSettings';
import { OfferStepButtonType } from '../../interfaces/IOfferStepButton';
import { useOffer } from '../../hooks/offer';

export interface IProps {
  onClose: (event?: MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export const OfferModal: FC<IProps> = ({ onClose }) => {
  const segment = getSegmentFromCookies();

  const toast = useToast({
    isClosable: true,
    status: 'error',
    position: 'top-right',
  });

  const { user } = useAuth();
  const { companies } = useCompanies();
  const { isLoading, offer, fieldsValue } = useOffer();
  const { open: openConfirmationModal } = useConfirmationModal();
  const isMounted = useIsMounted();

  const backdropRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  const [currentStepIndex, setCurrentStepIndex] = useState(0);

  const currentSettings =
    offer?.steps?.[currentStepIndex]?.settings?.status === 'active'
      ? offer?.steps[currentStepIndex].settings
      : offer?.stepsSettings;

  function close(): void {
    const modalContent = contentRef.current;
    const modalBackdrop = backdropRef.current;

    if (modalContent && modalBackdrop) {
      modalContent.style.transform = 'translateY(1000px)';
      modalBackdrop.style.opacity = '0';
    }

    setTimeout(() => {
      onClose && onClose();
    }, 300);
  }

  function handleClose(event: any): void {
    if (!event.target.dataset.canclose) return;

    const hasValue = Object.values(fieldsValue).some((item) => item);

    if ((currentStepIndex > 0 && hasValue) || hasValue) {
      openConfirmationModal({
        content: 'deseja realmente fechar a oferta?',
        onConfirm: () => close(),
      });
    } else {
      close();
    }
  }

  async function handleSubmit(
    type: OfferStepButtonType,
    apiSettings?: IOfferStepButtonApiSettings,
    emailSettings?: IOfferStepButtonEmailSettings,
    link?: string
  ): Promise<void> {
    if (type === 'increase-step') {
      setCurrentStepIndex((prevState) =>
        offer!.steps.length - 1 === prevState ? prevState : prevState + 1
      );
      return;
    }

    if (type === 'decrease-step') {
      setCurrentStepIndex((prevState) =>
        prevState === 0 ? prevState : prevState - 1
      );
      return;
    }

    if (type.includes('submit')) {
      try {
        const request = {
          user: {
            name: user.name,
            phoneNumber: user.phoneNumber,
            email: user.email,
          },
          offer: offer?._id,
          company: companies?.[0]?._id,
          offerName: offer?.name,
          segment,
        };

        if (apiSettings && type.includes('submit-with-api')) {
          let schemaString = JSON.stringify(apiSettings?.schema);

          Object.entries(fieldsValue || {})?.forEach(([key, value]) => {
            let newKey = `[${key}]`;

            if (typeof value === 'boolean') {
              newKey = `"[${key}]"`;
            }

            if (schemaString) {
              schemaString = schemaString.replaceAll(newKey, String(value));
            }
          });

          Object.assign(request, {
            type: 'api',
            apiSchema: JSON.parse(schemaString),
            apiUri: apiSettings.url,
            apiMethod: apiSettings.method,
            apiHeaders: apiSettings.headers,
          });
        }

        if (emailSettings && type.includes('submit-with-email')) {
          let templateString = emailSettings.template;

          Object.entries(fieldsValue || {})?.forEach(([key, value]) => {
            let newKey = `[${key}]`;

            if (typeof value === 'boolean') {
              newKey = `"[${key}]"`;
            }

            if (templateString) {
              templateString = templateString.replaceAll(newKey, String(value));
            }
          });

          Object.assign(request, {
            type: 'email',
            emailTemplate: templateString,
          });
        }

        await api.post('/offer-purchases', request);

        if (!!link && type.includes('submit-with-link')) {
          window.open(link);
        }

        if (type.includes('increase-step')) {
          setCurrentStepIndex((prevState) => {
            if (offer!.steps.length - 1 === prevState) {
              close();
              return prevState;
            }

            return prevState + 1;
          });
        } else {
          close();
        }
      } catch (error) {
        toast({
          title: 'Ops, ocorreu um erro ao finalizar a etapa!',
          description: 'Recarregue a página e tente novamente.',
        });
      }
    }
  }

  if (!isMounted) return null;

  return ReactDOM.createPortal(
    <Backdrop ref={backdropRef} data-canclose onClick={(ev) => handleClose(ev)}>
      <Content ref={contentRef} settings={currentSettings}>
        <div>
          <Tooltip label="Fechar" hasArrow>
            <button type="button" onClick={handleClose} data-canclose>
              <IoClose data-canclose />
            </button>
          </Tooltip>

          <div>
            {isLoading ? (
              <LoaderWrapper>
                <Spinner />
              </LoaderWrapper>
            ) : (
              offer?.steps[currentStepIndex] &&
              currentSettings &&
              (offer?.steps[currentStepIndex].type === 'form' ? (
                <FormStep
                  data={offer?.steps[currentStepIndex]}
                  offerName={offer.name}
                  settings={currentSettings}
                  onSubmit={(...props) => handleSubmit(...props)}
                />
              ) : (
                <DefaultStep
                  data={offer?.steps[currentStepIndex]}
                  offerName={offer.name}
                  settings={currentSettings}
                  onSubmit={(...props) => handleSubmit(...props)}
                />
              ))
            )}
          </div>
        </div>
      </Content>
    </Backdrop>,
    document.getElementById('modal-root')
  );
};
