import { useCallback, useEffect, useRef, useState } from 'react';

import { useRouter } from 'next/router';

import { useOnboarding } from '~/modules/onboarding/hooks/onboarding';
import { useAuth } from '~/modules/auth/hooks/auth';

import { getSegmentFromCookies } from '~/shared/utils/getSegmentFromCookies';
import { FCWithChildren } from '~/shared/types/FCWithChildren';
import { api } from '~/shared/services/api';
import { IMenuJourneyData } from '~/shared/interfaces/IMenuJourneyData';
import { useVideoModal } from '~/shared/hooks/videoModal';
import { useDynamicSidebar } from '~/shared/hooks/dynamicSidebar';
import { SidebarPagesEnum } from '~/shared/enums/SidebarPagesEnum';
import { ReferenceIdEnum } from '~/shared/enums/ReferenceIdEnum';
import { SidebarSelectedStep } from '~/shared/components/SidebarSelectedStep';
import { Overflow } from '~/shared/components/Backdrop/styles';
import { Backdrop } from '~/shared/components/Backdrop';

import { FirstTimePopover } from '../FirstTimePopover';
import { CoursesWrapper } from '../CoursesWrapper';
import { IPageJourneyData } from '../../interfaces/IPageJourneyData';

const pageRoutes: { [key: string]: IPageJourneyData } = {
  '/users': {
    id: SidebarPagesEnum.LIST_USERS,
    reference: ReferenceIdEnum.users,
  },
  '/companies': {
    id: SidebarPagesEnum.LIST_COMPANIES,
    reference: ReferenceIdEnum.companies,
  },
  '/users/groups': {
    id: SidebarPagesEnum.USER_GROUPS,
    reference: ReferenceIdEnum['user-groups'],
  },
  '/marketplaces-management': {
    id: SidebarPagesEnum.LIST_MARKETPLACE_MANAGEMENT,
    reference: ReferenceIdEnum['marketplaces-management'],
  },
  '/orders': {
    id: SidebarPagesEnum.LIST_ORDERS,
    reference: ReferenceIdEnum.orders,
  },
  '/orders-posts': {
    id: SidebarPagesEnum.POST_ORDERS,
    reference: ReferenceIdEnum['orders-posts'],
  },
  '/cashiers': {
    id: SidebarPagesEnum.LIST_CASHIERS,
    reference: ReferenceIdEnum.cashiers,
  },
  '/customers': {
    id: SidebarPagesEnum.LIST_CUSTOMERS,
    reference: ReferenceIdEnum.customers,
  },
  '/sales/reports': {
    id: SidebarPagesEnum.ORDERS_REPORT,
    reference: ReferenceIdEnum['orders-reports'],
  },
  '/products': {
    id: SidebarPagesEnum.LIST_PRODUCTS,
    reference: ReferenceIdEnum.products,
  },
  '/categories': {
    id: SidebarPagesEnum.LIST_CATEGORIES,
    reference: ReferenceIdEnum.categories,
  },
  '/brands': {
    id: SidebarPagesEnum.LIST_BRANDS,
    reference: ReferenceIdEnum.brands,
  },
  '/products/environments': {
    id: SidebarPagesEnum.LIST_ENVIRONMENTS,
    reference: ReferenceIdEnum['product-environments'],
  },
  '/taxation-rules': {
    id: SidebarPagesEnum.LIST_TAXATION_RULES,
    reference: ReferenceIdEnum['taxation-rules'],
  },
  '/products/reports': {
    id: SidebarPagesEnum.PRODUCTS_REPORTS,
    reference: ReferenceIdEnum['products-reports'],
  },
  '/stock-locals': {
    id: SidebarPagesEnum.LIST_STOCKLOCALS,
    reference: ReferenceIdEnum['stock-locals'],
  },
  '/stock-adjustments': {
    id: SidebarPagesEnum.LIST_STOCK_ADJUSTMENTS,
    reference: ReferenceIdEnum['stock-adjustment'],
  },
  '/stock-monitoring': {
    id: SidebarPagesEnum.LIST_STOCK_MONITORING,
    reference: ReferenceIdEnum['stock-monitoring'],
  },
  '/stock-transfer-groups': {
    id: SidebarPagesEnum.LIST_STOCK_TRANSFER,
    reference: ReferenceIdEnum['stock-transfer-group'],
  },
  '/stock-locals/reports': {
    id: SidebarPagesEnum.STOCKLOCALS_REPORTS,
    reference: ReferenceIdEnum['stock-reports'],
  },
  '/apps': {
    id: SidebarPagesEnum.LIST_INTEGRATIONS,
    reference: ReferenceIdEnum.integrations,
  },
  '/events': {
    id: SidebarPagesEnum.LIST_EVENTS,
    reference: ReferenceIdEnum.events,
  },
  '/file-transmissions': {
    id: SidebarPagesEnum.FILE_TRANSMISSIONS,
    reference: ReferenceIdEnum['file-transmissions'],
  },
  '/devices': {
    id: SidebarPagesEnum.LIST_DEVICES,
    reference: ReferenceIdEnum.devices,
  },
  '/abandoned-carts': {
    id: SidebarPagesEnum.ABANDONED_CARTS,
    reference: ReferenceIdEnum['abandoned-carts'],
  },
  '/promotions': {
    id: SidebarPagesEnum.LIST_PROMOTIONS,
    reference: ReferenceIdEnum.promotions,
  },
};

type PageDynamicData = { label: string; videoLink: string };

export const PageJourneyProvider: FCWithChildren = ({ children }) => {
  const { isVisible: isOnboardingVisible } = useOnboarding();
  const { sidebarData } = useDynamicSidebar();
  const backdropRef = useRef<HTMLDivElement>(null);
  const [visible, setVisible] = useState<boolean>(false);
  const [pageData, setPageData] = useState<
    IPageJourneyData & { videoLink: string; menuJourneyData: IMenuJourneyData }
  >();
  const {
    user,
    addLocalUserScreenConfirmation,
    addLocalUserConfirmedSecondStepJourney,
  } = useAuth();
  const { route } = useRouter();
  const [characterStep, setCharacterStep] = useState<1 | 2>(1);
  const { open } = useVideoModal();
  const screenRefId = useRef<string>(null);

  const confirmPageJourney = useCallback(async () => {
    if (screenRefId.current) {
      const segment = getSegmentFromCookies();
      await api
        .post(`/segments/${segment}/users/screens-confirmations`, {
          screenId: screenRefId.current,
        })
        .then(() => {
          addLocalUserScreenConfirmation(screenRefId.current);
        });
    }
  }, [addLocalUserScreenConfirmation]);

  const resetStep = useCallback(() => {
    setTimeout(() => {
      setCharacterStep(1);
    }, 500);
  }, []);

  const closeBackdrop = (): void => {
    if (backdropRef.current) {
      backdropRef.current.style.opacity = '0';
      setTimeout(() => {
        setVisible(false);
      }, 300);
    }
  };

  const saveAndClose = useCallback(async () => {
    await confirmPageJourney().then(closeBackdrop).then(resetStep);
  }, [confirmPageJourney, resetStep]);

  const onNotNow = useCallback(async () => {
    const { hasConfirmedPageJourneySecondStep } = user;
    if (!hasConfirmedPageJourneySecondStep) {
      setCharacterStep(2);
    } else saveAndClose().then(addLocalUserConfirmedSecondStepJourney);
  }, [addLocalUserConfirmedSecondStepJourney, saveAndClose, user]);

  const onGoBack = useCallback(() => {
    setCharacterStep(1);
  }, []);

  const onConfirm = useCallback(() => {
    saveAndClose();
    open(pageData.videoLink);
  }, [open, pageData?.videoLink, saveAndClose]);

  const getPageDynamicDataById = useCallback(
    (pageId: string): PageDynamicData | undefined => {
      if (!user || !sidebarData) {
        return undefined;
      }

      let link: string;
      let pageLabel: string;

      sidebarData.forEach((group) => {
        if (!link) {
          group.items.forEach((page) => {
            if (page._id === pageId) {
              const { videoLink, customDescription, defaultDescription } = page;
              link = videoLink;
              pageLabel = customDescription || defaultDescription;
            }
          });
        } else return null;
      });

      return { videoLink: link, label: pageLabel };
    },
    [sidebarData, user]
  );

  const callJourney = useCallback(
    (data: IPageJourneyData) => {
      const { id } = data;
      const currentScreensConfirmed = user.screensJourneyConfirmations || [];
      if (!currentScreensConfirmed?.includes(id)) {
        const { videoLink, label } = getPageDynamicDataById(id);
        if (videoLink) {
          const { menuJourneyData } = user;

          if (menuJourneyData) {
            setPageData({ ...data, videoLink, label, menuJourneyData });
            screenRefId.current = id;
            setVisible(true);
          }
        }
      }
    },
    [getPageDynamicDataById, user]
  );

  useEffect(() => {
    const currentRouteProps = pageRoutes[route];
    if (currentRouteProps && user && sidebarData) {
      callJourney(currentRouteProps);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [route, user, sidebarData]);

  return (
    <>
      {visible && !isOnboardingVisible && (
        <>
          <Overflow>
            <SidebarSelectedStep referenceId={pageData.reference} ignoreStep />
            <CoursesWrapper visible={characterStep === 2} />
          </Overflow>

          <Backdrop ref={backdropRef}>
            <FirstTimePopover
              data={pageData}
              characterStep={characterStep}
              onNotNow={onNotNow}
              onConfirm={onConfirm}
              onFinish={saveAndClose}
              onGoBack={onGoBack}
            />
          </Backdrop>
        </>
      )}

      {children}
    </>
  );
};
