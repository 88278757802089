import { createContext, useCallback, useContext, useEffect } from 'react';

import { useRouter } from 'next/router';

import { useSettings } from './settings';
import { useOrigin } from './origin';
import { FCWithChildren } from '../types/FCWithChildren';

type TTrackEvent =
  | 'AddPaymentInfo'
  | 'AddToCart'
  | 'AddToWishlist'
  | 'CompleteRegistration'
  | 'Contact'
  | 'CustomizeProduct'
  | 'Donate'
  | 'FindLocation'
  | 'InitiateCheckout'
  | 'Lead'
  | 'Purchase'
  | 'Schedule'
  | 'Search'
  | 'StartTrial'
  | 'SubmitApplication'
  | 'Subscribe'
  | 'ViewContent';
interface IFacebookPixelContextData {
  init(): void;
  track(event: TTrackEvent, params?: Record<string, any>): void;
}

const FacebookPixelContext = createContext<IFacebookPixelContextData>(
  {} as IFacebookPixelContextData
);

const FacebookPixelProvider: FCWithChildren = ({ children }) => {
  const { events } = useRouter();
  const { settings } = useSettings();
  const { origin } = useOrigin();

  const init = useCallback(() => {
    if (origin) {
      const findFbPixelConfig = settings.facebookPixelConfigurations?.find(
        (config) => config.origin === origin
      );

      if (!findFbPixelConfig) {
        return () => null;
      }

      import('react-facebook-pixel')
        .then((x) => x.default)
        .then((ReactPixel) => {
          ReactPixel.init(findFbPixelConfig.pixelId, undefined, {
            autoConfig: true,
            debug: false,
          });

          ReactPixel.pageView();
        });
    }
  }, [origin, settings?.facebookPixelConfigurations]);

  const track = useCallback(
    (event: TTrackEvent, params?: Record<string, any>) => {
      const findFbPixelConfig = settings.facebookPixelConfigurations?.find(
        (config) => config.origin === origin
      );

      if (!findFbPixelConfig) return;

      import('react-facebook-pixel')
        .then((x) => x.default)
        .then((ReactPixel) => {
          ReactPixel.init(findFbPixelConfig.pixelId, undefined, {
            autoConfig: true,
            debug: false,
          });

          ReactPixel.track(event, params);
        });
    },
    [origin, settings?.facebookPixelConfigurations]
  );

  useEffect(() => {
    events.on('routeChangeComplete', init);

    return () => {
      events.off('routeChangeComplete', init);
    };
  }, [events, init]);

  return (
    <FacebookPixelContext.Provider
      value={{
        init,
        track,
      }}
    >
      {children}
    </FacebookPixelContext.Provider>
  );
};

function useFacebookPixel(): IFacebookPixelContextData {
  return useContext(FacebookPixelContext);
}

export { FacebookPixelProvider, useFacebookPixel };
