import styled from 'styled-components';

export const Container = styled.nav`
  flex: 1;

  display: flex;
  align-items: center;
  flex-direction: column;

  footer {
    width: 100%;
    padding: ${({ theme }) => theme.spacing.sm} 0;
  }
`;
