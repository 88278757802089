import { useEffect, FC, useRef, useCallback } from 'react';

import { useSideBarDrawer } from '~/shared/hooks/sideBarDrawer';
import { useGetElementVisibleById } from '~/shared/hooks/getElementVisibleById';

import { Wrapper } from './styles';

type TSidebarSelectedStep = {
  referenceId: string;
  step?: number;
  ignoreStep?: boolean;
};

export const SidebarSelectedStep: FC<TSidebarSelectedStep> = ({
  referenceId,
  step,
  ignoreStep,
}) => {
  const { isOpen } = useSideBarDrawer();
  const { element } = useGetElementVisibleById(referenceId);
  const selectedRef = useRef<HTMLDivElement>(null);

  const calculateOffset = useCallback(() => {
    if (element) {
      const selectedElement = selectedRef.current;
      const bounding = element.getBoundingClientRect();

      if (ignoreStep) {
        selectedElement.style.borderRadius = `0`;
        selectedElement.style.transform = `translateX(${bounding.left}px) translateY(${bounding.top}px)`;
      } else if (step <= 5) {
        selectedElement.style.transform = `translateX(${bounding.left}px) translateY(${bounding.top}px) scale(1.5)`;
        selectedElement.style.borderRadius = `50%`;
      } else {
        selectedElement.style.borderRadius = `0`;
        selectedElement.style.transform = `translateX(${bounding.left}px) translateY(${bounding.top}px)`;
      }
      selectedElement.style.width = `${element.offsetWidth}px`;
      selectedElement.style.height = `${element.offsetHeight}px`;
    }
  }, [element, ignoreStep, step]);

  useEffect(() => {
    calculateOffset();
  }, [calculateOffset, element]);

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        calculateOffset();
      }, 500);
    }
  }, [calculateOffset, isOpen]);

  useEffect(() => {
    const sideBar = document.getElementById('sidebar');
    if (sideBar) {
      sideBar.addEventListener('scroll', calculateOffset);
    }

    return () => {
      if (sideBar) {
        sideBar.removeEventListener('scroll', calculateOffset);
      }
    };
  }, [calculateOffset]);

  return <Wrapper ref={selectedRef} />;
};
