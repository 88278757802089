import styled from 'styled-components';

type TWrapperImage = {
  width?: number;
  height?: number;
};

export const WrapperImage = styled.div<TWrapperImage>`
  width: ${(p) => (p.width ? `${p.width}px` : 'auto')};
  height: ${(p) => (p.height ? `${p.height}px` : 'auto')};
`;

type TStyledImage = {
  objectFit?: 'contain' | 'cover';
  objectPosition?: 'center' | 'top' | 'bottom';
};

export const StyledImage = styled.img<TStyledImage>`
  width: 100%;
  height: 100%;
  object-fit: ${(p) => p.objectFit || 'unset'};
  object-position: ${(p) => p.objectPosition || 'unset'};
`;
