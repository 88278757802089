import styled from 'styled-components';

export const Container = styled.span`
  display: block;
  width: 100%;
  margin-top: 3px;

  min-height: 21px;

  color: ${({ theme }) => theme.colors.error};
  font-size: 12px;
  font-weight: 600;
`;
