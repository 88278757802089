import { useRouter } from 'next/router';

import { parseCookies } from 'nookies';

import { cookies } from '../constants/cookies';

export function useOrigin(): { origin: string } {
  const { [cookies.ORIGIN]: storagedOrigin } = parseCookies();
  const { query } = useRouter();

  let origin = 'pos-facil-site';

  if (query.origin || (storagedOrigin && storagedOrigin !== 'undefined')) {
    origin = (query.origin as string) || JSON.parse(storagedOrigin);
  }

  return { origin };
}
