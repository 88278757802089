import styled from 'styled-components';

export const CoursesDropdownContainer = styled.li`
  position: relative;
  width: 100%;

  > div {
    position: absolute;
    display: inline-block;
    bottom: -12px;

    @media (max-width: 750px) {
      width: 100vw;
    }

    @media (min-width: 750px) {
      width: 600px;
      right: -${({ theme }) => theme.spacing.sm};
    }
  }
`;
