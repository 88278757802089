import styled from 'styled-components';

export const Container = styled.li`
  position: relative;

  > div {
    position: absolute;
    bottom: -12px;
    right: -${({ theme }) => theme.spacing.sm};
    width: 425px;
  }
`;

export const PopoverArrow = styled.span`
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid ${({ theme }) => theme.colors.surface};
  top: -7px;
  position: absolute;
  right: ${({ theme }) => `calc(${theme.spacing.sm} + 6px)`};
`;

export const PopoverContainer = styled.div`
  @media (max-width: 750px) {
    position: fixed;
    top: 0;
    right: 0;
  }

  @media (min-width: 750px) {
    position: absolute;
  }

  box-shadow: 3px 3px 15px #00000020;
  width: 100%;
  z-index: 25;

  > div {
    border-radius: ${({ theme }) => theme.borderRadius.sm};
    background: ${({ theme }) => theme.colors.surface};
    width: 100%;
    overflow: hidden;
  }
`;

export const PopoverHeader = styled.header`
  padding: ${({ theme }) => theme.spacing['2xs']};
  padding-bottom: ${({ theme }) => theme.spacing['2xs']};

  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  > div {
    display: flex;
    align-items: center;
    height: 26px;

    span {
      color: ${({ theme }) => theme.colors.onSurface};
      font-size: 14px;
      font-weight: 700;

      b {
        color: ${({ theme }) => theme.colors.onSurface};
        font-weight: 800;
      }
    }
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 26px;

    padding: ${({ theme }) => `${theme.spacing.xxs} ${theme.spacing.sm}`};
    background: ${({ theme }) => theme.colors.onPrimary};
    color: ${({ theme }) => theme.colors.primary};
    border-radius: ${({ theme }) => theme.borderRadius.lg};
    border: 1px solid ${({ theme }) => theme.colors.primary};
    white-space: nowrap;

    font-size: 12px;
    font-weight: 700;
    line-height: 14px;

    transition: all 0.3s;

    &:hover {
      filter: brightness(0.92);
    }
  }
`;

export const PopoverContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.sm};
  padding: ${({ theme }) =>
    `0 ${theme.spacing['2xs']} ${theme.spacing['2xs']}`};
  overflow: auto;
  max-height: 550px;
`;
