import { FC, useRef } from 'react';

import { IoTrash } from 'react-icons/io5';

import { useRouter } from 'next/router';

import { format } from 'date-fns';
import { Tooltip } from '@chakra-ui/react';

import { INotification } from '~/modules/notifications/interfaces/INotifications';
import { useNotifications } from '~/modules/notifications/hooks/notifications';
import { NotificationTypeEnum } from '~/modules/notifications/enums/NotificationTypeEnum';

import { routes } from '~/shared/constants/routes';

import { Container, Content } from './styles';

interface NotificationProps {
  notification: INotification;
  onClose(): void;
}

export const Notification: FC<NotificationProps> = ({
  notification,
  onClose,
}) => {
  const router = useRouter();

  const { read } = useNotifications();

  const readButtonRef = useRef<HTMLButtonElement>(null);

  function handleOpenNotification(event: any): void {
    if (
      event.target === readButtonRef?.current ||
      readButtonRef?.current.contains(event.target)
    )
      return;

    if (notification.type === NotificationTypeEnum.order) {
      router.push(routes.orders.details(notification.auxId));
    }

    read(notification._id);

    onClose();
  }

  return (
    <Container>
      {/* {image && (
        <ImageContainer>
          <Image
            src={image}
            alt="..."
            width={50}
            height={50}
            objectFit="cover"
          />
        </ImageContainer>
      )} */}

      <Content onClick={handleOpenNotification}>
        <header>
          <h3>
            notificação recebida em{' '}
            {format(new Date(notification.createdAt), "dd/MM/yyyy 'às' HH:mm")}
          </h3>

          <Tooltip hasArrow label="Limpar">
            <button
              type="button"
              onClick={() => read(notification._id)}
              ref={readButtonRef}
            >
              <IoTrash />
            </button>
          </Tooltip>
        </header>

        <p>{notification.content}</p>
      </Content>
    </Container>
  );
};
