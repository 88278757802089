import {
  ForwardRefRenderFunction,
  InputHTMLAttributes,
  useState,
  forwardRef,
  CSSProperties,
  FocusEvent,
  ReactNode,
  useCallback,
  memo,
  useEffect,
} from 'react';

import { IoEyeOff, IoEye } from 'react-icons/io5';
import { IconType } from 'react-icons';

import { ColorScheme } from 'styled-components';
import { Tooltip } from '@chakra-ui/react';

import {
  Container,
  InputContainer,
  StartIconContainer,
  EndIconContainer,
  InputVariant,
  InputSize,
  LoadingContainer,
  EndContent,
  ObservationContainer,
} from './styles';
import { LabelText } from '../LabelText';
import { ErrorMessage } from '../ErrorMessage';
import { Spinner } from '../../Spinner';

export interface InputProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'size' | 'color'> {
  name: string;
  startIcon?: IconType;
  endIcon?: IconType;
  error?: string;
  variant?: InputVariant;
  color?: ColorScheme;
  label?: string;
  containerStyle?: CSSProperties;
  size?: InputSize;
  isLoading?: boolean;
  hasError?: boolean;
  endContent?: ReactNode;
  hint?: ReactNode;
  observation?: string;
  centralize?: boolean;
  width?: string;
  labelColor?: ColorScheme;
  autocomplete?: string;
}

const BaseInput: ForwardRefRenderFunction<HTMLInputElement, InputProps> = (
  {
    name,
    error,
    label,
    type = 'text',
    size = 'md',
    containerStyle,
    startIcon: StartIcon,
    endIcon: EndIcon,
    isLoading = false,
    variant = 'outlined',
    color = 'primary',
    onBlur = () => null,
    width = 'auto',
    onFocus = () => null,
    endContent,
    hasError = false,
    hint,
    centralize = false,
    observation,
    labelColor,
    ...props
  },
  ref
) => {
  const [newType, setNewType] = useState(type);
  const [isPasswordEncrypted, setIsPasswordEncrypted] = useState(true);
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    setNewType(type);
  }, [type]);

  const toggleIsPasswordEncrypted = useCallback(() => {
    setIsPasswordEncrypted((prevState) => {
      const newState = !prevState;

      setNewType(newState ? 'password' : 'text');

      return newState;
    });
  }, []);

  const handleFocusInput = useCallback(
    (event: FocusEvent<HTMLInputElement>) => {
      setIsFocused(true);

      onFocus(event);
    },
    [onFocus]
  );

  const handleBlurInput = useCallback(
    (event: FocusEvent<HTMLInputElement>) => {
      setIsFocused(false);

      onBlur(event);
    },
    [onBlur]
  );

  return (
    <Container id="input" style={containerStyle}>
      <label htmlFor={name} style={{ width }}>
        {!!label && variant !== 'default' && (
          <LabelText tooltip={hint} color={labelColor}>
            {label}
          </LabelText>
        )}

        <InputContainer
          type={type}
          size={size}
          color={color}
          hasError={hasError || !!error}
          hasStartIcon={!!StartIcon}
          hasEndIcon={!!EndIcon}
          isFocused={isFocused}
          variant={variant}
        >
          {!!StartIcon && (
            <StartIconContainer
              hasError={hasError || !!error}
              isFocused={isFocused}
              variant={variant}
              size={size}
              color={color}
            >
              <StartIcon />
            </StartIconContainer>
          )}

          <input
            name={name}
            id={name}
            ref={ref}
            type={newType}
            onFocus={handleFocusInput}
            onBlur={handleBlurInput}
            style={{
              textAlign: centralize ? 'center' : 'start',
            }}
            {...props}
          />

          {isLoading && (
            <LoadingContainer variant={variant}>
              <Spinner size="sm" color="primary" />
            </LoadingContainer>
          )}

          {!!endContent && (
            <EndContent isDisabled={props.disabled} variant={variant}>
              {endContent}
            </EndContent>
          )}

          {!!EndIcon && (
            <EndIconContainer
              hasError={hasError || !!error}
              isFocused={isFocused}
              variant={variant}
              size={size}
              color={color}
            >
              <EndIcon />
            </EndIconContainer>
          )}

          {type === 'password' && (
            <Tooltip
              label={isPasswordEncrypted ? 'Mostrar senha' : 'Esconder senha'}
              hasArrow
              closeOnClick={false}
            >
              <button type="button" onClick={toggleIsPasswordEncrypted}>
                {isPasswordEncrypted ? <IoEye /> : <IoEyeOff />}
              </button>
            </Tooltip>
          )}
        </InputContainer>
        {observation && (
          <ObservationContainer>
            <span>{observation}</span>
          </ObservationContainer>
        )}
        <ErrorMessage>{error}</ErrorMessage>
      </label>
    </Container>
  );
};

const FowardedInput = forwardRef(BaseInput);

export const Input = memo(FowardedInput);
