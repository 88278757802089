import { isAfter } from 'date-fns';

import { ISubscriptionInvoiceAttempt } from '../interfaces/ISubscriptionInvoiceAttempt';

export function getLastSubscriptionInvoiceAttempt(
  attempts: ISubscriptionInvoiceAttempt[]
): ISubscriptionInvoiceAttempt {
  if ((attempts || []).length === 0) return null;

  const lastAttempt = attempts.reduce((acc, attempt) => {
    if (!acc.date || isAfter(new Date(attempt.date), new Date(acc.date))) {
      return attempt;
    }

    return acc;
  }, {} as ISubscriptionInvoiceAttempt);

  return Object.values(lastAttempt || {}).length > 0 ? lastAttempt : null;
}
