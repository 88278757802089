import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();

export interface EnviromentVariables {
  API_URI: string;
  PHONE_VERIFICATION_SECONDS: number;
  MELI_GENERATE_TOKEN_URL: string;
  MERCADO_PAGO_GENERATE_TOKEN_URL: string;
  MENU_FACIL_URL: string;
  PRA_VENDAS_STORE_DOMAIN: string;
  VIA_CEP_API_URL: string;
  GEO_PRA_FRETES_API_URL: string;
  RS_SOLUTIONS_SITE_URL: string;
  PRA_FRETES_CORREIOS_API_URI: string;
  GEOLOCATION_API_URL: string;
  KDS_FACIL_URL: string;
  ADMIN_API_URL: string;
  PRA_FRETES_API_URL: string;
  AMAZON_GENERATE_AUTHORIZATION_CODE_URL: string;
  PRA_CHAMADOS_PANEL_DOMAIN: string;
}

export const env: EnviromentVariables = publicRuntimeConfig;
