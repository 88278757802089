import styled from 'styled-components';

export const Container = styled.div`
  @media (max-width: 750px) {
    position: fixed;
    top: 0;
    right: 0;
  }

  @media (min-width: 750px) {
    position: absolute;
  }

  box-shadow: 3px 3px 15px #00000020;
  background: ${({ theme }) => theme.colors.surface};
  width: 100%;
  z-index: 25;
  display: flex;
  flex-direction: column;
  padding-top: ${({ theme }) => theme.spacing.md};
  padding-bottom: ${({ theme }) => theme.spacing.md};

  > div:last-of-type {
    margin-top: ${({ theme }) => theme.spacing.xs};
  }
`;

export const PopoverArrow = styled.span`
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid ${({ theme }) => theme.colors.surface};
  top: -7px;
  position: absolute;
  right: ${({ theme }) => `calc(${theme.spacing.sm} + 6px)`};
`;

export const CoursesHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: ${({ theme }) => theme.spacing.md};
  padding-left: ${({ theme }) => theme.spacing.md};

  > div {
    margin-right: ${({ theme }) => theme.spacing.xs};
  }
`;

export const CoursesTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: ${({ theme }) => theme.spacing.xs};
  padding-right: ${({ theme }) => theme.spacing.md};
  padding-left: ${({ theme }) => theme.spacing.md};

  & > div {
    display: flex;
    flex-direction: column;
    margin-top: ${({ theme }) => theme.spacing['2xs']};
    span,
    b {
      font-size: 20px;
      color: ${({ theme }) => theme.colors.primary};
    }
  }
`;
