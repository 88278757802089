import {
  createContext,
  useContext,
  useState,
  useCallback,
  ReactNode,
} from 'react';

import { ColorScheme } from 'styled-components';

import { FCWithChildren } from '../types/FCWithChildren';
import { ConfirmationModal } from '../components/ConfirmationModal';

type AsyncFunction = () => Promise<void>;
type RegularFunction = () => void;
type OnChoiseFunction = AsyncFunction | RegularFunction;
type TCustomOptions = {
  cancel: string;
  confirm: string;
};

interface OpenRequest {
  content: ReactNode;
  title?: string;
  subtitle?: string;
  options?: TCustomOptions;
  size?: string;
  titleColor?: ColorScheme;
  onConfirm?: OnChoiseFunction;
  onCancel?: OnChoiseFunction;
}

interface IConfirmationModalContextData {
  open(request: OpenRequest): void;
  close(): void;
  onConfirm: OnChoiseFunction;
  onCancel?: OnChoiseFunction;
  isOpen: boolean;
  options: TCustomOptions;
  size: string;
}

const ConfirmationModalContext = createContext(
  {} as IConfirmationModalContextData
);

const ConfirmationModalProvider: FCWithChildren = ({ children }) => {
  const [content, setContent] = useState<ReactNode>('');
  const [title, setTitle] = useState<string>('');
  const [subtitle, setSubtitle] = useState<string>('');
  const [onConfirm, setOnConfirm] = useState(() => null);
  const [onCancel, setOnCancel] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [titleColor, setTitleColor] = useState<ColorScheme>();
  const [size, setSize] = useState<string>('310px');
  const [options, setOptions] = useState<TCustomOptions>({
    cancel: 'não',
    confirm: 'sim',
  });

  const open = useCallback((request: OpenRequest) => {
    setContent(request.content);
    setTitle(request.title);
    setSubtitle(request.subtitle);
    setTitleColor(request.titleColor);
    setOnConfirm(() => request.onConfirm);
    setOnCancel(() => request.onCancel);
    setIsOpen(true);

    if (request.size) {
      setSize(request.size);
    }

    if (request.options) {
      setOptions(request.options);
    }
  }, []);

  const close = useCallback(() => {
    setIsOpen(false);
    setOnConfirm(() => null);
    setContent('');
  }, []);

  return (
    <ConfirmationModalContext.Provider
      value={{ open, close, size, onConfirm, onCancel, isOpen, options }}
    >
      {children}

      {isOpen && (
        <ConfirmationModal
          content={content}
          title={title}
          subtitle={subtitle}
          titleColor={titleColor}
        />
      )}
    </ConfirmationModalContext.Provider>
  );
};

const useConfirmationModal = (): IConfirmationModalContextData => {
  return useContext(ConfirmationModalContext);
};

export { ConfirmationModalProvider, useConfirmationModal };
