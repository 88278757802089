import { FC, Fragment, useCallback, useRef } from 'react';

import { Skeleton, Tooltip } from '@chakra-ui/react';

import { useMediaQuery } from '~/shared/hooks/mediaQuery';
import { OutlinedButton } from '~/shared/components/OutlinedButton';
import { LabelText } from '~/shared/components/Form/LabelText';
import { Alert } from '~/shared/components/Alert';

import {
  CourseButtonsWrapper,
  CourseCurrentPercentage,
  CourseItem,
  CoursePercentageWrapper,
  CoursesListContainer,
  CourseTitleWrapper,
  CourseVideoLineConector,
  CourseVideoWrapper,
  LoadingRow,
} from './styles';
import VideoPlayer, { IVideoPlayerHandles } from '../VideoPlayer';
import { CourseVideoButton } from '../CourseVideoButton';
import { IVideo, IVideoPicutes } from '../../interfaces/IVideo';
import { useCourses } from '../../hooks/courses';

export const CoursesList: FC = () => {
  const isMobile = useMediaQuery('(max-width: 750px)');
  const { hasNotShowcase, videos, progress, loading, updateProgress } =
    useCourses();
  const videoPlayerRef = useRef<IVideoPlayerHandles>(null);

  const getVideoPicute = useCallback((pictures: IVideoPicutes) => {
    const picture = pictures?.sizes?.find(
      (size) => size.width === 1920 && size.height === 1080
    );

    if (picture) {
      const { link } = picture;
      return link;
    }

    return '';
  }, []);

  const matchProgress = useCallback(
    (video: IVideo) => {
      const foundProgress = progress?.find(
        (prog) => prog.videoId === video.uri.split('/').pop()
      );

      return foundProgress;
    },
    [progress]
  );

  const matchVideoPercentage = useCallback(
    (video: IVideo) => {
      const foundProgress = matchProgress(video);

      if (foundProgress) {
        const { currentPercentage } = foundProgress;
        return currentPercentage * 100;
      }

      return 0;
    },
    [matchProgress]
  );

  const matchVideoProgressAndOpen = useCallback(
    (video: IVideo) => {
      const foundProgress = matchProgress(video);

      if (!foundProgress) {
        videoPlayerRef.current.open(video.uri);
      } else {
        const { currentSeconds } = foundProgress;
        videoPlayerRef.current.open(video.uri, currentSeconds);
      }
    },
    [matchProgress]
  );

  const handleCloseVideoPlayer = useCallback(async () => {
    await updateProgress();
  }, [updateProgress]);

  const LoadingList: JSX.Element = (
    <>
      <LoadingRow>
        <Skeleton />
        <Skeleton />
      </LoadingRow>
      <LoadingRow>
        <Skeleton />
        <Skeleton />
      </LoadingRow>
      <LoadingRow>
        <Skeleton />
        <Skeleton />
      </LoadingRow>
    </>
  );

  const renderPercentageIndicator = useCallback(
    (video: IVideo) => {
      const percentage = matchVideoPercentage(video);
      const percentageText = `Assistindo ${Math.floor(percentage)}%`;

      return (
        <Tooltip label={percentageText} hasArrow>
          <CoursePercentageWrapper>
            <CourseCurrentPercentage percentage={percentage} />
          </CoursePercentageWrapper>
        </Tooltip>
      );
    },
    [matchVideoPercentage]
  );

  const renderButtons = useCallback(
    (video: IVideo) => {
      const hasContinue = matchProgress(video);
      return (
        <CourseButtonsWrapper hasContinue={!!hasContinue}>
          <OutlinedButton
            active={false}
            onClick={() => videoPlayerRef.current.open(video.uri)}
          >
            assistir do início
          </OutlinedButton>
          {hasContinue && (
            <OutlinedButton
              active={false}
              onClick={() => matchVideoProgressAndOpen(video)}
            >
              continuar
            </OutlinedButton>
          )}
        </CourseButtonsWrapper>
      );
    },
    [matchProgress, matchVideoProgressAndOpen]
  );

  return (
    <>
      <CoursesListContainer>
        {loading ? (
          LoadingList
        ) : (
          <>
            {hasNotShowcase ? (
              <>
                <Alert
                  type="info"
                  text="Ops, ainda não publicamos os vídeos dos cursos. Mas em breve estará disponível."
                />
              </>
            ) : !videos || videos.length === 0 ? (
              <>
                <Alert
                  type="info"
                  text="Ops, ainda não há nenhum vídeo disponível para sua plataforma."
                />
              </>
            ) : (
              <>
                {videos?.map((video) => (
                  <Fragment key={video.uri}>
                    <CourseItem>
                      <CourseVideoWrapper id="video-wrapper">
                        <CourseVideoButton
                          onClick={() => matchVideoProgressAndOpen(video)}
                          name={video.name}
                          source={getVideoPicute(video.pictures)}
                        />
                        <CourseVideoLineConector>
                          <div />
                          <div />
                          <div />
                        </CourseVideoLineConector>
                        {renderPercentageIndicator(video)}
                      </CourseVideoWrapper>
                      <CourseTitleWrapper hasDescription={!!video.description}>
                        <LabelText fontSize={16}>{video.name}</LabelText>
                        {video.description && (
                          <div id="description">
                            <span>{video.description}</span>
                          </div>
                        )}
                        {renderButtons(video)}
                      </CourseTitleWrapper>
                    </CourseItem>
                    {isMobile && <hr />}
                  </Fragment>
                ))}
              </>
            )}
          </>
        )}
      </CoursesListContainer>
      <VideoPlayer ref={videoPlayerRef} onClose={handleCloseVideoPlayer} />
    </>
  );
};
