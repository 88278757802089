import styled from 'styled-components';

export const Container = styled.button<{ active: boolean }>`
  padding: ${({ theme }) => `0 ${theme.spacing.sm}`};
  background: ${({ theme, active }) =>
    active ? theme.colors.primary : theme.colors.onPrimary};
  color: ${({ theme, active }) =>
    active ? theme.colors.onPrimary : theme.colors.primary};
  border-radius: ${({ theme }) => theme.borderRadius.lg};
  border: 1px solid ${({ theme, color }) => theme.colors[color]};
  font-size: 12px;
  font-weight: 700;
  height: 24px;
  line-height: 24px;

  transition: color 0.3s, background-color 0.3s;
  will-change: color, background-color;

  text-transform: uppercase;

  &:not(:disabled):hover {
    color: ${(p) => p.theme.colors.onPrimary};
    background-color: ${(p) => p.theme.colors.primary};
  }

  &:disabled {
    background: ${({ theme }) => theme.colors.disabledBackground};
    color: ${({ theme }) => theme.colors.onDisabledBackground};
    border-color: ${({ theme }) => theme.colors.onDisabledBackground};
  }
`;
