import Link from 'next/link';
import styled from 'styled-components';

interface IContainerProps {
  index: number;
}

export const Container = styled(Link)<IContainerProps>`
  width: 100%;
  background: ${({ theme }) => theme.colors.error};
  color: ${({ theme }) => theme.colors.onError};
  padding: ${({ theme }) => `4px ${theme.spacing.md}`};

  box-shadow: 3px 3px 15px #00000020;
  z-index: ${({ index }) => 100 - index};

  text-align: center;

  font-size: 13px;
  line-height: 20px;
  font-weight: bold;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;

  transition: filter ease-in 0.3s;

  &:hover {
    filter: brightness(0.92);
  }
`;
