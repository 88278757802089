/* eslint-disable import/no-cycle */
import { parseCookies } from 'nookies';
import axios, { AxiosInstance } from 'axios';

import { signOut } from '~/modules/auth/hooks/auth';

import { AuthTokenError } from '../errors/AuthTokenError';
import { env } from '../constants/env';
import { cookies } from '../constants/cookies';

export function setupApi(ctx = undefined): AxiosInstance {
  const parsedCookies = parseCookies(ctx);

  const api = axios.create({
    baseURL: env.API_URI,
    headers: {
      ...(parsedCookies &&
        parsedCookies[cookies.AUTH_TOKEN] && {
          Authorization: `Bearer ${parsedCookies[cookies.AUTH_TOKEN]}`,
        }),
    },
  });

  api.interceptors.response.use(
    (success) => success,
    (error) => {
      if (error.response?.status === 401) {
        if (error.response?.data?.code === 'token_expired') {
          if (typeof document !== 'undefined') {
            signOut();
          } else {
            return Promise.reject(new AuthTokenError());
          }
        }
      }

      return Promise.reject(error);
    }
  );

  return api;
}

export const api = setupApi();
