import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  top: ${({ theme }) => `calc(${theme.spacing.md} + 60px)`};
  left: 50%;
  right: 50%;
  transform: translateX(-50%);
  border-radius: ${({ theme }) => theme.borderRadius.sm};
  z-index: 110;
  width: 330px;
  background: #fff;
  transition: all 0.4s;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  padding-bottom: ${({ theme }) => theme.spacing['2xs']};

  > div {
    padding: ${({ theme }) => `0 ${theme.spacing['2xs']}`};

    p {
      font-size: 14px;
      letter-spacing: 0;
    }
  }
`;

export const Arrow = styled.span`
  width: 0;
  height: 0;
  border-bottom: 30px solid transparent;
  border-top: 30px solid transparent;
  border-right: 30px solid #fff;
  bottom: -25px;
  right: 50%;
  transform: rotate(-20deg);
  position: absolute;
  transition: all 0.4s;
`;

export const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: ${({ theme }) => theme.spacing['2xs']};

  > div {
    display: flex;
    align-items: center;

    > span {
      font-weight: 700;
      font-size: 15px;
      line-height: 15px;
      letter-spacing: 0;
    }
  }

  > button {
    display: flex;
    align-items: center;
    justify-content: center;

    background: none;
    border: none;
    cursor: pointer;

    transition: all 0.4s;

    &:hover {
      opacity: 0.6;
    }

    svg {
      height: 16px;
      width: 16px;
      stroke-width: 1px;
    }
  }
`;

export const Footer = styled.footer`
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: ${({ theme }) => theme.spacing['2xs']};

  > button {
    display: flex;
    align-items: center;
    justify-content: center;

    padding: ${({ theme }) => `0 ${theme.spacing.xs}`};
    height: 24px;
    background: ${({ theme }) => theme.colors.onInfo};
    color: ${({ theme }) => theme.colors.info};
    border-radius: ${({ theme }) => theme.borderRadius.lg};
    border: 1px solid ${({ theme }) => theme.colors.info};
    white-space: nowrap;

    font-size: 13px;
    font-weight: 700;
    line-height: 14px;

    min-width: 65px;

    transition: all 0.5s;

    &:hover {
      filter: brightness(0.92);
    }
  }
`;
