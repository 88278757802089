import { isAfter } from 'date-fns';

import { ISubscriptionInvoice } from '../interfaces/ISubscriptionInvoice';

export function getLastSubscriptionInvoice(
  invoices: ISubscriptionInvoice[]
): ISubscriptionInvoice {
  if ((invoices || []).length === 0) return null;

  const lastInvoice = invoices.reduce((acc, attempt) => {
    if (
      !acc.billingDate ||
      isAfter(new Date(attempt.billingDate), new Date(acc.billingDate))
    ) {
      return attempt;
    }

    return acc;
  }, {} as ISubscriptionInvoice);

  return Object.values(lastInvoice || {}).length > 0 ? lastInvoice : null;
}
