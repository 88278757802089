import { ReactNode } from 'react';

import { RiQuestionLine } from 'react-icons/ri';

import { ColorScheme } from 'styled-components';
import { Tooltip } from '@chakra-ui/react';

import { FCWithChildren } from '~/shared/types/FCWithChildren';

import { Container } from './styles';

interface LabelTextProps {
  tooltip?: ReactNode;
  as?: any;
  color?: ColorScheme;
  readonly noWrap?: boolean;
  fontSize?: number;
  fontWeight?: number;
}

export const LabelText: FCWithChildren<LabelTextProps> = ({
  tooltip,
  as,
  children,
  noWrap = false,
  color = 'onSurface',
  fontSize = 13,
  fontWeight = 600,
}) => {
  return (
    <Container
      fontSize={fontSize}
      as={as}
      color={color}
      noWrap={noWrap}
      fontWeight={fontWeight}
    >
      <span>{children}</span>

      {tooltip && (
        <Tooltip label={tooltip} hasArrow closeOnClick={false}>
          <div>
            <RiQuestionLine />
          </div>
        </Tooltip>
      )}
    </Container>
  );
};
