import { createContext, useContext, useState, useCallback } from 'react';

import { ISalesChannelCategory } from '~/modules/products/interfaces/ISalesChannelCategory';

import { FCWithChildren } from '~/shared/types/FCWithChildren';
import { api } from '~/shared/services/api';

interface IMeliCategoriesContextData {
  selectedCategory: ISalesChannelCategory | undefined;
  selectCategory(category: ISalesChannelCategory): void;
  searchCategoriesByPredicator(search: string): Promise<void>;
  hasAlreadySearchedByPredictor: boolean;
  categoriesByPredictor: ISalesChannelCategory[];
}

const MeliCategoriesContext = createContext({} as IMeliCategoriesContextData);

const MeliCategoriesProvider: FCWithChildren = ({ children }) => {
  const [selectedCategory, setSelectedCategory] = useState<
    ISalesChannelCategory | undefined
  >(undefined);
  const [hasAlreadySearchedByPredictor, setHasAlreadySearchedByPredictor] =
    useState(false);
  const [categoriesByPredictor, setCategoriesByPredictor] = useState<
    ISalesChannelCategory[]
  >([]);

  const selectCategory = useCallback((category: ISalesChannelCategory) => {
    setSelectedCategory(category);
    setCategoriesByPredictor([]);
  }, []);

  const searchCategoriesByPredicator = useCallback(async (search: string) => {
    setHasAlreadySearchedByPredictor(false);
    setCategoriesByPredictor([]);

    const response = await api.get<ISalesChannelCategory[]>(
      `/sales-channel-categories/predictor?search=${search}`
    );

    if (response.data) {
      const formattedCategories = response.data.map((item) => {
        const formattedPreviousCategories = item.previousCategories?.reduce(
          (acc, previousCategory) => {
            return (
              acc +
              (acc ? ` > ${previousCategory.name}` : previousCategory.name)
            );
          },
          ''
        );

        return {
          ...item,
          formattedPreviousCategories,
        };
      });

      setCategoriesByPredictor(formattedCategories);
    }

    setHasAlreadySearchedByPredictor(true);
  }, []);

  return (
    <MeliCategoriesContext.Provider
      value={{
        selectedCategory,
        selectCategory,
        categoriesByPredictor,
        hasAlreadySearchedByPredictor,
        searchCategoriesByPredicator,
      }}
    >
      {children}
    </MeliCategoriesContext.Provider>
  );
};

const useMeliCategories = (): IMeliCategoriesContextData => {
  return useContext(MeliCategoriesContext);
};

export { MeliCategoriesProvider, useMeliCategories };
