import styled from 'styled-components';

import { IOfferStepSettings } from '~/modules/offers/interfaces/IOfferStepSettings';

interface ContainerProps {
  settings: Omit<IOfferStepSettings, 'status'>;
}

export const Container = styled.div<ContainerProps>`
  margin-top: ${({ theme }) => `calc(${theme.spacing['2xs']} / 2)`};
  max-width: 250px;
  width: 100%;

  > input,
  > select {
    width: 100%;
    height: 45px;
    border-radius: 5px;
    border: none;
    background: none;
    padding: 10px;
    color: ${({ settings }) => settings?.colors?.text};
    border: 1px solid ${({ settings }) => settings?.colors?.secondary};
    font-size: 12px;
    font-weight: 600;

    optgroup,
    option {
      background: #fff !important;
      color: #444 !important;
    }
  }

  > input::placeholder {
    color: ${({ settings }) => settings?.colors?.text};
    font-weight: 400;
  }

  label {
    display: flex;
    align-items: center;

    color: ${({ settings }) => settings?.colors?.text};
    font-size: 14px;

    input {
      margin-right: 10px;
    }
  }

  div {
    span:not([id='error-message']) {
      color: ${({ settings }) => settings?.colors?.text};
      font-size: 14px;
      margin-bottom: 10px;
      display: block;
    }

    label + label {
      margin-top: 3px;
    }
  }

  #error-message {
    color: ${({ settings }) => settings?.colors?.error};
  }
`;
