import styled from 'styled-components';

export const Container = styled.li`
  width: 100%;
  padding: ${({ theme }) => theme.spacing.sm};
  background: ${({ theme }) => theme.colors.secondary};
  border-radius: ${({ theme }) => theme.borderRadius.sm};
  box-shadow: 3px 3px 10px #00000030;

  cursor: pointer;

  transition: all 0.3s;

  display: flex;
  align-items: center;

  &:hover {
    filter: brightness(0.92);
  }
`;

export const ImageContainer = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;

  margin-right: ${({ theme }) => theme.spacing.xs};
  box-shadow: 3px 3px 10px #00000020;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;

  flex: 1;

  > header {
    position: relative;
    margin-bottom: ${({ theme }) => theme.spacing.xxs};

    h3 {
      font-size: 13px;
      color: ${({ theme }) => theme.colors.onSecondary};
      font-weight: 700;
    }

    > button {
      position: absolute;
      right: 0;
      top: 0;

      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        height: 14px;
        width: 14px;
        color: ${({ theme }) => theme.colors.onSurface};

        opacity: 0.4;

        transition: all 0.3s;

        &:hover {
          opacity: 1;
        }
      }
    }
  }

  > p {
    color: ${({ theme }) => theme.colors.onSecondary};
    font-size: 14px;
    font-weight: 300;
    line-height: 22px;
  }
`;
