import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import { useRouter } from 'next/router';

import { useMediaQuery } from './mediaQuery';
import { FCWithChildren } from '../types/FCWithChildren';

interface SideBarDrawerContextData {
  open(): void;
  close(): void;
  toggle(): void;
  isOpen: boolean;
  isSmallerThan850: boolean;
}

const SideBarDrawerContext = createContext({} as SideBarDrawerContextData);

const SideBarDrawerProvider: FCWithChildren = ({ children }) => {
  const router = useRouter();
  const isSmallerThan850 = useMediaQuery('(max-width: 850px)');

  const [isOpen, setIsOpen] = useState(false);

  const close = useCallback(() => {
    if (!isSmallerThan850) return;

    const sideNav = document.getElementById('sidebar');
    const sideNavBackdrop = document.getElementById('sidebar-backdrop');

    if (sideNav && sideNavBackdrop) {
      sideNav.style.transform = 'translateX(-300px)';
      sideNavBackdrop.style.opacity = '0';

      setTimeout(() => {
        sideNavBackdrop.style.display = 'none';
        setIsOpen(false);
      }, 500);
    }
  }, [isSmallerThan850]);

  const open = useCallback(() => {
    if (!isSmallerThan850) return;

    setIsOpen(true);

    const sideNav = document.getElementById('sidebar');
    const sideNavBackdrop = document.getElementById('sidebar-backdrop');

    if (sideNav && sideNavBackdrop) {
      sideNavBackdrop.style.display = 'block';

      setTimeout(() => {
        sideNav.style.transform = 'translateX(0)';
        sideNavBackdrop.style.opacity = '1';
      }, 100);

      sideNavBackdrop.addEventListener('click', () => {
        close();
      });
    }
  }, [close, isSmallerThan850]);

  const toggle = useCallback(() => {
    isOpen ? close() : open();
  }, [close, isOpen, open]);

  useEffect(() => {
    close();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.asPath]);

  useEffect(() => {
    const sidebar = document.getElementById('sidebar');

    if (sidebar) {
      if (isSmallerThan850) {
        sidebar.style.transform = 'translateX(-300px)';
        return;
      }

      sidebar.style.transition = 'all 0s';

      sidebar.style.transform = 'translateX(0)';

      setTimeout(() => {
        sidebar.style.transition = 'all 0.5s';
      }, 100);
    }
  }, [isSmallerThan850]);

  return (
    <SideBarDrawerContext.Provider
      value={{
        open,
        close,
        toggle,
        isOpen,
        isSmallerThan850,
      }}
    >
      {children}
    </SideBarDrawerContext.Provider>
  );
};

const useSideBarDrawer = (): SideBarDrawerContextData => {
  return useContext(SideBarDrawerContext);
};

export { SideBarDrawerProvider, useSideBarDrawer };
