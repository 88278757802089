import styled from 'styled-components';

interface ContainerProps {
  backgroundColor: string;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  min-width: 55px;
  height: 55px;
  border-radius: 8px;

  box-shadow: 5px 5px 10px #00000015;
  overflow: hidden;

  background-color: ${({ backgroundColor }) => backgroundColor};
`;
