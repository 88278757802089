import styled from 'styled-components';

interface TextareaContainerProps {
  hasError: boolean;
  isFocused: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  > label {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
`;

export const TextareaContainer = styled.div<TextareaContainerProps>`
  overflow: hidden;
  border-radius: ${({ theme }) => theme.borderRadius.md};
  border: 1px solid
    ${({ theme, hasError, isFocused }) =>
      hasError
        ? theme.colors.error
        : isFocused
        ? theme.colors.primary
        : '#ddd'};

  width: 100%;
  max-width: 100%;

  display: flex;

  > textarea {
    background-image: none !important;
    border-radius: ${({ theme }) => theme.borderRadius.md};
    padding: ${({ theme }) => theme.spacing.xs};

    width: 100%;
    height: 100%;
    min-height: 96px;
    max-height: 144px;
    overflow: auto;

    font-size: 15px;
    font-weight: 400;

    font-weight: 500;
    color: ${({ theme }) => theme.colors.onSuccess};

    &::placeholder {
      color: ${({ theme }) => theme.colors.onSuccess};
      font-weight: 400;
      opacity: 0.4;
      font-weight: 500;
    }
  }
`;

export const ObservationContainer = styled.div`
  margin-top: 3px;
  line-height: 16px;

  & > span {
    font-style: italic;
    font-size: 12px;
    color: '#4D4D4D';
  }
`;
